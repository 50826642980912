import ClearableInput from '@rio-cloud/rio-uikit/ClearableInput';

import { useEffect, useRef, useState } from 'react';
import { Controller, FieldErrors, Path, useFormContext } from 'react-hook-form';
import { FormattedMessage, useIntl } from 'react-intl';
import { DATA_TEST_ID_PROPERTY_NAME } from '../../../../../utils';
import { fetchPackagingMaterial } from '../../../api/packaging/packagingMaterialCalls';
import { Partner } from '../../../domain/common.types';
import { PackagingMaterial } from '../../../reducers/packaging/types';
import { Ownership } from '../../../reducers/shipments/packaging.types';
import { PackageTogetherFormValues } from '../../dispatchProposals/dispatchProposalPanel/PackageTogetherDialog';
import { PackagingMaterialExpandableV2 } from './PackagingMaterialExpandableV2';

export const BOX_FINDER_INPUT_SEARCH_BUTTON_TEST_ID = 'BOX_FINDER_INPUT_SEARCH_BUTTON_TEST_ID';
export const MAN_COMMON_BOX_CODE_PREFIX = '09.84019-';

export interface PackagingMaterialFormValues {
    name?: string;
    boxCode?: string;
    boxCodeInput?: string;
    outerHeightInMm?: number;
    outerWidthInMm?: number;
    outerLengthInMm?: number;
    tareWeightInKg?: number;
    isReusable?: boolean;
    ownership?: Ownership;
    stackingFactor?: number;
}

export interface BoxFinderInputProps {
    fieldName: Path<PackageTogetherFormValues>;
    getPackagingMaterialError: (
        errors: FieldErrors<PackageTogetherFormValues>,
    ) => FieldErrors<PackagingMaterialFormValues> | undefined;
    outerClassName?: string;
    disabled?: boolean;
    [DATA_TEST_ID_PROPERTY_NAME]?: string;
    packagingMaterialInputPrefix?: string;
    buyer: string;
    partner: Partner;
}

const mapFetchedPackagingMaterialToPackagingMaterialFormState = (
    fetchedPackagingMaterial: PackagingMaterial,
): PackagingMaterialFormValues => {
    return {
        name: fetchedPackagingMaterial.name,
        boxCode: fetchedPackagingMaterial.boxCode,
        outerHeightInMm: fetchedPackagingMaterial.outerHeightInMm,
        outerWidthInMm: fetchedPackagingMaterial.outerWidthInMm,
        outerLengthInMm: fetchedPackagingMaterial.outerLengthInMm,
        tareWeightInKg: fetchedPackagingMaterial.tareWeightInKg,
        isReusable: fetchedPackagingMaterial.isReusable,
        ownership: fetchedPackagingMaterial.ownership,
        stackingFactor: fetchedPackagingMaterial.stackingFactor,
    };
};

export const DEBOUNCE_TIMEOUT_IN_MS = 500;
export const EXACT_BOX_CODE_LENGTH_OF_MAN = 4;
export const MAX_BOX_CODE_LENGTH_OF_VW = 7;
export const EXACT_BOX_CODE_LENGTH_OF_POWERCO = 10;

export const BoxFinderInputV2 = (props: BoxFinderInputProps) => {
    const { fieldName, getPackagingMaterialError, outerClassName, disabled, buyer, partner, ...rest } = props;

    const intl = useIntl();
    const [fetched, setFetched] = useState<boolean>(false);
    const timerId = useRef(0);

    const {
        setValue,
        resetField,
        setError,
        watch,
        formState: { errors },
    } = useFormContext<PackageTogetherFormValues>();

    const boxCodeInput = watch(`${fieldName}.boxCodeInput`);
    const isManPartner = partner === Partner.MAN;
    const isPowerCoPartner = partner === Partner.POWERCO;

    const isInputDataValid = (unvalidatedInput: string | undefined): unvalidatedInput is string => {
        if (unvalidatedInput === undefined || unvalidatedInput.length < 1 || /[;\\/]/.test(unvalidatedInput)) {
            return false;
        }
        return (
            !(isManPartner && unvalidatedInput.length !== EXACT_BOX_CODE_LENGTH_OF_MAN) &&
            !(isPowerCoPartner && unvalidatedInput.length !== EXACT_BOX_CODE_LENGTH_OF_POWERCO)
        );
    };

    // biome-ignore lint/correctness/useExhaustiveDependencies: Migrated from eslint
    useEffect(() => {
        window.clearTimeout(timerId.current);
        timerId.current = window.setTimeout(() => fetchAndUpdatePackagingMaterial(), DEBOUNCE_TIMEOUT_IN_MS);
        return () => window.clearTimeout(timerId.current);
    }, [boxCodeInput]);

    const dataTestId = rest[DATA_TEST_ID_PROPERTY_NAME];
    const errorClassName = 'form-group has-error has-feedback';

    const hasBoxCodeInputError = getPackagingMaterialError(errors)?.boxCodeInput !== undefined;

    const getInputRowClassName = () => {
        return `${hasBoxCodeInputError ? errorClassName : ''} ${outerClassName}`;
    };

    const setPackagingMaterial = (fetchedPackagingMaterial: PackagingMaterial) => {
        const mappedPackagingMaterial =
            mapFetchedPackagingMaterialToPackagingMaterialFormState(fetchedPackagingMaterial);
        setValue(fieldName, { ...mappedPackagingMaterial, boxCodeInput });
    };

    const fetchAndUpdatePackagingMaterial = () => {
        if (isInputDataValid(boxCodeInput)) {
            const boxCode = isManPartner ? MAN_COMMON_BOX_CODE_PREFIX + boxCodeInput : boxCodeInput;
            fetchPackagingMaterial(boxCode, buyer, partner)
                .then((packagingMaterial) => setPackagingMaterial(packagingMaterial))
                .catch(() => {
                    resetField(fieldName);
                    setError(`${fieldName}.boxCodeInput`, { type: 'custom' });
                })
                .finally(() => {
                    setFetched(true);
                });
        }
    };
    const maxLength = isManPartner
        ? EXACT_BOX_CODE_LENGTH_OF_MAN
        : isPowerCoPartner
          ? EXACT_BOX_CODE_LENGTH_OF_POWERCO
          : MAX_BOX_CODE_LENGTH_OF_VW;

    return (
        <div className={`${getInputRowClassName()}`} data-testid={dataTestId}>
            <div className={`form-group`}>
                <div className={'input-group'}>
                    {isManPartner && (
                        <span className='input-group-addon input-group-addon-label'>{MAN_COMMON_BOX_CODE_PREFIX}</span>
                    )}
                    <Controller
                        name={`${fieldName}.boxCodeInput`}
                        rules={{ required: true, validate: (value) => isInputDataValid(value) }}
                        render={({ field }) => {
                            const inputClassName = `${isManPartner ? 'padding-left-10' : ''}`;
                            return (
                                <ClearableInput
                                    id={fieldName}
                                    ref={field.ref}
                                    onBlur={field.onBlur}
                                    disabled={disabled}
                                    value={field.value}
                                    onChange={(value: string) => {
                                        setFetched(false);
                                        resetField(fieldName);
                                        field.onChange(value);
                                    }}
                                    maxLength={maxLength}
                                    placeholder={intl.formatMessage({
                                        id: 'webedi.inputPlaceholder.packagingMaterial',
                                    })}
                                    inputClassName={inputClassName}
                                />
                            );
                        }}
                    />
                    <span className={'input-group-btn'}>
                        <button
                            className={'btn btn-primary btn-icon-only'}
                            data-testid={BOX_FINDER_INPUT_SEARCH_BUTTON_TEST_ID}
                            type={'button'}
                            disabled={disabled ? disabled : hasBoxCodeInputError || !isInputDataValid(boxCodeInput)}
                            tabIndex={-1}
                            onClick={fetchAndUpdatePackagingMaterial}
                        >
                            <span className={'rioglyph rioglyph-chevron-right'} />
                        </button>
                    </span>
                </div>
            </div>
            {
                <div className={'margin-top-5'}>
                    <PackagingMaterialExpandableV2
                        fetched={fetched}
                        disabled={disabled === undefined ? false : disabled}
                        packagingMaterialFieldName={fieldName}
                        getPackagingMaterialError={getPackagingMaterialError}
                    />
                </div>
            }
            {hasBoxCodeInputError && (
                <span className={'help-block text-size-12 margin-bottom-0'}>
                    <FormattedMessage id={'webedi.form.error.requiredField'} />
                </span>
            )}
        </div>
    );
};
