import SortArrowsUiKit from '@rio-cloud/rio-uikit/SortArrows';
import SortDirectionUiKit from '@rio-cloud/rio-uikit/SortDirection';
import { ObjectValues } from '@rio-cloud/rio-uikit/utils/ObjectValues';
import { FormattedMessage } from 'react-intl';
import { useAppDispatch, useAppSelector } from '../../../../configuration/setup/typedReduxHooks';
import { neverReachedFor } from '../../../../utils';
import { fetchAndStoreDeliverySchedules } from '../../actions/deliverySchedules/DeliverySchedules.actions';
import {
    DeliveryScheduleSortParameterName,
    DeliverySchedulesSortParameters,
    SortDirection,
} from '../../reducers/deliverySchedules/types';
import { getActiveSortParameters } from '../../selectors/deliverySchedules/DeliverySchedules.selector';
import { Tooltip } from '../common/Tooltip';

const toggleSortDirection = (currentDirection: SortDirection): SortDirection => {
    return currentDirection === SortDirection.ASCENDING ? SortDirection.DESCENDING : SortDirection.ASCENDING;
};

const defaultStartingSortDirection = (parameterName: DeliveryScheduleSortParameterName): SortDirection => {
    switch (parameterName) {
        case DeliveryScheduleSortParameterName.ARTICLE_NUMBER_BUYER:
            return SortDirection.ASCENDING;
        case DeliveryScheduleSortParameterName.LATEST_DELIVERY_CALL_OFF_DATE:
            return SortDirection.DESCENDING;
        default:
            return neverReachedFor(parameterName);
    }
};

const setActiveSortParameter = (
    requestedParameter: DeliveryScheduleSortParameterName,
    activeSortParameters: DeliverySchedulesSortParameters,
): DeliverySchedulesSortParameters => {
    if (requestedParameter === activeSortParameters.sortBy) {
        return {
            ...activeSortParameters,
            direction: toggleSortDirection(activeSortParameters.direction),
        };
    }

    return {
        sortBy: requestedParameter,
        direction: defaultStartingSortDirection(requestedParameter),
    };
};

const status = () => {
    return (
        <th className={'text-left width-5pct'}>
            <FormattedMessage id={'webedi.common.status'} />
        </th>
    );
};

const SortArrows = (props: {
    activeSortParameters: DeliverySchedulesSortParameters;
    myPropertyName: DeliverySchedulesSortParameters['sortBy'];
}) => {
    const mapSortDirection = (sortDirection: SortDirection): ObjectValues<typeof SortDirectionUiKit> => {
        switch (sortDirection) {
            case SortDirection.ASCENDING:
                return SortDirectionUiKit.ASCENDING;
            case SortDirection.DESCENDING:
                return SortDirectionUiKit.DESCENDING;
            default:
                return neverReachedFor(sortDirection);
        }
    };

    return (
        <>
            {props.activeSortParameters.sortBy === props.myPropertyName ? (
                <SortArrowsUiKit direction={mapSortDirection(props.activeSortParameters.direction)} />
            ) : (
                <SortArrowsUiKit />
            )}
        </>
    );
};

export const ArticleNumberBuyer = () => {
    const activeSortParameters = useAppSelector(getActiveSortParameters);
    const dispatch = useAppDispatch();
    const onClickHandler = () => {
        dispatch(
            fetchAndStoreDeliverySchedules({
                sort: setActiveSortParameter(
                    DeliveryScheduleSortParameterName.ARTICLE_NUMBER_BUYER,
                    activeSortParameters,
                ),
            }),
        );
    };
    return (
        <th className={'text-center sort-column'} onClick={onClickHandler}>
            <span>
                <SortArrows
                    activeSortParameters={activeSortParameters}
                    myPropertyName={DeliveryScheduleSortParameterName.ARTICLE_NUMBER_BUYER}
                />
                <FormattedMessage id={'webedi.articleNumber.buyer'} />
            </span>
        </th>
    );
};

const seller = () => {
    return (
        <th className={'text-center'}>
            <FormattedMessage id={'webedi.articleNumber.seller'} />
        </th>
    );
};

const identifier = () => {
    return (
        <th className={'text-center'}>
            <FormattedMessage id={'webedi.common.shipFrom.identifier'} />
        </th>
    );
};

const plantIdentifier = () => {
    return (
        <th className={'text-center'}>
            <FormattedMessage id={'webedi.plantIdentifier'} />
        </th>
    );
};

const placeOfDischarge = () => {
    return (
        <th className={'text-center'}>
            <FormattedMessage id={'webedi.common.placeOfDischarge'} />
        </th>
    );
};

const placeOfDelivery = () => {
    return (
        <th className={'text-center'}>
            <FormattedMessage id={'webedi.deliverySchedule.placeOfDelivery'} />
        </th>
    );
};

const orderNumber = () => {
    return (
        <th className={'text-center'}>
            <FormattedMessage id={'webedi.orderNumber'} />
        </th>
    );
};

const manufacturingCompany = () => {
    return (
        <th className={'text-center'}>
            <FormattedMessage id={'webedi.common.manufacturingCompany'} />
        </th>
    );
};

const earliestDeliveryDate = () => {
    return (
        <th className={'text-center'}>
            <FormattedMessage id={'webedi.common.earliestDeliveryDate'} />
        </th>
    );
};

export const LatestDeliveryCallOffDate = () => {
    const activeSortParameters = useAppSelector(getActiveSortParameters);
    const dispatch = useAppDispatch();
    const onClickHandler = () => {
        dispatch(
            fetchAndStoreDeliverySchedules({
                sort: setActiveSortParameter(
                    DeliveryScheduleSortParameterName.LATEST_DELIVERY_CALL_OFF_DATE,
                    activeSortParameters,
                ),
            }),
        );
    };
    return (
        <th className={'text-center sort-column'} onClick={onClickHandler}>
            <span>
                <SortArrows
                    activeSortParameters={activeSortParameters}
                    myPropertyName={DeliveryScheduleSortParameterName.LATEST_DELIVERY_CALL_OFF_DATE}
                />
                <FormattedMessage id={'webedi.common.callOffDate'} />
            </span>
        </th>
    );
};

const masterDataAndPackaging = () => {
    return (
        <th className={'text-right padding-right-15'}>
            <FormattedMessage id={'webedi.deliverySchedule.masterDataAndPackaging'} />
        </th>
    );
};

const wrapHeadlineInTooltip = (text: string, column: any) => {
    return (
        <Tooltip text={text} placement={'top'}>
            {column}
        </Tooltip>
    );
};

export const getTableHeadlinesWithTooltip = () => {
    return (
        <>
            <th className={'width-10'} />
            {status()}
            {wrapHeadlineInTooltip('SG6 SG12 LIN No.21 C212 7140', <ArticleNumberBuyer />)}
            {wrapHeadlineInTooltip('User entered data', seller())}
            {wrapHeadlineInTooltip('SG6 SG7 NAD Nr. 8 (Qualifier BY) C082 3039', identifier())}
            {wrapHeadlineInTooltip('SG6 SG7 NAD Nr. 15 C082 3039', plantIdentifier())}
            {wrapHeadlineInTooltip('SG6 SG7 LOC Nr. 16 (Qualifier 11) 3227', placeOfDischarge())}
            {wrapHeadlineInTooltip('SG6 SG7 LOC Nr. 17 (Qualifier 7) 3227', placeOfDelivery())}
            {wrapHeadlineInTooltip('SG6 SG12 SG13 RFF Nr. 27 (Qualifier ON) C506 1154', orderNumber())}
            {wrapHeadlineInTooltip('SG2 NAD Nr.8 C082 3039', manufacturingCompany())}
            {wrapHeadlineInTooltip('SG6 SG12 SG18 SG19 DTM Nr.47 (Qualifier 2,10, 63 & 64)', earliestDeliveryDate())}
            {wrapHeadlineInTooltip('SG6 SG12 SG13 DTM Nr. 29 C171', <LatestDeliveryCallOffDate />)}
            {masterDataAndPackaging()}
        </>
    );
};

export const getTableHeadlinesWithoutTooltip = () => {
    return (
        <>
            <th className={'width-10'} />
            {status()}
            <ArticleNumberBuyer />
            {seller()}
            {identifier()}
            {plantIdentifier()}
            {placeOfDischarge()}
            {placeOfDelivery()}
            {orderNumber()}
            {manufacturingCompany()}
            {earliestDeliveryDate()}
            <LatestDeliveryCallOffDate />
            {masterDataAndPackaging()}
        </>
    );
};
