import ApplicationLayoutBodyBanner from '@rio-cloud/rio-uikit/ApplicationLayoutBodyBanner';
import { FormattedMessage } from 'react-intl';
import { useMaintenanceWindow } from '../../../../configuration/featureToggle/hooks';
import { getValueOrDefaultWhenLoading } from '../../../../configuration/featureToggle/utils';
import { isValidISODateRange } from '../../../../dateUtils';
import { FormattedDateOrDateTime } from './i18n/FormattedDateOrDateTime';

export const MaintenanceWindowBanner = () => {
    const maintenanceWindow = getValueOrDefaultWhenLoading(useMaintenanceWindow());
    if (maintenanceWindow === 'DISABLED' || !isValidISODateRange(maintenanceWindow)) {
        return null;
    } else {
        const window = maintenanceWindow.split('_');
        const maintenanceWindowStartDate = window[0];
        const maintenanceWindowEndDate = window[1];

        return (
            <ApplicationLayoutBodyBanner className={'bg-warning'}>
                <div>
                    <div className={'text-medium'}>
                        <FormattedMessage id={'webedi.common.maintenance.header'} />
                    </div>
                    <span>
                        <FormattedMessage
                            id={'webedi.common.maintenance.body'}
                            values={{
                                maintenanceWindowStartDate: (
                                    <b>
                                        {' '}
                                        <FormattedDateOrDateTime date={maintenanceWindowStartDate!} />{' '}
                                    </b>
                                ),
                                maintenanceWindowEndDate: (
                                    <b>
                                        {' '}
                                        <FormattedDateOrDateTime date={maintenanceWindowEndDate!} />
                                    </b>
                                ),
                            }}
                        />
                    </span>
                </div>
            </ApplicationLayoutBodyBanner>
        );
    }
};
