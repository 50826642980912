import ButtonDropdown from '@rio-cloud/rio-uikit/ButtonDropdown';
import { ReactElement, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';
import { useAppDispatch } from '../../../../../configuration/setup/typedReduxHooks';
import { TimeRangeType } from '../../../domain/transportOrderOptions.types';
import { transportOrdersSlice } from '../../../reducers/transportOrders/TransportOrders.reducer';
import { getSelectedTimeRangeTypeFilter } from '../../../selectors/transportOrders/transportOrders.selector';

const createDropdownItem = (
    item: { type: TimeRangeType; translationKey: string },
    onSelectTimeRangeType: (type: TimeRangeType) => void,
    selectedTimeRangType: TimeRangeType,
    setIsOpen: (isOpen: boolean) => void,
): ReactElement => (
    <li
        key={item.type}
        role='presentation'
        className={'width-200'}
        onClick={() => {
            onSelectTimeRangeType(item.type);
            setIsOpen(false);
        }}
    >
        <a role='menuitem'>
            {selectedTimeRangType === item.type ? (
                <span data-testid={'dropDownItemSelectedIcon'} className='rioglyph rioglyph-ok margin-right-5' />
            ) : null}
            <FormattedMessage id={item.translationKey} />
        </a>
    </li>
);

const dropdownItems = [
    { type: TimeRangeType.TODAY, translationKey: 'webedi.transportOrders.overview.toolbar.today' },
    { type: TimeRangeType.YESTERDAY, translationKey: 'webedi.transportOrders.overview.toolbar.yesterday' },
    { type: TimeRangeType.TOMORROW, translationKey: 'webedi.transportOrders.overview.toolbar.tomorrow' },
    { type: TimeRangeType.LAST_WEEK, translationKey: 'webedi.transportOrders.overview.toolbar.lastWeek' },
    { type: TimeRangeType.CUSTOM, translationKey: 'webedi.transportOrders.overview.toolbar.custom' },
];

const PreSelectionActiveMarker = (props: { selectedTimeRangType: TimeRangeType }) => {
    if (props.selectedTimeRangType !== TimeRangeType.CUSTOM) {
        return (
            <div
                className={
                    'bg-primary rounded-circle border-style-solid border-color-white border-size-1 padding-5 position-absolute top--2 right--3 z-index-10'
                }
            />
        );
    }
    return null;
};

export const PreSelectionDropdown = () => {
    const selectedTimeRangeType = useSelector(getSelectedTimeRangeTypeFilter);
    const dispatch = useAppDispatch();
    const onSelectTimeRangeType: (type: TimeRangeType) => void = (type: TimeRangeType) => {
        dispatch(transportOrdersSlice.actions.setFilterTimeRangeType(type));
    };
    const [isOpen, setIsOpen] = useState(false);

    const customDropdown = dropdownItems.map(
        (item): ReactElement => createDropdownItem(item, onSelectTimeRangeType, selectedTimeRangeType, setIsOpen),
    );

    return (
        <div data-testid={'TimeRangePreSelection'}>
            <div className={'position-relative margin-right-10'}>
                <ButtonDropdown
                    className={'position-relative'}
                    title={<span className={'rioglyph rioglyph rioglyph-settings'} aria-hidden={'true'} />}
                    customDropdown={customDropdown}
                    iconOnly
                    open={isOpen}
                    onOpen={() => setIsOpen(true)}
                    onClose={() => setIsOpen(false)}
                />
                <PreSelectionActiveMarker selectedTimeRangType={selectedTimeRangeType} />
            </div>
        </div>
    );
};
