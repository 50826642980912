import Notification from '@rio-cloud/rio-uikit/Notification';
import SaveableInput from '@rio-cloud/rio-uikit/components/saveableInput/SaveableInput';
import { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useAppDispatch } from '../../../../../configuration/setup/typedReduxHooks';
import { mapShipmentToShipmentUpdateRequest } from '../../../actions/shipments/Shipments.actions';
import { ProblemError } from '../../../api/apiUtils';
import { getShipment, patchShipment } from '../../../api/shipment/shipmentCalls';
import { transportOrderApi } from '../../../api/transportOrder/transportOrdersApi';
import { MeansOfTransportMode, RoadTransport } from '../../../domain/meansOfTransport.types';
import { useDunsNumberFromPath } from '../../../hooks/Routing.hooks';
import { HandlingUnit } from '../../../reducers/shipments/packaging.types';
import { Dimensions } from '../../../reducers/shipments/types';
import { Tooltip } from '../../common/Tooltip';

interface LicensePlateInputProps {
    id?: string;
    shipmentId?: string;
    meansOfTransport?: RoadTransport;
    disabled?: boolean;
}

export const LicensePlateInput = (props: LicensePlateInputProps) => {
    const { meansOfTransport, shipmentId, disabled, id } = props;
    const initialValue = meansOfTransport?.registrationNumber ?? meansOfTransport?.registrationNumberFromConfirmation;
    const valueFromConfirmation = meansOfTransport?.registrationNumberFromConfirmation;

    const dispatch = useAppDispatch();
    const dunsNumber = useDunsNumberFromPath();
    const [value, setValue] = useState(initialValue);
    const [previousValue, setPreviousValue] = useState(valueFromConfirmation);

    const onInputChange = (inputValue: string) => {
        setValue(inputValue);
        hidePreviousValueIfNoConfirmation(inputValue);
    };

    const hidePreviousValueIfNoConfirmation = (inputValue: string) => {
        if (!valueFromConfirmation) {
            setPreviousValue(inputValue);
        }
    };
    const onValueChanged = async (newValue: string) => {
        if (shipmentId === undefined || dunsNumber === undefined || meansOfTransport === undefined) {
            return;
        }
        try {
            const shipment = await getShipment(dunsNumber, shipmentId);
            if (shipment.meansOfTransport?.mode !== MeansOfTransportMode.ROAD) {
                Notification.error('Means of transport is not road transport!');
                return;
            }
            const updatedShipment = {
                ...shipment,
                meansOfTransport: { ...shipment.meansOfTransport, registrationNumber: newValue },
            };
            const updateRequest = mapShipmentToShipmentUpdateRequest(updatedShipment);
            const packagingOuterDimensions: { [handlingUnitId: HandlingUnit['id']]: Dimensions } = {};
            shipment.groupOfIdenticalPackagingHierarchies.forEach((it) => {
                it.handlingUnitIds.forEach((huId) => {
                    packagingOuterDimensions[huId] = it.dimensions;
                });
            });
            await patchShipment(updateRequest, dunsNumber, shipmentId, packagingOuterDimensions);
            Notification.success(<FormattedMessage id={'webedi.label.success.save'} />);
            setValue(newValue);
        } catch (error) {
            showErrorNotification(error);
            setValue(initialValue);
        } finally {
            dispatch(transportOrderApi.util?.invalidateTags([{ type: 'TransportOrders' }]));
        }
    };

    return (
        <div className={'display-flex flex-row align-items-center'}>
            <SaveableInput
                className={'flex-1-1'}
                value={value}
                inputProps={{
                    id,
                }}
                onInputChange={(_, inputValue) => onInputChange(inputValue)}
                disabled={disabled}
                fixedPreviousValue={previousValue}
                onValueChanged={onValueChanged}
                isValid={value ? value.trim().length > 0 : false}
            />
            {meansOfTransport?.registrationNumber !== undefined && (
                <Tooltip
                    width={300}
                    text={<FormattedMessage id={'webedi.shipment.meansOfTransport.hint.valueEnteredManually'} />}
                    placement={'bottom'}
                >
                    <span className={'rioglyph rioglyph-info-sign text-size-18 text-info form-group margin-left-5'} />
                </Tooltip>
            )}
        </div>
    );
};

const showErrorNotification = (error: unknown) => {
    let errorCode: string | undefined = undefined;
    if (error instanceof ProblemError) {
        errorCode = error.errorCode;
    }
    Notification.error(
        <FormattedMessage id={errorCode ? `webedi.error.${errorCode}` : 'webedi.error.unknown'} />,
        <FormattedMessage id={'webedi.error.shipment.update'} />,
    );
};
