import SplitDialog from '@rio-cloud/rio-uikit/SplitDialog';
import { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useAppDispatch } from '../../../../../../configuration/setup/typedReduxHooks';
import {
    ShipmentDocumentSettingsDialogContent,
    ShipmentDocumentSettingsFormValues,
} from '../../../common/shipmentDocumentSettings/ShipmentDocumentSettingsDialogContent';
import { ShipmentDocumentSettingsDialogFooter } from '../../../common/shipmentDocumentSettings/ShipmentDocumentSettingsDialogFooter';
import {
    ShipmentDocumentSettingsDialogMenu,
    ShipmentDocumentSettingsGroup,
} from '../../../common/shipmentDocumentSettings/ShipmentDocumentSettingsDialogMenu';
import { generateSubmitAction as generateSubmitActionGtl } from './GlobalTransportLabelsSettings';
import { generateSubmitAction as generateSubmitActionTsb } from './ShippingDocumentsSettings';

export interface ShipmentDocumentGeneratorSettingsDialogProps {
    show: boolean;
    onClose: () => void;
}

export const ShipmentDocumentGeneratorSettingsDialog = (props: ShipmentDocumentGeneratorSettingsDialogProps) => {
    const [selectedMenuEntry, setSelectedMenuEntry] = useState(
        ShipmentDocumentSettingsGroup.GTL_GLOBAL_TRANSPORT_LABEL,
    );
    const dispatch = useAppDispatch();

    const onSubmit = (formValues: ShipmentDocumentSettingsFormValues) => {
        dispatch(generateSubmitActionTsb(formValues.tsb));
        dispatch(generateSubmitActionGtl(formValues.gtl));
        props.onClose();
    };

    return (
        <SplitDialog
            show={props.show}
            onClose={props.onClose}
            showCloseButton={true}
            title={<FormattedMessage id={'webedi.shipmentDocuments.generatorSettingsDialog.title'} />}
            leftContent={
                <ShipmentDocumentSettingsDialogMenu selected={selectedMenuEntry} onChange={setSelectedMenuEntry} />
            }
            rightContent={<ShipmentDocumentSettingsDialogContent type={selectedMenuEntry} onSubmit={onSubmit} />}
            footer={
                <ShipmentDocumentSettingsDialogFooter
                    onCancel={props.onClose}
                    submitButtonText={<FormattedMessage id={'webedi.label.save'} />}
                />
            }
        />
    );
};
