import ExpanderPanel from '@rio-cloud/rio-uikit/ExpanderPanel';
import { MouseEvent, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useNavigate } from 'react-router';
import { useAppDispatch, useAppSelector } from '../../../../configuration/setup/typedReduxHooks';
import { isReadOnlyAdmin } from '../../../../configuration/tokenHandling/selectors';

import { formatAddress } from '../../../../utils';
import { createShipmentFromSuggestion } from '../../actions/shipments/Shipments.actions';
import { useDunsNumberFromPath } from '../../hooks/Routing.hooks';
import { Tooltip } from '../common/Tooltip';
import { FormattedDateOrDateTime } from '../common/i18n/FormattedDateOrDateTime';
import { SuggestedLoadTable } from './SuggestedLoadTable';
import { ShipmentSuggestionProps } from './types';

export const ShipmentSuggestion = (props: ShipmentSuggestionProps) => {
    const { shipmentSuggestion, allShipmentSuggestionExpanderOpen } = props;
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const dunsNumber = useDunsNumberFromPath();
    const isReadOnly = useAppSelector(isReadOnlyAdmin);

    const [useAsTemplateButtonDisabledDueToPreviousClick, setUseAsTemplateButtonDisabledDueToPreviousClick] =
        useState(false);
    const shipmentSuggestionHasWarnings = () => {
        const hasInvalidCumulativeQuantitySent = Object.values(shipmentSuggestion.load).some(
            (loadItem) => !loadItem.hasValidCumulativeQuantitySent,
        );
        const hasInvalidArticleMasterData = Object.values(shipmentSuggestion.load).some(
            (loadItem) => !loadItem.hasValidArticleMasterData,
        );
        return hasInvalidCumulativeQuantitySent || hasInvalidArticleMasterData;
    };

    const UseAsTemplateButton = () => {
        const clickHandler = (event: MouseEvent) => {
            event.preventDefault();
            event.stopPropagation();
            setUseAsTemplateButtonDisabledDueToPreviousClick(true);
            dispatch(createShipmentFromSuggestion(dunsNumber!, shipmentSuggestion, navigate));
        };

        const content = (
            <button
                className={`btn btn-default ${shipmentSuggestionHasWarnings() || isReadOnly ? 'disabled' : ''}`}
                disabled={useAsTemplateButtonDisabledDueToPreviousClick}
                onClick={clickHandler}
            >
                <span className={'rioglyph rioglyph-order'} />
                <FormattedMessage id={'webedi.shipmentSuggestion.useAsTemplate'} />
                {isReadOnly && (
                    <span className={'rioglyph rioglyph-info-sign text-color-info padding-left-5 margin-right-0'} />
                )}
            </button>
        );
        if (isReadOnly) {
            return (
                <Tooltip text={<FormattedMessage id={'webedi.error.insufficientPermissions'} />} placement={'bottom'}>
                    <span className={'padding-top-5 padding-bottom-5'}>{content}</span>
                </Tooltip>
            );
        } else if (shipmentSuggestionHasWarnings()) {
            return (
                <Tooltip text={<FormattedMessage id={'webedi.error.invalidMasterData'} />} placement={'bottom'}>
                    <span className={'padding-top-5 padding-bottom-5'}>{content}</span>
                </Tooltip>
            );
        }
        return content;
    };

    return (
        <ExpanderPanel
            open={allShipmentSuggestionExpanderOpen}
            bsStyle={'default'}
            titleClassName={'width-100pct text-normal'}
            headerClassName={`padding-left-25 ${shipmentSuggestionHasWarnings() ? 'callout-warning' : ''}`}
            bodyClassName={'padding-0'}
            title={
                <div className={'display-flex justify-content-between align-items-center'}>
                    <div className={'ellipsis-1'}>
                        <span className={'text-medium expander-header-text text-uppercase'}>
                            <FormattedMessage id={'webedi.shipmentSuggestion.shipmentSuggestionDeliveryDateV2'} />{' '}
                            {'- '}
                        </span>
                        <span>
                            <FormattedMessage id={'webedi.dispatchProposals.overview.expander.header.deliveryDate'} />:
                        </span>
                        <span className={'margin-left-5'}>
                            <FormattedDateOrDateTime date={shipmentSuggestion.requestedDeliveryDate} />
                        </span>
                    </div>
                    <div className={'margin-left-5 width-60pct display-flex align-items-center'}>
                        <span className={'margin-left-25 ellipsis-1 word-break text-color-gray'}>
                            <FormattedMessage id={'webedi.plantIdentifier'} />
                            {':'}
                        </span>
                        <span className={'rioglyph rioglyph-factory text-size-18 margin-left-5 margin-right-5'} />
                        <span className={'margin-right-25 ellipsis-1 word-break'}>{shipmentSuggestion.shipToId}</span>
                        <span className={'ellipsis-1 word-break text-color-gray'}>
                            <FormattedMessage id={'webedi.common.placeOfDischarge'} />
                            {':'}
                        </span>
                        <span className={'rioglyph rioglyph-load-unload text-size-18 margin-left-5 margin-right-5'} />
                        <Tooltip text={formatAddress(shipmentSuggestion.placeOfDischarge.address)} placement={'top'}>
                            <span className={'ellipsis-1 word-break'}>{shipmentSuggestion.placeOfDischarge.id}</span>
                        </Tooltip>
                    </div>

                    <div className={'padding-right-10'}>
                        <UseAsTemplateButton />
                    </div>
                </div>
            }
        >
            <SuggestedLoadTable shipmentSuggestion={shipmentSuggestion} />
        </ExpanderPanel>
    );
};
