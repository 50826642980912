import ConfirmationDialog from '@rio-cloud/rio-uikit/ConfirmationDialog';
import SimpleButtonDropdown from '@rio-cloud/rio-uikit/SimpleButtonDropdown';
import FileSaver from 'file-saver';
import { ReactNode, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useAppSelector } from '../../../../../configuration/setup/typedReduxHooks';
import { today } from '../../../../../dateUtils';
import {
    getDeliverySchedules,
    getDeliverySchedulesQuery,
    getTotalCountOfMatchedDeliverySchedules,
} from '../../../selectors/deliverySchedules/DeliverySchedules.selector';
import { generateDeliverySchedulesCsv } from './DeliveryScheduleCsvExport';

export const DeliveryScheduleCsvExportButton = () => {
    const deliverySchedules = useAppSelector(getDeliverySchedules);
    const totalDeliverySchedules = useAppSelector(getTotalCountOfMatchedDeliverySchedules);
    const query = useAppSelector(getDeliverySchedulesQuery);

    const [onlyOpenAmounts, setOnlyOpenAmounts] = useState(true);
    const [showConfirmationDialog, setShowConfirmationDialog] = useState(false);

    const moreMatchingDeliverySchedulesExist = totalDeliverySchedules - query.params.limit > 0;

    const downloadOptions: { labelKey: string; onlyOpenAmounts: boolean }[] = [
        { labelKey: 'webedi.deliverySchedule.export.openAmounts', onlyOpenAmounts: true },
        { labelKey: 'webedi.deliverySchedule.export.totalAmounts', onlyOpenAmounts: false },
    ];

    const generateAndDownloadCsv = (selectedOnlyOpenAmounts: boolean) => {
        const dateString = today();
        const filename = `${dateString}-webscm-${selectedOnlyOpenAmounts ? 'open-' : ''}demand.csv`;

        FileSaver.saveAs(generateDeliverySchedulesCsv(deliverySchedules, selectedOnlyOpenAmounts), filename);
        setShowConfirmationDialog(false);
    };

    const onSelectHandler = (selectedOnlyOpenAmounts: boolean) => {
        setOnlyOpenAmounts(selectedOnlyOpenAmounts);
        if (moreMatchingDeliverySchedulesExist) {
            setShowConfirmationDialog(true);
        } else {
            generateAndDownloadCsv(selectedOnlyOpenAmounts);
        }
    };

    return (
        <>
            <SimpleButtonDropdown
                className={'width-100pct'}
                bsStyle={'default'}
                toggleClassName={'width-100pct'}
                title={
                    <>
                        <span className={'rioglyph rioglyph-download'} />
                        <FormattedMessage id={'webedi.deliverySchedule.export.label'} />
                        <span className={'caret'} />
                    </>
                }
                items={downloadOptions.map((downloadOption) => ({
                    value: <FormattedMessage id={downloadOption.labelKey} />,
                    onSelect: () => onSelectHandler(downloadOption.onlyOpenAmounts),
                }))}
            />
            <ConfirmationDialog
                show={showConfirmationDialog}
                title={<FormattedMessage id={'webedi.deliverySchedule.export.dialog.title'} />}
                content={
                    <FormattedMessage
                        id={'webedi.deliverySchedule.export.dialog.content'}
                        values={{
                            numberOfAdditionalDeliverySchedules: totalDeliverySchedules - query.params.limit,
                            b: (chunks: ReactNode) => (
                                <span className={'text-decoration-underline text-bold'}>{chunks}</span>
                            ),
                        }}
                    />
                }
                onClickConfirm={() => generateAndDownloadCsv(onlyOpenAmounts)}
                onClickCancel={() => setShowConfirmationDialog(false)}
                cancelButtonText={<FormattedMessage id={'webedi.deliverySchedule.export.dialog.cancel'} />}
                confirmButtonText={<FormattedMessage id={'webedi.deliverySchedule.export.dialog.confirm'} />}
                useOverflow={false}
            />
        </>
    );
};
