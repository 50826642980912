import Dialog from '@rio-cloud/rio-uikit/Dialog';
import Spinner from '@rio-cloud/rio-uikit/Spinner';
import SplitDialog from '@rio-cloud/rio-uikit/SplitDialog';
import { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useAppDispatch, useAppSelector } from '../../../../../configuration/setup/typedReduxHooks';
import { neverReachedFor } from '../../../../../utils';
import {
    ShipmentDocumentsGenerationError,
    generateShipmentDocuments,
} from '../../../actions/shipmentDocuments/shipmentDocuments.actions';
import { ProblemError } from '../../../api/apiUtils';
import { useDunsNumberFromPath } from '../../../hooks/Routing.hooks';
import { ShipmentDocument, ShipmentDocuments } from '../../../reducers/shipmentDocuments/ShipmentDocuments.types';
import { getSelectedTransportOrders } from '../../../selectors/transportOrders/transportOrders.selector';
import { DownloadShipmentDocumentsComponent } from '../../common/shipmentDocumentDownload/DownloadShipmentDocuments';
import {
    ShipmentDocumentSettingsDialogContent,
    ShipmentDocumentSettingsFormValues,
} from '../../common/shipmentDocumentSettings/ShipmentDocumentSettingsDialogContent';
import { ShipmentDocumentSettingsDialogFooter } from '../../common/shipmentDocumentSettings/ShipmentDocumentSettingsDialogFooter';
import {
    ShipmentDocumentSettingsDialogMenu,
    ShipmentDocumentSettingsGroup,
} from '../../common/shipmentDocumentSettings/ShipmentDocumentSettingsDialogMenu';
import { generateSubmitAction as generateSubmitActionGtl } from '../../shipments/shipmentDocuments/generatorSettings/GlobalTransportLabelsSettings';
import { ShipmentDocumentGeneratorSettingsDialogProps } from '../../shipments/shipmentDocuments/generatorSettings/ShipmentDocumentGeneratorSettingsDialog';
import { generateSubmitAction as generateSubmitActionTsb } from '../../shipments/shipmentDocuments/generatorSettings/ShippingDocumentsSettings';
import { mergeDocuments } from './mergeDocuments';

export const DownloadShipmentDocumentsDialog = (props: ShipmentDocumentGeneratorSettingsDialogProps) => {
    const selectedTransportOrders = useAppSelector(getSelectedTransportOrders);
    const sortedTransportOrders = [...selectedTransportOrders].sort((a, b) => a.index - b.index);
    const dispatch = useAppDispatch();
    const dunsNumber = useDunsNumberFromPath() ?? '';
    const [error, setError] = useState<undefined | Error>(undefined);
    const [content, setContent] = useState<'presets' | 'loading' | 'download'>('presets');
    const [gtlDocument, setGtlDocument] = useState<ShipmentDocument>();
    const [tsbDocument, setTsbDocument] = useState<ShipmentDocument>();

    const onSubmit = (formValues: ShipmentDocumentSettingsFormValues) => {
        dispatch(generateSubmitActionTsb(formValues.tsb));
        dispatch(generateSubmitActionGtl(formValues.gtl));
        const shipments = sortedTransportOrders.map((it) => ({
            shipmentId: it.shipmentId,
            shipmentNumber: it.shipmentNumber,
        }));
        setContent('loading');
        dispatch(generateShipmentDocuments(dunsNumber, shipments))
            .then((shipmentDocuments: ShipmentDocuments[]) =>
                Promise.all(
                    shipmentDocuments.map((it, index) =>
                        downloadDocuments({
                            shipmentId: shipments[index].shipmentId,
                            shipmentNumber: shipments[index].shipmentNumber,
                            tsbDocument: it.tsbDocument,
                            gtlDocument: it.gtlDocument,
                        }),
                    ),
                ),
            )
            .then(async (documents) => {
                const gtlDocumentsByteArrays = documents.map((it) => it.gtlDocument.data);
                setGtlDocument({
                    url: await mergeDocuments(gtlDocumentsByteArrays),
                    hasWarnings: documents.some((it) => it.gtlDocument.hasWarnings),
                });

                const tsbDocumentsByteArrays = documents.map((it) => it.tsbDocument.data);
                setTsbDocument({
                    url: await mergeDocuments(tsbDocumentsByteArrays),
                    hasWarnings: documents.some((it) => it.tsbDocument.hasWarnings),
                });

                setContent('download');
            })
            .catch((err: Error) => {
                setError(err);
                setContent('presets');
            });
    };

    const onClose = () => {
        setError(undefined);
        setGtlDocument(undefined);
        setTsbDocument(undefined);
        props.onClose();
        setContent('presets');
    };

    switch (content) {
        case 'presets':
            return <PresetsDialog show={props.show} onClose={onClose} onSubmit={onSubmit} error={error} />;
        case 'loading':
            return <LoadingSpinnerDialog show={props.show} onClose={onClose} />;
        case 'download':
            return (
                <DownloadDialog
                    show={props.show}
                    onClose={onClose}
                    gtlDocument={gtlDocument!}
                    tsbDocument={tsbDocument!}
                />
            );
        default:
            return neverReachedFor(content);
    }
};

interface SettingsDialogProps extends ShipmentDocumentGeneratorSettingsDialogProps {
    onSubmit: (formValues: ShipmentDocumentSettingsFormValues) => void;
    error?: Error;
}

const PresetsDialog = (props: SettingsDialogProps) => {
    const [selectedMenuEntry, setSelectedMenuEntry] = useState(
        ShipmentDocumentSettingsGroup.GTL_GLOBAL_TRANSPORT_LABEL,
    );
    const selectedTransportOrders = useAppSelector(getSelectedTransportOrders);

    return (
        <SplitDialog
            show={props.show}
            onClose={props.onClose}
            showCloseButton={true}
            title={<FormattedMessage id={'webedi.shipmentDocuments.generatorSettingsDialog.title'} />}
            leftContent={
                <ShipmentDocumentSettingsDialogMenu selected={selectedMenuEntry} onChange={setSelectedMenuEntry} />
            }
            rightContent={
                <>
                    {props.error && <ErrorBox error={props.error} />}
                    <ShipmentDocumentSettingsDialogContent type={selectedMenuEntry} onSubmit={props.onSubmit} />
                </>
            }
            footer={
                <ShipmentDocumentSettingsDialogFooter
                    onCancel={props.onClose}
                    submitButtonText={
                        <FormattedMessage
                            id={'webedi.transportOrders.generateShippingDocumentsDialog.button'}
                            values={{ number: selectedTransportOrders.length }}
                        />
                    }
                />
            }
            useOverflow
        />
    );
};

const LoadingSpinnerDialog = (props: ShipmentDocumentGeneratorSettingsDialogProps) => (
    <Dialog
        show={props.show}
        onClose={props.onClose}
        onHide={props.onClose}
        onEsc={props.onClose}
        title={<FormattedMessage id={'webedi.transportOrders.downloadShipmentDocumentsDialog.loading.title'} />}
        body={
            <div className={'text-center'}>
                <div className={'margin-bottom-10'}>
                    <FormattedMessage id={'webedi.shipmentDocuments.generateDocuments.loading'} />
                </div>
                <Spinner />
            </div>
        }
        footer={null}
    />
);

interface DownloadDialogProps {
    show: boolean;
    onClose: () => void;
    gtlDocument: ShipmentDocument;
    tsbDocument: ShipmentDocument;
}

const DownloadDialog = (props: DownloadDialogProps) => (
    <Dialog
        show={props.show}
        onClose={props.onClose}
        onHide={props.onClose}
        onEsc={props.onClose}
        showCloseButton
        title={<FormattedMessage id={'webedi.shipmentDocuments.labelAndShipmentDocuments'} />}
        body={
            <DownloadShipmentDocumentsComponent
                shipmentDocuments={{
                    tsbDocument: props.tsbDocument,
                    gtlDocument: props.gtlDocument,
                }}
            />
        }
        footer={
            <button className={'CancelButton btn btn-default'} onClick={props.onClose}>
                <FormattedMessage id={'webedi.common.close'} />
            </button>
        }
        useOverflow
    />
);

interface ErrorBoxProps {
    error: Error;
}

const ErrorBox = (props: ErrorBoxProps) => {
    const renderError = (err: Error) => {
        if (err instanceof ShipmentDocumentsGenerationError) {
            return (
                <FormattedMessage
                    id={'webedi.error.shipmentDocuments.generate'}
                    values={{
                        shipmentNumber: err.shipmentNumber,
                        errorMessage: renderErrorCodeOrFallback(err.cause),
                    }}
                />
            );
        }

        return renderErrorCodeOrFallback(err);
    };

    const renderErrorCodeOrFallback = (err: unknown) => {
        if (err instanceof ProblemError && err.errorCode) {
            return <FormattedMessage id={`webedi.error.${err.errorCode}`} />;
        } else if (err instanceof ProblemError && err.statusCode === 500) {
            return <FormattedMessage id={'webedi.error.unknown'} />;
        } else {
            return (err as { message?: string })?.message || 'UNKNOWN ERROR';
        }
    };

    return (
        <div className='alert alert-danger margin-bottom-10'>
            <div className='display-flex'>
                <span className='text-color-danger text-size-h4 margin-right-10 rioglyph rioglyph rioglyph-error-sign' />
                <div>{renderError(props.error)}</div>
            </div>
        </div>
    );
};

interface DownloadDocumentsParameters {
    shipmentId: string;
    shipmentNumber: number;
    tsbDocument: ShipmentDocument;
    gtlDocument: ShipmentDocument;
}

interface DownloadDocumentsResult {
    shipmentId: string;
    tsbDocument: {
        data: ArrayBuffer;
        hasWarnings: boolean;
    };
    gtlDocument: {
        data: ArrayBuffer;
        hasWarnings: boolean;
    };
}

const downloadDocuments = async (params: DownloadDocumentsParameters): Promise<DownloadDocumentsResult> => {
    const downloadFile = async (url: string): Promise<ArrayBuffer> => {
        const response = await fetch(url);
        if (!response.ok) {
            throw new Error(`Unable to download file; response status: ${response.status}`);
        }

        // It seems that the arrayBuffer() method returns some special array buffer for some reason.
        // pdf-lib cannot read it:
        //      "'pdf' must be of type 'string' or 'Uint8Array' or 'ArrayBuffer', but was actually of type 'NaN'"
        // see: https://github.com/Hopding/pdf-lib/issues/1186
        // As a workaround, copy the array buffer to a new one.
        const content = await response.arrayBuffer();
        return copyArrayBuffer(content);
    };

    const copyArrayBuffer = (arrayBuffer: ArrayBuffer): ArrayBuffer => {
        const copiedContent = new Uint8Array(arrayBuffer.byteLength);
        copiedContent.set(new Uint8Array(arrayBuffer));
        return copiedContent.buffer;
    };

    const wrapError = async <T,>(func: () => Promise<T>): Promise<T> => {
        try {
            return await func();
        } catch (err) {
            throw new ShipmentDocumentsGenerationError(params.shipmentId, params.shipmentNumber, err);
        }
    };

    const downloadedFiles = await Promise.all([
        wrapError(() => downloadFile(params.tsbDocument.url)),
        wrapError(() => downloadFile(params.gtlDocument.url)),
    ]);

    return {
        shipmentId: params.shipmentId,
        tsbDocument: {
            data: downloadedFiles[0],
            hasWarnings: params.tsbDocument.hasWarnings,
        },
        gtlDocument: {
            data: downloadedFiles[1],
            hasWarnings: params.gtlDocument.hasWarnings,
        },
    };
};
