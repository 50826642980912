import Notification from '@rio-cloud/rio-uikit/Notification';
import { ReactNode } from 'react';
import { FormattedMessage } from 'react-intl';
import { TranslationKeys } from '../../../configuration/lang/types';

const ERROR_NOTIFICATION_CONTENT_TEST_ID = 'ERROR_NOTIFICATION_CONTENT';
const ALMOST_INFINITE_NOTIFICATION_DELAY = 1000 * 60 * 60; // Notifications can't be shown indefinitely. To simulate it, we use 60 minutes

export const nonVanishingErrorNotification = (message: ReactNode, title?: ReactNode, errorCode?: string) => {
    Notification.error(
        <div data-testid={ERROR_NOTIFICATION_CONTENT_TEST_ID}>
            {message}
            <hr />
            {errorCode && `ErrorCode: ${errorCode}`}
            <span className={'notification-close'}>
                <span className='rioglyph rioglyph-remove' />
            </span>
        </div>,
        title || '',
        ALMOST_INFINITE_NOTIFICATION_DELAY,
    );
};

interface ErrorNotificationProps {
    messageId: TranslationKeys;
    titleId?: TranslationKeys;
    errorCode?: string;
}

export const nonVanishingFormattedErrorNotification = (props: ErrorNotificationProps) =>
    nonVanishingErrorNotification(
        <FormattedMessage id={props.messageId} />,
        (props.titleId && <FormattedMessage id={props.titleId} />) || undefined,
        props.errorCode,
    );

export const notifySuccess = (key: TranslationKeys) => {
    Notification.success(<FormattedMessage id={key} />);
};
