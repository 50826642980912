import Collapse from '@rio-cloud/rio-uikit/Collapse';
import { useState } from 'react';
import { State } from '../../../../configuration/setup/store';
import { useAppSelector } from '../../../../configuration/setup/typedReduxHooks';
import { getShipmentsQuery } from '../../selectors/shipments/Shipments.selector';
import { ShipmentsFilterRow } from './ShipmentsFilterRow';
import { ShipmentsFilterToggleButton } from './ShipmentsFilterToggleButton';
import { ShipmentsList } from './ShipmentsList';
import { ShipmentsPaginationBar } from './ShipmentsPaginationBar';
import { ShipmentsSearch } from './ShipmentsSearch';
import { ShipmentsSelectedFilters } from './ShipmentsSelectedFilters';
import { ShipmentsViewProps } from './types';

export const ShipmentsView = (props: ShipmentsViewProps) => {
    const { exported } = props;
    const [showFilter, setShowFilter] = useState<boolean>(false);
    const queryParams = useAppSelector((state: State) => getShipmentsQuery(state, false).params);

    return (
        <>
            <div className={'table-toolbar margin-bottom-0'}>
                <div className={'table-toolbar-container'}>
                    <div className={'table-toolbar-group-right'}>
                        <div className={'table-toolbar-column'}>
                            <ShipmentsSelectedFilters exported={exported} />
                        </div>
                        <div className={'table-toolbar-column'}>
                            <ShipmentsFilterToggleButton
                                active={showFilter}
                                exported={exported}
                                toggleShow={() => setShowFilter((current) => !current)}
                            />
                        </div>
                        <div className={'table-toolbar-column'}>
                            <ShipmentsSearch exported={exported} />
                        </div>
                    </div>
                </div>
            </div>
            <div className={'table-row margin-bottom-10'}>
                <Collapse open={showFilter} unmountOnExit={true}>
                    <div className={'panel-wrapper'}>
                        <div className={'panel panel-default'}>
                            <div className={'display-flex justify-content-end'}>
                                <button
                                    className={'btn btn-xs btn-icon-only btn-outline'}
                                    onClick={() => setShowFilter(false)}
                                >
                                    <span className={'rioglyph rioglyph-remove toggle-icon close'} />
                                </button>
                            </div>
                            <div className={'panel-body'}>
                                <ShipmentsFilterRow exported={exported} />
                            </div>
                        </div>
                    </div>
                </Collapse>
            </div>
            <ShipmentsList exported={exported} queryParams={queryParams} />
            <div className={'display-flex flex-row align-items-center justify-content-center'}>
                <ShipmentsPaginationBar exported={exported} />
            </div>
        </>
    );
};
