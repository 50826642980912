import Checkbox from '@rio-cloud/rio-uikit/Checkbox';
import { BaseSyntheticEvent } from 'react';
import { FormattedMessage } from 'react-intl';
import { measurementUnitCodeToTranslation } from '../../../../../../utils';
import { MeasurementUnitCode } from '../../../../domain/common.types';
import { GroupedHandlingUnits } from '../../../../reducers/shipments/packaging.types';
import { HandlingUnitCategoryTranslation } from '../../../common/PackagingCommon';
import { Tooltip } from '../../../common/Tooltip';
import { PackagedArticlesInfoTooltip } from './PackagedArticlesInfoTooltip';

interface GroupedPackagingHandlingUnitsProps {
    groupedHandlingUnits: GroupedHandlingUnits[];
    selectable: boolean;
    selectedGroupedHandlingUnits?: GroupedHandlingUnits[];
    handleSelection?: (event: BaseSyntheticEvent, changedHandlingUnitGroups: GroupedHandlingUnits[]) => void;
}

export const PACKAGING_GROUPING_DESCRIPTION_TEST_ID = 'PACKAGING_GROUPINGING_DESCRIPTION_TEST_ID';
export const errorMissingPropertiesForEditMode = Error('Missing properties for the edit mode.');

export const GroupedPackagingHandlingUnits = (props: GroupedPackagingHandlingUnitsProps) => {
    const { groupedHandlingUnits, selectable, selectedGroupedHandlingUnits, handleSelection } = props;

    const renderContent = (groupedHandlingUnit: GroupedHandlingUnits) => {
        const joinedArticleNumbers = groupedHandlingUnit.packagedArticlesInfo
            .map((packagedArticleInfo) => packagedArticleInfo.articleNumberBuyer)
            .join(', ');

        return (
            <>
                <div className={`display-flex align-items-center ${selectable ? 'justify-content-between' : ''}`}>
                    <div className={`${selectable ? 'margin-left-10' : 'text-bold'}`}>
                        {groupedHandlingUnit.quantity} &nbsp;
                        <FormattedMessage id={measurementUnitCodeToTranslation(MeasurementUnitCode.PIECE)} />
                    </div>
                    <div className={'margin-left-20'}>
                        <span className={'text-bold'}>{groupedHandlingUnit.type}</span>
                        {groupedHandlingUnit.description && (
                            <span className={'margin-left-5'} data-testid={PACKAGING_GROUPING_DESCRIPTION_TEST_ID}>
                                {groupedHandlingUnit.description}
                            </span>
                        )}{' '}
                        <PackagedArticlesInfoTooltip packagedArticlesInfo={groupedHandlingUnit.packagedArticlesInfo} />
                        {joinedArticleNumbers && (
                            <div className={`${selectable ? '' : 'text-bold'} text-size-12 text-color-gray ellipsis-1`}>
                                <Tooltip text={joinedArticleNumbers} placement={'bottom'}>
                                    <span>
                                        <FormattedMessage id={'webedi.packaging.contents'} />
                                        {': '}
                                        {joinedArticleNumbers}
                                    </span>
                                </Tooltip>
                            </div>
                        )}
                    </div>
                </div>
                <div className={'margin-left-auto'}>
                    <span className={'label label-default label-filled label-condensed'}>
                        <FormattedMessage id={HandlingUnitCategoryTranslation[groupedHandlingUnit.category]} />
                    </span>
                </div>
            </>
        );
    };

    const renderEditMode = (groupedHandlingUnit: GroupedHandlingUnits) => {
        if (selectedGroupedHandlingUnits === undefined || handleSelection === undefined) {
            throw errorMissingPropertiesForEditMode;
        }

        const isSelected = selectedGroupedHandlingUnits.some((value) => value.hash === groupedHandlingUnit.hash);
        const getCheckboxClasses = `padding-15 margin-bottom-15 user-select-none border bg-white
            ${isSelected ? 'border-color-highlight' : ''}`;

        return (
            <Checkbox
                custom
                checked={isSelected}
                onClick={(event: BaseSyntheticEvent) => handleSelection(event, [groupedHandlingUnit])}
                className={getCheckboxClasses}
            >
                <div
                    className={'checkbox-text-wrapper display-flex align-items-center'}
                    key={groupedHandlingUnit.type + groupedHandlingUnit.quantity + groupedHandlingUnit.hash}
                >
                    <div className={'checkbox-text'} />
                    {renderContent(groupedHandlingUnit)}
                </div>
            </Checkbox>
        );
    };

    return (
        <div className={'children-last-margin-bottom-0'}>
            {groupedHandlingUnits.map((groupedHandlingUnit) => {
                return (
                    <div key={groupedHandlingUnit.type + groupedHandlingUnit.quantity + groupedHandlingUnit.hash}>
                        {selectable ? (
                            renderEditMode(groupedHandlingUnit)
                        ) : (
                            <div className={'panel panel-default'}>
                                <div className={'panel-body display-flex align-items-center'}>
                                    {renderContent(groupedHandlingUnit)}
                                </div>
                            </div>
                        )}
                    </div>
                );
            })}
        </div>
    );
};
