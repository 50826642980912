import ButtonDropdown from '@rio-cloud/rio-uikit/ButtonDropdown';
import Dialog from '@rio-cloud/rio-uikit/Dialog';
import { MenuItemProps } from '@rio-cloud/rio-uikit/MenuItem';
import { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useAppDispatch, useAppSelector } from '../../../../../configuration/setup/typedReduxHooks';
import { downloadFileContent } from '../../../actions/files/Files.actions';
import { generateDeliveryForecastDocument } from '../../../actions/shipmentDocuments/shipmentDocuments.actions';
import { useDunsNumberFromPath } from '../../../hooks/Routing.hooks';
import { DeliveryInstructionType } from '../../../reducers/deliverySchedules/types';
import { getSelectedDeliverySchedule } from '../../../selectors/deliverySchedules/DeliverySchedules.selector';
import { DeliveryScheduleChildDialogProps, DisplayMode } from '../DeliveryScheduleDialog';
import { DeliveryScheduleDetails } from './DeliveryScheduleDetails';
import { DeliveryScheduleDetailsFooterSection } from './DeliveryScheduleDetailsFooterSection';

export const DELIVERY_SCHEDULE_DETAILS_DIALOG_TEST_ID = 'DELIVERY_SCHEDULE_DETAILS_DIALOG';
export const DELIVERY_SCHEDULES_DIALOG_DOWNLOAD_MENU_TEST_ID = 'DELIVERY_SCHEDULES_DIALOG_DOWNLOAD_MENU_TEST_ID';
export const DELIVERY_SCHEDULES_DIALOG_PRINT_MENU_TEST_ID = 'DELIVERY_SCHEDULES_DIALOG_PRINT_MENU_TEST_ID';

export const DeliveryScheduleDetailsDialog = (props: DeliveryScheduleChildDialogProps) => {
    const dispatch = useAppDispatch();
    const selectedDeliverySchedule = useAppSelector(getSelectedDeliverySchedule);
    const dunsNumber = useDunsNumberFromPath() ?? '';

    const [showDialog, setShowDialog] = useState(false);

    const downloadItems: MenuItemProps[] = [];
    const printItems: MenuItemProps[] = [];

    useEffect(() => {
        setShowDialog(true);
    }, []);

    const deliveryCallOff = selectedDeliverySchedule?.deliveryInstructionReferences.find(
        (deliveryScheduleReference) => deliveryScheduleReference.type === DeliveryInstructionType.LAB,
    );
    const dailyCallOff = selectedDeliverySchedule?.deliveryInstructionReferences.find(
        (deliveryScheduleReference) => deliveryScheduleReference.type === DeliveryInstructionType.FAB,
    );

    if (deliveryCallOff) {
        downloadItems.push({
            value: <FormattedMessage id={'webedi.schedulingData.processIndicator.deliveryCallOff'} />,
            onSelect: () =>
                dispatch(downloadFileContent(dunsNumber, deliveryCallOff.ediFileId, deliveryCallOff.ediFileId)),
        });
        printItems.push({
            value: <FormattedMessage id={'webedi.schedulingData.processIndicator.deliveryCallOff'} />,
            onSelect: () =>
                dispatch(
                    generateDeliveryForecastDocument(dunsNumber, deliveryCallOff.ediFileId, deliveryCallOff.ediFileId),
                ),
        });
    }
    if (dailyCallOff) {
        downloadItems.push({
            value: <FormattedMessage id={'webedi.schedulingData.processIndicator.dailyCallOff'} />,
            onSelect: () => dispatch(downloadFileContent(dunsNumber, dailyCallOff.ediFileId, dailyCallOff.ediFileId)),
        });
        printItems.push({
            value: <FormattedMessage id={'webedi.schedulingData.processIndicator.dailyCallOff'} />,
            onSelect: () =>
                dispatch(generateDeliveryForecastDocument(dunsNumber, dailyCallOff.ediFileId, dailyCallOff.ediFileId)),
        });
    }

    const headerButtons = (
        <>
            <button className={'btn btn-muted'} onClick={() => props.setDisplayMode(DisplayMode.ARCHIVE)}>
                <span className={'rioglyph rioglyph-folder-closed'} />
                <FormattedMessage id={'webedi.deliveryScheduleDetails.archive.label'} />
            </button>
            <ButtonDropdown
                data-testid={DELIVERY_SCHEDULES_DIALOG_DOWNLOAD_MENU_TEST_ID}
                toggleClassName={'btn-muted'}
                title={
                    <span>
                        <span className='rioglyph rioglyph-download' />{' '}
                        <FormattedMessage id={'webedi.download.ediFile'} />
                    </span>
                }
                items={downloadItems}
                disabled={selectedDeliverySchedule?.manuallyCreated}
            />
            <ButtonDropdown
                data-testid={DELIVERY_SCHEDULES_DIALOG_PRINT_MENU_TEST_ID}
                toggleClassName={'btn-muted margin-right-10'}
                title={
                    <span>
                        <span className='rioglyph rioglyph-print' /> <FormattedMessage id={'webedi.print.ediFile'} />
                    </span>
                }
                items={printItems}
                disabled={selectedDeliverySchedule?.manuallyCreated}
            />
        </>
    );

    return (
        <div data-testid={DELIVERY_SCHEDULE_DETAILS_DIALOG_TEST_ID}>
            <Dialog
                show={showDialog}
                title={<FormattedMessage id={'webedi.deliverySchedule.deliveryScheduleDetails.title'} />}
                headerButtons={headerButtons}
                body={<DeliveryScheduleDetails deliverySchedule={selectedDeliverySchedule} />}
                onHide={props.onHide}
                bsSize={Dialog.SIZE_XL}
                footer={
                    <DeliveryScheduleDetailsFooterSection
                        deliverySchedule={selectedDeliverySchedule!}
                        hideDialog={props.onHide}
                    />
                }
                showCloseButton
            />
        </div>
    );
};
