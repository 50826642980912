import ApplicationHeader from '@rio-cloud/rio-uikit/ApplicationHeader';
import ApplicationLayout from '@rio-cloud/rio-uikit/ApplicationLayout';
import VolkswagenApplicationHeader from '@rio-cloud/rio-uikit/VolkswagenApplicationHeader';
import { DefaultUserMenu as RIOUserMenu } from '@rio-cloud/rio-user-menu-component';
import classNames from 'classnames';
import { UserManager } from 'oidc-client-ts';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import { IdentityProvider, config } from '../../../config';
import { DisplayMessagesInterface, IdToken, getDisplayMessages, getIdToken, getLocale } from '../../../configuration';
import { userSelectedLocaleChanged } from '../../../configuration/lang/actions';
import { Dispatch, State } from '../../../configuration/setup/store';
import { VWIdToken } from '../../../configuration/tokenHandling/IdToken';
import { useDunsNumberFromPath } from '../hooks/Routing.hooks';
import { homeLink, useNavLinks } from './NavLinks';
import { ServiceInfoMenuItem } from './ServiceInfo';
import { RioCompanyInfo } from './header/rio/RioCompanyInfo';
import RioMenuItem from './header/rio/RioMenuItem';
import { VwCompanyName } from './header/vw/VwCompanyName';
import { VwSelectedDunsNumber } from './header/vw/VwSelectedDunsNumber';
import { VolkswagenAccountMenu } from './vwSpecific/VolkswagenAccountMenu';

interface AppHeaderOwnProps {
    webSCMPlusFeaturesAreActive: boolean;
    userManager: UserManager;
}

export interface AppHeaderPropsFromState {
    idToken: IdToken;
    displayMessages: DisplayMessagesInterface;
    userLocale: string;
}

const mapStateToProps = (state: State): AppHeaderPropsFromState => ({
    idToken: getIdToken(state),
    displayMessages: getDisplayMessages(state),
    userLocale: getLocale(state),
});

interface AppHeaderPropsFromDispatch {
    handleLogoutClicked: () => void;
    handleLanguageChange: (_: string) => void;
}

const mapDispatchToProps = (dispatch: Dispatch, ownProps: AppHeaderOwnProps): AppHeaderPropsFromDispatch => ({
    handleLogoutClicked: () => {
        ownProps.userManager.signoutRedirect().then(() => console.log('logged out'));
    },
    handleLanguageChange: (newLocale) => dispatch(userSelectedLocaleChanged(newLocale)),
});

const AppHeader = (props: AppHeaderOwnProps & AppHeaderPropsFromState & AppHeaderPropsFromDispatch) => {
    const { webSCMPlusFeaturesAreActive } = props;
    const dunsNumber = useDunsNumberFromPath();
    const navLinks = useNavLinks(dunsNumber, webSCMPlusFeaturesAreActive);

    const isVWEnvironment =
        config.identityProvider === IdentityProvider.VW_QA || config.identityProvider === IdentityProvider.VW;

    const userMenu = isVWEnvironment ? (
        <VolkswagenAccountMenu
            idToken={props.idToken as unknown as VWIdToken}
            currentLocale={props.userLocale}
            onLanguageChange={props.handleLanguageChange}
            onLogoutClick={props.handleLogoutClicked}
        />
    ) : (
        <RIOUserMenu environment={import.meta.env.MODE} preventLogoutRedirect={true} disableCookieModal={true} />
    );

    const vwActionBarItems = [
        <VwCompanyName key={'companyName'} />,
        <VwSelectedDunsNumber key={'selectedDunsNumber'} />,
        <ServiceInfoMenuItem key={'serviceInfoMenuItem'} />,
        userMenu,
    ];

    const rioActionBarItems = [<ServiceInfoMenuItem key={'serviceInfoMenuItem'} />, userMenu];
    dunsNumber && rioActionBarItems.splice(1, 0, <RioCompanyInfo key={'supplierInfoMenuItem'} />);

    const wrapperClassName = classNames('flex-1-0 width-100pct min-width-300 display-flex flex-column padding-5');

    const appNavigator = (
        <div className={wrapperClassName}>
            <RioMenuItem
                title={<FormattedMessage id={'applicationmenu.title'} />}
                description={<FormattedMessage id={'applicationmenu.entry.supplierhub.description'} />}
                icon={'order'}
                link={'/'}
            />
            <RioMenuItem
                title={<FormattedMessage id={'applicationmenu.entry.useradministration'} />}
                description={<FormattedMessage id={'applicationmenu.entry.useradministration.description'} />}
                icon={'user'}
                link={'https://users.iam.rio.cloud/#/users'}
            />
        </div>
    );

    return isVWEnvironment ? (
        <ApplicationLayout.Header className={isVWEnvironment ? 'vw-aktiengesellschaft' : ''}>
            <VolkswagenApplicationHeader navItems={navLinks} homeRoute={homeLink} actionBarItems={vwActionBarItems} />
        </ApplicationLayout.Header>
    ) : (
        <ApplicationLayout.Header>
            <ApplicationHeader
                appNavigator={appNavigator}
                navItems={navLinks}
                homeRoute={homeLink}
                actionBarItems={rioActionBarItems}
                label={<FormattedMessage id={'applicationmenu.title'} />}
                showHomeIcon={false}
            />
        </ApplicationLayout.Header>
    );
};

export default connect(mapStateToProps, mapDispatchToProps)(AppHeader);
