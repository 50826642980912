import Notification from '@rio-cloud/rio-uikit/Notification';
import { Dispatch } from '../../../../configuration/setup/store';
import {
    deleteManagedFreightForwarder,
    getManagedFreightForwarders,
    putManagedFreightForwarder,
} from '../../api/masterData/masterDataCalls';
import { masterDataSlice } from '../../reducers/masterData/MasterData.reducer';
import { ManagedFreightForwarder } from '../../reducers/transportation/types';
import { notifySuccess } from '../NotificationUtils';

export const getAndStoreManagedFreightForwarders = (dunsNumber: string) => (dispatch: Dispatch) => {
    dispatch(masterDataSlice.actions.setIsLoading(true));
    return getManagedFreightForwarders(dunsNumber)
        .then((result) => dispatch(masterDataSlice.actions.updateManagedFreightForwarders(result)))
        .catch((error: Error) => {
            Notification.error(error.message);
        })
        .finally(() => {
            dispatch(masterDataSlice.actions.setIsLoading(false));
        });
};

export const saveManagedFreightForwarderAndRefresh =
    (managedFreightForwarder: ManagedFreightForwarder) => (dispatch: Dispatch) => {
        return putManagedFreightForwarder(managedFreightForwarder)
            .then(() => notifySuccess('webedi.label.success.save'))
            .then(() => dispatch(getAndStoreManagedFreightForwarders(managedFreightForwarder.dunsNumberOwner)))
            .catch((error: Error) => Notification.error(error.message));
    };

export const deleteManagedFreightForwarderAndRefresh = (dunsNumber: string, id: string) => async (dispatch: Dispatch) =>
    deleteManagedFreightForwarder(dunsNumber, id)
        .then(() => notifySuccess('webedi.label.success.delete'))
        .then(() => dispatch(getAndStoreManagedFreightForwarders(dunsNumber)))
        .catch((error: Error) => Notification.error(error.message));
