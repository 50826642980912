import Dialog from '@rio-cloud/rio-uikit/Dialog';
import lodash, { isEmpty } from 'lodash';
import { Field } from 'react-final-form';
import { FormattedMessage } from 'react-intl';
import { useAppDispatch, useAppSelector } from '../../../../../configuration/setup/typedReduxHooks';
import { closeAdditionalPropertiesDialog } from '../../../actions/shipments/Shipments.actions';
import { LoadItem, Shipment } from '../../../reducers/shipments/types';
import { getLoadItemPathForAdditionalPropertiesDialog } from '../../../selectors/shipments/Shipments.selector';
import { CheckboxInput } from '../../common/form/CheckboxInput';
import { DatePickerInput } from '../../common/form/DatePickerInput';
import { TextInput } from '../../common/form/TextInput';
import { AdditionalProductIdInformation } from './AdditionalProductIdInformation';
import { ShipmentEditErrors } from './types';

export interface AdditionalPropertiesDialogProps {
    shipment: Shipment;
    formErrors?: ShipmentEditErrors;
}

export const ADDITIONAL_PROPERTIES_BATCH_NUMBER_TEST_ID = 'ADDITIONAL_PROPERTIES_BATCH_NUMBER_TEST_ID';
export const ADDITIONAL_PROPERTIES_EXPIRY_DATE_TEST_ID = 'ADDITIONAL_PROPERTIES_EXPIRY_DATE_TEST_ID';
export const ADDITIONAL_PROPERTIES_SUBJECT_TO_PREFERENCE_AUTHORIZATION_TEST_ID =
    'ADDITIONAL_PROPERTIES_SUBJECT_TO_PREFERENCE_AUTHORIZATION_TEST_ID';

export const AdditionalPropertiesDialog = (props: AdditionalPropertiesDialogProps) => {
    const { shipment, formErrors } = props;
    const loadItemPath = useAppSelector(getLoadItemPathForAdditionalPropertiesDialog);
    const dispatch = useAppDispatch();

    if (loadItemPath === undefined) {
        return null;
    }
    const loadItemErrors = lodash.get(formErrors, loadItemPath);

    const renderFooter = () => {
        const disabled = loadItemErrors?.batchNumber || loadItemErrors?.additionalProductId;
        return (
            <div className={'pull-right btn-toolbar'}>
                <button
                    type={'button'}
                    className={'btn btn-primary'}
                    onClick={() => dispatch(closeAdditionalPropertiesDialog())}
                    disabled={disabled !== undefined}
                >
                    <FormattedMessage id={'webedi.label.confirm'} />
                </button>
            </div>
        );
    };

    const body = (
        <div>
            <label>
                <FormattedMessage id={'webedi.shipment.additionalProperties.batchNumber'} />
            </label>
            <div data-testid={ADDITIONAL_PROPERTIES_BATCH_NUMBER_TEST_ID}>
                <Field<string>
                    name={`${loadItemPath}.batchNumber`}
                    component={TextInput}
                    parse={(value) => (isEmpty(value) ? (undefined as unknown as string) : value.toUpperCase())}
                    className={'form-control'}
                />
            </div>
            <hr className={'margin-left--20 margin-right--20 margin-top-25 margin-bottom-25'} />
            <label>
                <FormattedMessage id={'webedi.shipment.additionalProperties.expiryDate'} />
            </label>
            <div data-testid={ADDITIONAL_PROPERTIES_EXPIRY_DATE_TEST_ID}>
                <Field<string | undefined> name={`${loadItemPath}.expiryDate`} component={DatePickerInput} />
            </div>
            <hr className={'margin-left--20 margin-right--20 margin-top-25 margin-bottom-25'} />
            <AdditionalProductIdInformation loadItemPath={loadItemPath} />
            <hr className={'margin-left--20 margin-right--20 margin-top-25 margin-bottom-25'} />
            <div data-testid={ADDITIONAL_PROPERTIES_SUBJECT_TO_PREFERENCE_AUTHORIZATION_TEST_ID}>
                <Field<boolean>
                    name={`${loadItemPath}.isSubjectToPreferenceAuthorization`}
                    component={CheckboxInput}
                    type={'checkbox'}
                >
                    <FormattedMessage id={'webedi.shipment.additionalProperties.isSubjectToPreferenceAuthorization'} />
                </Field>
            </div>
        </div>
    );

    return (
        <Dialog
            show={true}
            title={
                <span>
                    <FormattedMessage id={'webedi.shipment.additionalProperties.title'} />
                    {` ${(lodash.get(shipment, loadItemPath) as LoadItem).articleNumberBuyer}`}
                </span>
            }
            body={body}
            footer={renderFooter()}
            showCloseButton={false}
            onCloseValidation={() => false}
            disableEsc={true}
            bsSize={'sm'}
        />
    );
};
