import Checkbox from '@rio-cloud/rio-uikit/Checkbox';
import { BaseSyntheticEvent, ReactElement, ReactNode, useEffect, useRef } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { FormattedMessage } from 'react-intl';
import { useAppSelector } from '../../../../../configuration/setup/typedReduxHooks';
import { measurementUnitCodeToTranslation } from '../../../../../utils';
import {
    DispatchProposal,
    DispatchProposalItem,
    PackagedDispatchProposalItem,
} from '../../../domain/dispatchProposal.types';
import { PackagedArticleInfo } from '../../../reducers/shipments/packaging.types';
import {
    getSelectedDispatchProposalItemToPackage,
    getSelectedDispatchProposalToPackage,
} from '../../../selectors/dispatchProposals/DispatchProposals.selector';
import { PackagedArticlesInfoTooltip } from '../../shipments/packaging/dialogs/PackagedArticlesInfoTooltip';
import { PackageTogetherFormValues, PackagedDispatchProposalItemWithAmount } from './PackageTogetherDialog';

export const PackageTogetherStep1 = () => {
    const selectedDispatchProposalToPackage: DispatchProposal = useAppSelector(getSelectedDispatchProposalToPackage);
    const selectedDispatchProposalItemToPackage: PackagedDispatchProposalItem = useAppSelector(
        getSelectedDispatchProposalItemToPackage,
    );

    const mapToDispatchProposalItems = (dispatchProposal: DispatchProposal): PackagedDispatchProposalItem[] => {
        return (
            dispatchProposal?.items
                ?.filter(
                    (item) =>
                        item.type === 'PACKAGED_DISPATCH_PROPOSAL_ITEM' &&
                        item.identifier.shipmentId === selectedDispatchProposalItemToPackage.identifier.shipmentId,
                )
                ?.map((item) => item as PackagedDispatchProposalItem)
                ?.filter((item) => item.articleContents.length === 1) || []
        );
    };

    const dispatchProposalItems: PackagedDispatchProposalItem[] = mapToDispatchProposalItems(
        selectedDispatchProposalToPackage,
    );

    const {
        formState: { errors },
    } = useFormContext<PackageTogetherFormValues>();

    return (
        <>
            <div>
                <InfoBox />
                {errors?.selectedItemsToPackage && <ErrorHelpBox />}
            </div>
            <div
                className={
                    'display-flex flex-column padding-bottom-5pct margin-left-20pct margin-right-20pct min-height-500'
                }
            >
                <div className={'display-flex flex-column'}>
                    <div className={'text-size-18 text-center margin-bottom-25'}>
                        <FormattedMessage id={'webedi.packaging.whatToPackage'} />
                    </div>
                    <div className={'counter color-highlight'}>
                        <div data-count={'1'}>
                            <span>
                                <FormattedMessage
                                    id={'webedi.packaging.regular.groupingSelection.explanation'}
                                    values={{
                                        b: (chunks: ReactNode) => <b>{chunks}</b>,
                                    }}
                                />
                                {':'}
                            </span>
                        </div>
                    </div>
                </div>
                <div className={'display-flex flex-column flex-1-1-0 justify-content-center width-100pct'}>
                    <PackageDispatchProposalItemsToggle dispatchProposalItems={dispatchProposalItems} />
                    <div>
                        <DispatchProposalItems
                            dispatchProposalItems={dispatchProposalItems}
                            selectedDispatchProposalItem={selectedDispatchProposalItemToPackage}
                        />
                    </div>
                </div>
            </div>
        </>
    );
};

interface PackageDispatchProposalItemsToggleProps {
    dispatchProposalItems: PackagedDispatchProposalItem[];
}

export const PackageDispatchProposalItemsToggle = (props: PackageDispatchProposalItemsToggleProps) => {
    const { dispatchProposalItems } = props;
    const packageDispatchProposalItemsLength = dispatchProposalItems.length;

    const { control } = useFormContext<PackageTogetherFormValues>();
    const findSelectedItems = (event: BaseSyntheticEvent): PackagedDispatchProposalItemWithAmount[] => {
        if (!event.target.checked) {
            return [];
        }
        return dispatchProposalItems.map((item) => ({ dispatchProposalItem: item }));
    };

    return (
        <div
            className={
                'border-style-solid border-bottom-only border-color-light border-width-1 padding-15 margin-bottom-15'
            }
        >
            <Controller
                rules={{ required: true }}
                name={`selectedItemsToPackage`}
                control={control}
                render={({ field }) => (
                    <Checkbox
                        checked={field.value.length === packageDispatchProposalItemsLength}
                        onChange={(event) => field.onChange(findSelectedItems(event))}
                        label={
                            <span className={'text-medium'}>
                                <FormattedMessage id={'webedi.label.selectAll'} />
                                &nbsp; ({packageDispatchProposalItemsLength})
                            </span>
                        }
                    />
                )}
            />
        </div>
    );
};

interface DispatchProposalItemProps {
    dispatchProposalItems: PackagedDispatchProposalItem[];
    selectedDispatchProposalItem: DispatchProposalItem;
}

const DispatchProposalItems = (props: DispatchProposalItemProps) => {
    const { control } = useFormContext<PackageTogetherFormValues>();
    const { dispatchProposalItems } = props;

    const updateSelectedPackagedItems = (
        isChecked: boolean,
        dispatchProposalItem: PackagedDispatchProposalItem,
        currentSelectedItemsToPackage: PackagedDispatchProposalItemWithAmount[],
        dispatchProposalItemIndex: number,
        onChangeHandler: (item: PackagedDispatchProposalItemWithAmount[]) => void,
    ) => {
        const itemsExists = dispatchProposalItemIndex > -1;

        if (isChecked && !itemsExists) {
            currentSelectedItemsToPackage.push({ dispatchProposalItem });
        }

        if (!isChecked && itemsExists) {
            currentSelectedItemsToPackage.splice(dispatchProposalItemIndex, 1);
        }

        onChangeHandler(currentSelectedItemsToPackage);
    };

    const validateItemsLength = (fieldValue: PackagedDispatchProposalItemWithAmount[]) => {
        return fieldValue.length >= 2 || 'Select at least 2 items';
    };

    return (
        <div className={'children-last-margin-bottom-0'}>
            <Controller
                rules={{
                    required: true,
                    validate: {
                        atLeast2: validateItemsLength,
                    },
                }}
                name={`selectedItemsToPackage`}
                control={control}
                render={({ field }): ReactElement => (
                    <>
                        {dispatchProposalItems.map((dispatchProposalItem) => {
                            const currentSelectedItemsToPackage =
                                field.value as PackagedDispatchProposalItemWithAmount[];

                            const dispatchProposalItemIndex = currentSelectedItemsToPackage.findIndex(
                                (item) =>
                                    item.dispatchProposalItem?.identifier?.id === dispatchProposalItem.identifier?.id,
                            );

                            const isItemSelected = dispatchProposalItemIndex > -1;

                            return (
                                <div key={dispatchProposalItem.identifier.id}>
                                    <Checkbox
                                        custom
                                        checked={isItemSelected}
                                        onChange={(event) =>
                                            updateSelectedPackagedItems(
                                                event.target.checked,
                                                dispatchProposalItem,
                                                currentSelectedItemsToPackage,
                                                dispatchProposalItemIndex,
                                                field.onChange,
                                            )
                                        }
                                        className={`padding-15 margin-bottom-15 user-select-none border bg-white ${isItemSelected ? 'border-color-highlight' : ''}`}
                                    >
                                        <div
                                            className={'checkbox-text-wrapper display-flex align-items-center'}
                                            key={dispatchProposalItem.identifier.id}
                                        >
                                            <div className={'checkbox-text'} />
                                            {renderBoxContent(dispatchProposalItem)}
                                        </div>
                                    </Checkbox>
                                </div>
                            );
                        })}
                    </>
                )}
            />
        </div>
    );
};

const renderBoxContent = (dispatchProposalItem: PackagedDispatchProposalItem) => {
    const articleContent = dispatchProposalItem.articleContents[0];
    const packagedArticleInfo: PackagedArticleInfo = {
        articleNumberBuyer: articleContent.articleNumberBuyer,
        amount: articleContent.quantity,
        loadItemPositionReference: {
            position: dispatchProposalItem.referencedDeliveryNotePositions[0].position,
            deliveryNoteNumber: dispatchProposalItem.referencedDeliveryNotePositions[0].deliveryNoteNumber,
        },
    };

    return (
        <>
            <div className={'display-flex align-items-center justify-content-between'}>
                <div className={'margin-left-10'}>
                    {dispatchProposalItem.outerPackaging.count} &nbsp;
                    <FormattedMessage
                        id={measurementUnitCodeToTranslation(
                            dispatchProposalItem.articleContents[0].quantity.measurementUnitCode,
                        )}
                    />
                </div>
                <div className={'margin-left-20'}>
                    <span className={'text-bold'}>{dispatchProposalItem.outerPackaging.type}</span>{' '}
                    <PackagedArticlesInfoTooltip packagedArticlesInfo={[packagedArticleInfo]} />
                    <div className={'text-size-12 text-color-gray ellipsis-1'}>
                        <span>
                            <FormattedMessage id={'webedi.packaging.contents'} />
                            {': '}
                            {packagedArticleInfo.articleNumberBuyer}
                        </span>
                    </div>
                </div>
            </div>
            <div className={'margin-left-auto'}>
                <span className={'label label-default label-filled label-condensed'}>
                    <FormattedMessage id={'webedi.packaging.category.loadCarrier'} />
                </span>
            </div>
        </>
    );
};

const InfoBox = () => {
    return (
        <div className={'alert alert-primary margin-bottom-20'}>
            <div className={'display-flex align-items-center'}>
                <span className={'text-color-primary text-size-h4 margin-right-10 rioglyph rioglyph-info-sign'} />
                <div>
                    <strong>
                        <FormattedMessage id={'webedi.packaging.minItemsToPackage.info'} />
                    </strong>
                </div>
            </div>
        </div>
    );
};

const ErrorHelpBox = () => {
    const ref = useRef<HTMLDivElement>(null);
    useEffect(() => {
        ref.current?.scrollIntoView({ behavior: 'smooth', block: 'end' });
    });

    return (
        <div ref={ref} className={'alert alert-danger margin-bottom-20'} role={'alert'}>
            <div className={'display-flex align-items-center'}>
                <span className={`text-color-danger text-size-h4 margin-right-10 rioglyph rioglyph-error-sign`} />
                <div>
                    <strong>
                        <FormattedMessage id={'webedi.packaging.minItemsToPackage.error'} />
                    </strong>
                </div>
            </div>
        </div>
    );
};
