import ClearableInputUiKit from '@rio-cloud/rio-uikit/ClearableInput';
import { debounce } from 'lodash';
import { KeyboardEvent, memo, useCallback } from 'react';
import { useIntl } from 'react-intl';
import { useAppDispatch, useAppSelector } from '../../../../configuration/setup/typedReduxHooks';
import { fetchAndStoreShipmentSuggestions } from '../../actions/suggestions/ShipmentSuggestions.actions';
import { shipmentSuggestionsSlice } from '../../reducers/suggestions/ShipmentSuggestions.reducer';
import { getShipmentSuggestionsQuery } from '../../selectors/suggestions/ShipmentSuggestions.selector';

export const SHIPMENT_SUGGESTION_SEARCH_TEST_ID = 'SHIPMENT_SUGGESTION_SEARCH_TEST_ID';

export const SHIPMENT_SUGGESTION_SEARCH_DEBOUNCE_TIME = 750;

export const ShipmentSuggestionSearch = () => {
    const intl = useIntl();
    const dispatch = useAppDispatch();
    const query = useAppSelector(getShipmentSuggestionsQuery);

    const debouncedSearch = useCallback(
        debounce((searchFieldInput: string) => {
            dispatch(fetchAndStoreShipmentSuggestions({ q: searchFieldInput, offset: 0 }));
        }, SHIPMENT_SUGGESTION_SEARCH_DEBOUNCE_TIME),
        [],
    );

    const onKeyPress = useCallback(
        (e: KeyboardEvent) => e.key === 'Enter' && debouncedSearch.flush(),
        [debouncedSearch],
    );

    return (
        <div className={'input-group width-250'}>
            <span className={'input-group-addon'}>
                <span className={'rioglyph rioglyph-search'} aria-hidden={'true'} />
            </span>
            <MemoizedClearableInput
                data-testid={SHIPMENT_SUGGESTION_SEARCH_TEST_ID}
                placeholder={intl.formatMessage({ id: 'webedi.deliverySchedule.searchBar.articleNumber' })}
                onChange={(newValue: string) => {
                    dispatch(
                        shipmentSuggestionsSlice.actions.updateQuery({
                            ...query,
                            params: { ...query.params, q: newValue },
                        }),
                    );
                    debouncedSearch(newValue);
                }}
                onKeyPress={onKeyPress}
                value={query.params.q}
            />
        </div>
    );
};

const MemoizedClearableInput = memo(ClearableInputUiKit);
