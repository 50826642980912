import Collapse from '@rio-cloud/rio-uikit/Collapse';
import { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useAppSelector } from '../../../../configuration/setup/typedReduxHooks';
import {
    getShipmentSuggestionsQuery,
    getTotalNumberOfShipmentSuggestions,
} from '../../selectors/suggestions/ShipmentSuggestions.selector';
import { Banner } from '../common/Banner';
import { ShipmentSuggestionPaginationBar } from './ShipmentSuggestionPaginationBar';
import { ShipmentSuggestionSearch } from './ShipmentSuggestionSearch';
import { ShipmentSuggestionsFilterRow } from './ShipmentSuggestionsFilterRow';
import { ShipmentSuggestionsFilterToggleButton } from './ShipmentSuggestionsFilterToggleButton';
import { ShipmentSuggestionsList } from './ShipmentSuggestionsList';
import { ShipmentSuggestionsSelectedFilters } from './ShipmentSuggestionsSelectedFilters';

export const SHIPMENT_SUGGESTION_HEADER_EXPAND_ALL_BUTTON = 'SHIPMENT_SUGGESTION_HEADER_EXPAND_ALL_BUTTON';

export const ShipmentSuggestionsView = () => {
    const totalNumberSuggestedShipments = useAppSelector(getTotalNumberOfShipmentSuggestions);
    const query = useAppSelector(getShipmentSuggestionsQuery);

    const [showFilter, setShowFilter] = useState<boolean>(false);
    const [allSuggestionsExpandedOpen, setAllSuggestionsExpandedOpen] = useState(true);

    return (
        <>
            <Banner
                type={'secondary'}
                messages={[
                    <FormattedMessage
                        id={'webedi.banner.hint.adjustCumulativeQuantitySentV2'}
                        key={'webedi.banner.hint.adjustCumulativeQuantitySentV2'}
                    />,
                    <FormattedMessage
                        id={'webedi.banner.hint.after.goLive.delivery.schedules.message3'}
                        key={'webedi.banner.hint.after.goLive.delivery.schedules.message3'}
                    />,
                ]}
            />
            <div className={'table-toolbar margin-bottom-0'}>
                <div className={'table-toolbar-container'}>
                    <div className={'table-toolbar-group-right'}>
                        <div className={'table-toolbar-column'}>
                            <ShipmentSuggestionsSelectedFilters />
                        </div>
                        <div className={'table-toolbar-column'}>
                            <button
                                type={'button'}
                                className={'btn btn-default btn-icon-only'}
                                onClick={() => setAllSuggestionsExpandedOpen(!allSuggestionsExpandedOpen)}
                                data-testid={SHIPMENT_SUGGESTION_HEADER_EXPAND_ALL_BUTTON}
                            >
                                <span
                                    className={`rioglyph rioglyph-resize-${allSuggestionsExpandedOpen ? 'small' : 'full'}`}
                                />
                            </button>
                        </div>
                        <div className={'table-toolbar-column'}>
                            <ShipmentSuggestionsFilterToggleButton
                                active={showFilter}
                                toggleShow={() => setShowFilter((current) => !current)}
                            />
                        </div>
                        <div className={'table-toolbar-column'}>
                            <ShipmentSuggestionSearch />
                        </div>
                    </div>
                </div>
            </div>
            <div className={'table-row margin-bottom-10'}>
                <Collapse open={showFilter} unmountOnExit={true}>
                    <div className={'panel-wrapper'}>
                        <div className={'panel panel-default'}>
                            <div className={'display-flex justify-content-end'}>
                                <button
                                    className={'btn btn-xs btn-icon-only btn-outline'}
                                    onClick={() => setShowFilter(false)}
                                >
                                    <span className={'rioglyph rioglyph-remove toggle-icon close'} />
                                </button>
                            </div>
                            <div className={'panel-body'}>
                                <ShipmentSuggestionsFilterRow />
                            </div>
                        </div>
                    </div>
                </Collapse>
            </div>
            <ShipmentSuggestionsList allSuggestionExpanderOpen={allSuggestionsExpandedOpen} />
            <div className={'display-flex flex-row align-items-center justify-content-center'}>
                <ShipmentSuggestionPaginationBar
                    totalNumber={totalNumberSuggestedShipments}
                    limit={query.params.limit}
                    offset={query.params.offset}
                />
            </div>
        </>
    );
};
