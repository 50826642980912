import ActionBarItem from '@rio-cloud/rio-uikit/ActionBarItem';
import OverlayTrigger from '@rio-cloud/rio-uikit/OverlayTrigger';
import Select, { type SelectOption } from '@rio-cloud/rio-uikit/Select';
import SimpleDialog from '@rio-cloud/rio-uikit/SimpleDialog';
import Tooltip from '@rio-cloud/rio-uikit/Tooltip';
import { FunctionComponent, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { VWIdToken } from '../../../../configuration/tokenHandling/IdToken';
import {
    ADMIN_ROLE,
    DEFAULT_ROLE,
    READ_ONLY_COUNTRY_ADMIN_ROLE,
    READ_ONLY_GLOBAL_ADMIN_ROLE,
    READ_ONLY_PLANT_ADMIN_ROLE,
    SUPPLIER_ROLE,
} from '../../../../configuration/tokenHandling/selectors';

export interface VolkswagenAccountMenuProps {
    idToken: VWIdToken;
    onLogoutClick: () => unknown;
    onLanguageChange: (newLocale: string) => unknown;
    currentLocale: string;
}

const mapToIdToken = (
    idTokenExternal: VolkswagenAccountMenuProps['idToken'],
): VolkswagenAccountMenuInternalProps['idToken'] => ({
    name: idTokenExternal.name || '',
    vwUserId: idTokenExternal.preferred_username?.toUpperCase() || '',
    firstName: idTokenExternal.given_name || '',
    lastName: idTokenExternal.family_name || '',
    email: idTokenExternal.email || '',
    dunsNumber: idTokenExternal.duns_number || '',
    roles: idTokenExternal.groups || [],
});

export const VolkswagenAccountMenu: FunctionComponent<VolkswagenAccountMenuProps> = (
    props: VolkswagenAccountMenuProps,
) => (
    <VolkswagenAccountMenuInternal
        idToken={mapToIdToken(props.idToken)}
        onLogoutClick={props.onLogoutClick}
        onLanguageChange={props.onLanguageChange}
        currentLocale={props.currentLocale}
    />
);

export const VOLKSWAGEN_ACTION_BAR_ITEM_TEST_ID = 'VOLKSWAGEN__ACCOUNT_MENU_ACTIONBAR_ITEM';
export const VOLKSWAGEN_ACCOUNT_MENU_CONTENT_TEST_ID = 'VOLKSWAGEN_ACCOUNT_MENU_CONTENT';

interface IdTokenInternal {
    name: string;
    vwUserId: string;
    firstName: string;
    lastName: string;
    email: string;
    dunsNumber: string;
    roles: string[];
}

interface VolkswagenAccountMenuInternalProps {
    idToken: IdTokenInternal;
    onLogoutClick: () => unknown;
    onLanguageChange: (newLocale: string) => unknown;
    currentLocale: string;
}

const VolkswagenAccountMenuInternal = (props: VolkswagenAccountMenuInternalProps) => {
    const formattedPreferredUserName = `(${props.idToken.vwUserId})`;

    const [isShown, setShow] = useState(false);

    const renderRoles = () => {
        const roleToRoleName = (role: string) => {
            switch (role) {
                case ADMIN_ROLE:
                    return <FormattedMessage id={'vw-account-menu.roles.webscm.admin'} tagName={'span'} />;
                case READ_ONLY_GLOBAL_ADMIN_ROLE:
                    return <FormattedMessage id={'vw-account-menu.roles.webscm.readOnlyAdmin'} tagName={'span'} />;
                case READ_ONLY_PLANT_ADMIN_ROLE:
                    return <FormattedMessage id={'vw-account-menu.roles.webscm.plantAdmin'} tagName={'span'} />;
                case READ_ONLY_COUNTRY_ADMIN_ROLE:
                    return <FormattedMessage id={'vw-account-menu.roles.webscm.countryAdmin'} tagName={'span'} />;
                case SUPPLIER_ROLE:
                    return <FormattedMessage id={'vw-account-menu.roles.webscm.supplier'} tagName={'span'} />;
                default:
                    return (
                        <span>
                            <FormattedMessage id={'vw-account-menu.roles.unknownRole'} />: &#39;{role}&#39;
                        </span>
                    );
            }
        };

        const roles = props.idToken.roles.filter((role: string) => role !== DEFAULT_ROLE);
        switch (roles.length) {
            case 0:
                return null;
            case 1:
                return roleToRoleName(roles[0]);
            default:
                return (
                    <OverlayTrigger
                        placement='bottom'
                        overlay={
                            <Tooltip id='tooltip' className='bottom'>
                                {roles
                                    .map((it) => roleToRoleName(it))
                                    // @ts-ignore
                                    .reduce((previous, current) => [previous, ', ', current] as any)}
                            </Tooltip>
                        }
                    >
                        <span>
                            <FormattedMessage id={'vw-account-menu.roles.multipleRoleHint'} />
                        </span>
                    </OverlayTrigger>
                );
        }
    };

    const renderLegalEntityInfo = () => (
        <>
            <hr className={'margin-top-5 margin-bottom-5'} />
            <div>DUNS: {props.idToken.dunsNumber}</div>
        </>
    );

    return (
        <ActionBarItem id={'accountMenu'}>
            <SimpleDialog
                title={<FormattedMessage id={'intl-msg:settingsDialog.tab.language.headline'} />}
                content={
                    <LanguageSelectionComponent
                        currentLocale={props.currentLocale}
                        handleLocaleChange={props.onLanguageChange}
                    />
                }
                show={isShown}
                onClose={() => setShow(false)}
            />
            <ActionBarItem.Icon>
                <span className={'icon rioglyph rioglyph-user'} data-testid={VOLKSWAGEN_ACTION_BAR_ITEM_TEST_ID} />
            </ActionBarItem.Icon>
            <ActionBarItem.Popover>
                <div
                    className={'popover-content margin-0 padding-0'}
                    data-testid={VOLKSWAGEN_ACCOUNT_MENU_CONTENT_TEST_ID}
                >
                    <div data-cy={'user-details'} className={'padding-bottom-10 text-color-dark'}>
                        <div>
                            <b>{`${props.idToken.firstName} ${props.idToken.lastName} ${formattedPreferredUserName}`}</b>
                        </div>
                        <div>{props.idToken.email}</div>
                        <div>{renderRoles()}</div>
                    </div>
                    {props.idToken.dunsNumber && renderLegalEntityInfo()}
                    <hr className={'margin-top-5 margin-bottom-5'} />
                    <button
                        type='button'
                        className={'btn btn-info btn-link btn-link-inline'}
                        onClick={() => setShow(true)}
                    >
                        <span className={'rioglyph rioglyph-cog'} />
                        <FormattedMessage id={'intl-msg:settingsDialog.tab.language.headline'} />
                    </button>
                    <hr className={'margin-top-5 margin-bottom-5'} />
                    <ul className={'list-unstyled'}>
                        <li>
                            <button
                                className={'btn btn-link btn-s padding-0 text-color-dark'}
                                tabIndex={1}
                                data-cy={'logout-btn'}
                                onClick={props.onLogoutClick}
                            >
                                <span
                                    className={'rioglyph rioglyph-off margin-right-5 text-size-large text-color-dark'}
                                />
                                <FormattedMessage id={'intl-msg:popover.logout'} />
                            </button>
                        </li>
                    </ul>
                </div>
            </ActionBarItem.Popover>
        </ActionBarItem>
    );
};

const LanguageSelectionComponent = (props: { currentLocale: string; handleLocaleChange: (_: string) => unknown }) => {
    const options = [
        { id: 'de-DE', label: 'Deutsch' },
        { id: 'en-GB', label: 'English' },
        { id: 'es-ES', label: 'Espanõl' },
        { id: 'it-IT', label: 'Italiano' },
    ];
    return (
        <div>
            <Select
                options={options}
                onChange={(item?: SelectOption) => item && props.handleLocaleChange(item.id)}
                value={[props.currentLocale]}
            />
        </div>
    );
};
