import Dialog from '@rio-cloud/rio-uikit/Dialog';
import InfoDialog from '@rio-cloud/rio-uikit/InfoDialog';
import Spinner from '@rio-cloud/rio-uikit/Spinner';
import { FormattedMessage } from 'react-intl';
import { useAppSelector } from '../../../../../configuration/setup/typedReduxHooks';
import { isShipmentDocumentsGenerationRunningForSelectedShipment } from '../../../selectors/shipments/ShipmentDocuments.selector';

export const DOCUMENTS_GENERATION_LOADING_TEST_ID = 'DOCUMENTS_GENERATION_LOADING_TEST_ID';
export const DOCUMENTS_GENERATION_LOADING_SPINNER_TEST_ID = 'DOCUMENTS_GENERATION_LOADING_SPINNER_TEST_ID';

export const DocumentGenerationInProgressInfoDialog = () => {
    const isGenerationInProgress = useAppSelector(isShipmentDocumentsGenerationRunningForSelectedShipment);

    const dialogContent = (
        <div className='text-center' data-testid={DOCUMENTS_GENERATION_LOADING_TEST_ID}>
            <div className='margin-bottom-15'>
                <FormattedMessage id={'webedi.shipmentDocuments.generateDocuments.loading'} />
            </div>
            <div data-testid={DOCUMENTS_GENERATION_LOADING_SPINNER_TEST_ID}>
                <Spinner isDoubleSized={true} />
            </div>
        </div>
    );

    return (
        <InfoDialog show={isGenerationInProgress} content={dialogContent} bsSize={Dialog.SIZE_SM} disableEsc={true} />
    );
};
