import Dialog from '@rio-cloud/rio-uikit/Dialog';
import Notification from '@rio-cloud/rio-uikit/Notification';
import { useState } from 'react';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import { FormattedMessage } from 'react-intl';
import { useAppDispatch } from '../../../../../configuration/setup/typedReduxHooks';
import {
    fetchAndStoreDeliveryScheduleFilterValues,
    fetchAndStoreDeliverySchedules,
} from '../../../actions/deliverySchedules/DeliverySchedules.actions';
import { usePostDeliveryScheduleMutation } from '../../../api/deliverySchedule/deliveryScheduleApi';
import { useDunsNumberFromPath } from '../../../hooks/Routing.hooks';
import { ErrorBoxForErrorCode } from '../../common/ErrorBox';
import { Footer } from '../../common/dialog/Footer';
import { CreationConfirmation } from './CreationConfirmation';
import { Step1 } from './Step1';
import { Step2 } from './Step2';
import { Step3 } from './Step3';
import { ManualCreationOfDeliverySchedulesFormValues, isComplete } from './formConfig';
import { mapToPostDeliverySchedule } from './mapper';

export const MANUAL_CREATION_OF_DELIVERY_SCHEDULES_FORM_ID = 'MANUAL_CREATION_OF_DELIVERY_SCHEDULES_FORM_ID';

export interface ManualCreationOfDeliveryScheduleDialogProps {
    showCreateDialog: boolean;
    close: () => void;
}

export const ManualCreationOfDeliveryScheduleDialog = (props: ManualCreationOfDeliveryScheduleDialogProps) => {
    const { showCreateDialog, close } = props;
    const [currentStep, setCurrentStep] = useState(1);
    const [showConfirmation, setShowConfirmation] = useState(false);
    const methods = useForm<ManualCreationOfDeliverySchedulesFormValues>({
        mode: 'all',
        defaultValues: {
            schedulingData: [{}],
        },
    });
    const dispatch = useAppDispatch();
    const reset = methods.reset;
    const onClose = () => {
        setCurrentStep(1);
        reset();
        setError(undefined);
        setShowConfirmation(false);
        close();
    };

    const dunsNumber = useDunsNumberFromPath() ?? '';
    const [postDeliverySchedule, postDeliveryScheduleResult] = usePostDeliveryScheduleMutation();
    const [error, setError] = useState<undefined | any>(undefined);

    const onSubmit: SubmitHandler<ManualCreationOfDeliverySchedulesFormValues> = async (data) => {
        try {
            if (isComplete(data)) {
                await postDeliverySchedule(mapToPostDeliverySchedule(data, dunsNumber)).unwrap();
                Notification.success(<FormattedMessage id={'webedi.deliverySchedule.creation.success'} />);
                setShowConfirmation(true);
                setError(undefined);
            } else {
                setError('Missing mandatory properties. This should not happen as we validate the form.');
            }
        } catch (err) {
            setError(err);
        } finally {
            try {
                await dispatch(fetchAndStoreDeliverySchedules());
                await dispatch(fetchAndStoreDeliveryScheduleFilterValues(dunsNumber));
            } catch {}
        }
    };

    const goNext = async () => {
        const validationsPass = await methods.trigger();
        if (validationsPass) {
            setCurrentStep((current) => current + 1);
        }
    };
    const goPrev = () => setCurrentStep((current) => current - 1);
    const renderStep = () => {
        switch (currentStep) {
            case 1:
                return <Step1 />;
            case 2:
                return <Step2 />;
            case 3:
                return <Step3 />;
            default:
                return null;
        }
    };

    return (
        <Dialog
            show={showCreateDialog}
            onClose={onClose}
            title={<FormattedMessage id={'webedi.deliverySchedules.createManual'} />}
            body={
                <>
                    {error && (
                        <ErrorBoxForErrorCode
                            errorCode={error?.data?.errorCode}
                            headerKey={'webedi.deliverySchedule.creation.failure'}
                        />
                    )}
                    <FormProvider {...methods}>
                        {' '}
                        {showConfirmation ? (
                            <CreationConfirmation
                                articleNumberBuyer={methods.getValues().articleNumberBuyer!}
                                deliveryScheduleId={postDeliveryScheduleResult?.data?.id}
                            />
                        ) : (
                            <form
                                id={MANUAL_CREATION_OF_DELIVERY_SCHEDULES_FORM_ID}
                                onSubmit={methods.handleSubmit(onSubmit)}
                            >
                                {renderStep()}
                            </form>
                        )}
                    </FormProvider>
                </>
            }
            footer={
                !showConfirmation ? (
                    <Footer
                        formId={MANUAL_CREATION_OF_DELIVERY_SCHEDULES_FORM_ID}
                        totalSteps={3}
                        currentStep={currentStep}
                        onClose={onClose}
                        goNext={goNext}
                        goPrev={goPrev}
                    />
                ) : (
                    <div className={'footer float-right'}>
                        <button onClick={onClose} className={'btn btn-default'}>
                            <FormattedMessage id={'webedi.common.close'} />
                        </button>
                    </div>
                )
            }
            bsSize={'lg'}
        />
    );
};
