import ErrorState from '@rio-cloud/rio-uikit/ErrorState';
import NotFoundState from '@rio-cloud/rio-uikit/NotFoundState';
import hash from 'object-hash';
import { Fragment, useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import { useDiscoveryNextGenFeatures } from '../../../../configuration/featureToggle/hooks';
import { useAppDispatch, useAppSelector } from '../../../../configuration/setup/typedReduxHooks';
import { areDaysEqual } from '../../../../dateUtils';
import { neverReachedFor } from '../../../../utils';
import {
    useGetDispatchProposalProblemsQuery,
    useGetDispatchProposalsQuery,
} from '../../api/dispatchProposal/dispatchProposalApi';
import {
    DispatchProposal,
    DispatchProposalFreightForwarderChangedProblem,
    DispatchProposalNotCompletelyPackagedProblem,
    DispatchProposalProblem,
    ManualDispatchProposalDraft,
    MissingLoadingLocationProblem,
    MissingPackagingHeightProblem,
    containsProblemsOfDispatchProposal,
} from '../../domain/dispatchProposal.types';
import { TransportConcept } from '../../domain/meansOfTransport.types';
import { useDunsNumberFromPath } from '../../hooks/Routing.hooks';
import { dispatchProposalsSlice } from '../../reducers/dispatchProposal/DispatchProposalsReducer';
import { Dialog, DispatchProposalsFilterParams } from '../../reducers/dispatchProposal/types';
import { getOrderValidationErrors } from '../../selectors/courierOrderIntent/CourierOrderIntent.selector';
import {
    getAddArticleSidebarOpen,
    getDispatchProposalsSelectedFilters,
    getManualDispatchProposalDrafts,
    getOverviewDateRangeISO,
    getOverviewSelectedDate,
    getSelectedArticleSuggestion,
    getSelectedDispatchProposal,
} from '../../selectors/dispatchProposals/DispatchProposals.selector';
import { LoadingIndicator } from '../common/LoadingIndicator';
import { useLoadingLocationsQuery } from '../loadingLocations/LoadingLocationHooks';
import { Routing } from '../routing/Routes';
import { CalendarPanel } from './CalendarPanel';
import { DispatchProposalCourierOrderValidationErrorsBanner } from './DispatchProposalCourierOrderValidationErrorsBanner';
import { DispatchProposalToolbar } from './DispatchProposalToolbar';
import { MISSING_LOADING_LOCATION_ID } from './DispatchProposalsFilterRow';
import { EditLoadItemAmountDialog } from './EditLoadItemAmountDialog';
import { CourierOrderIntentDialog } from './courierOrderIntent/CourierOrderIntentDialog';
import { DispatchProposalProblemBanners } from './dispatchProposalBanner/DispatchProposalProblemBanners';
import { DispatchProposalPanel } from './dispatchProposalPanel/DispatchProposalPanel';
import { ManualDispatchProposalDraftPanel } from './dispatchProposalPanel/ManualDispatchProposalDraftPanel';
import { PackageTogetherDialog } from './dispatchProposalPanel/PackageTogetherDialog';
import { useDispatchProposalProblemsForDay } from './dispatchProposalProblemHooks';

export const DISPATCH_PROPOSALS_VIEW_TEST_ID = 'dispatch-proposals-view';

const useForwardingToDunsSelectionOnMissingDunsOrInactiveFeatureToggle = () => {
    const dunsNumber = useDunsNumberFromPath();
    const featureToggleResponse = useDiscoveryNextGenFeatures();
    const navigate = useNavigate();

    useEffect(() => {
        const goToDunsSelectionView = () => {
            navigate(Routing.dunsSelection);
        };

        if (!dunsNumber) {
            goToDunsSelectionView();
        }
        const featureToggleEvaluated = !featureToggleResponse.isLoading;
        const featureToggleDisabled = !featureToggleResponse.value;

        if (featureToggleEvaluated && featureToggleDisabled) {
            goToDunsSelectionView();
        }
    }, [dunsNumber, featureToggleResponse, navigate]);
};

const orderByEffectiveTransportOrderStatus = (
    dispatchProposal: DispatchProposal,
    problems: DispatchProposalProblem[],
) => {
    const status = dispatchProposal.transportOrderStatus;
    switch (status) {
        case 'UNPLANNED':
            return 1;
        case 'PLANNED':
            return containsProblemsOfDispatchProposal(problems, dispatchProposal) ? 0 : 2;
        case 'ORDERED':
            return 3;
        default:
            return neverReachedFor(status);
    }
};

const filterAndSortDispatchProposalsForView = (
    activeFilters: DispatchProposalsFilterParams,
    dispatchProposals: DispatchProposal[],
    problems: DispatchProposalProblem[],
) => {
    const filteredDispatchProposals = dispatchProposals.flatMap((dispatchProposal) => {
        if (
            activeFilters.transportConceptList &&
            activeFilters.transportConceptList.length > 0 &&
            !activeFilters.transportConceptList.includes(dispatchProposal.termination.transportConcept)
        ) {
            return [];
        }
        // biome-ignore lint/complexity/noExcessiveCognitiveComplexity: Legacy code
        const filteredItems = dispatchProposal.items.filter((item) => {
            if (
                activeFilters.manufacturingCompanyList.length > 0 &&
                !activeFilters.manufacturingCompanyList.includes(item.buyer.name)
            ) {
                return false;
            } else if (
                activeFilters.plantNumberList.length > 0 &&
                !activeFilters.plantNumberList.includes(item.shipTo.plantCode)
            ) {
                return false;
            } else if (
                activeFilters.unloadingPointList.length > 0 &&
                !activeFilters.unloadingPointList.includes(item.shipTo.placeOfDischarge)
            ) {
                return false;
            } else if (activeFilters.loadingLocationList.length > 0) {
                if (
                    !item.shipFrom.shippingLocationId &&
                    !activeFilters.loadingLocationList.includes(MISSING_LOADING_LOCATION_ID)
                ) {
                    return false;
                } else if (
                    item.shipFrom.shippingLocationId &&
                    !activeFilters.loadingLocationList.includes(item.shipFrom.shippingLocationId)
                ) {
                    return false;
                }
            }
            return true;
        });

        if (filteredItems.length === 0) {
            return [];
        } else {
            return [{ ...dispatchProposal, items: filteredItems }];
        }
    });

    const unsortedDispatchProposals = [...filteredDispatchProposals];
    return unsortedDispatchProposals.sort((a: DispatchProposal, b: DispatchProposal) => {
        if (orderByEffectiveTransportOrderStatus(a, problems) !== orderByEffectiveTransportOrderStatus(b, problems)) {
            return (
                orderByEffectiveTransportOrderStatus(a, problems) - orderByEffectiveTransportOrderStatus(b, problems)
            );
        }
        return stableSortingKey(a) > stableSortingKey(b) ? -1 : 1;
    });
};

const stableSortingKey = (dispatchProposal: DispatchProposal) => {
    if (dispatchProposal.termination.transportConcept !== TransportConcept.KEP) {
        const firstItem = dispatchProposal.items?.[0];
        return hash([
            dispatchProposal.termination.transportConcept,
            dispatchProposal.termination.freightForwarder.dunsNumber,
            dispatchProposal.termination.pickupDate,
            firstItem?.deliveryDate,
            firstItem?.shipTo?.plantCode,
        ]);
    } else {
        return dispatchProposal.id;
    }
};

export const DispatchProposalsView = () => {
    useForwardingToDunsSelectionOnMissingDunsOrInactiveFeatureToggle();
    const dunsNumber = useDunsNumberFromPath() ?? '';
    const dispatchProposalsDateRange = useAppSelector(getOverviewDateRangeISO);
    const activeFilters = useAppSelector(getDispatchProposalsSelectedFilters);
    const dispatch = useAppDispatch();
    const {
        data: dispatchProposals,
        isLoading: isLoadingProposals,
        isError,
    } = useGetDispatchProposalsQuery(
        {
            dunsNumber,
            cutoffDateFrom: dispatchProposalsDateRange.from,
            cutoffDateTo: dispatchProposalsDateRange.to,
        },
        {
            skip: dunsNumber === '',
            pollingInterval: 60000,
        },
    );
    const { data: problems, isLoading: isLoadingProblems } = useGetDispatchProposalProblemsQuery(
        {
            dunsNumber,
            affectedDateFrom: dispatchProposalsDateRange.from,
            affectedDateTo: dispatchProposalsDateRange.to,
        },
        {
            skip: dunsNumber === '',
            pollingInterval: 60000,
        },
    );
    const sortedDispatchProposals = filterAndSortDispatchProposalsForView(
        activeFilters,
        dispatchProposals ?? [],
        problems ?? [],
    );

    // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
    useEffect(() => {
        return () => {
            dispatch(dispatchProposalsSlice.actions.clearTransportPlanningDraft());
            dispatch(dispatchProposalsSlice.actions.stopAddArticleToDispatchProposalWorkflow());
        };
    }, []);

    if (isError) {
        return (
            <ErrorState
                headline={<FormattedMessage id={'webedi.application.error.state.headline'} />}
                message={<FormattedMessage id={'webedi.application.error.state.message'} />}
            />
        );
    }

    return isLoadingProposals || isLoadingProblems || isError ? (
        <DispatchProposalsLoadingView />
    ) : (
        <DispatchProposalsLoadedView dispatchProposals={sortedDispatchProposals} />
    );
};

const DispatchProposalsLoadingView = () => {
    return (
        <div data-testid={DISPATCH_PROPOSALS_VIEW_TEST_ID}>
            <LoadingIndicator />
        </div>
    );
};

export const removeDispatchProposalProblemsReferencingNonExistingDispatchProposal = (
    dispatchProposalProblems: DispatchProposalProblem[],
    dispatchProposals: DispatchProposal[],
): DispatchProposalProblem[] => {
    type DPSpecificProblem =
        | DispatchProposalNotCompletelyPackagedProblem
        | MissingPackagingHeightProblem
        | MissingLoadingLocationProblem
        | DispatchProposalFreightForwarderChangedProblem;

    const [dispatchProposalSpecificProblems, generalProblems] = dispatchProposalProblems.reduce(
        ([dispatchProposalSpecificProblems, generalProblems], problem) => {
            return 'dispatchProposalId' in problem
                ? [[...dispatchProposalSpecificProblems, problem], generalProblems]
                : [dispatchProposalSpecificProblems, [...generalProblems, problem]];
        },
        [[], []] as [DPSpecificProblem[], DispatchProposalProblem[]],
    );

    const filteredSpecificProblems = dispatchProposalSpecificProblems.filter(
        (specificProblem) =>
            dispatchProposals
                .map((dispatchProposal) => dispatchProposal.id)
                .includes(specificProblem.dispatchProposalId) ||
            (specificProblem as DispatchProposalFreightForwarderChangedProblem).newCutoffDate !== undefined,
    );
    return generalProblems.concat(filteredSpecificProblems);
};

const DispatchProposalsLoadedView = (props: { dispatchProposals: DispatchProposal[] }) => {
    const overviewSelectedDate = useAppSelector(getOverviewSelectedDate);
    const manualDrafts = useAppSelector(getManualDispatchProposalDrafts);
    const selectedProposalOrDraft = useSelector(getSelectedDispatchProposal);

    const addArticleSidebarOpen = useSelector(getAddArticleSidebarOpen);
    const courierOrderValidationErrors = useSelector(getOrderValidationErrors);

    const articleSuggestion = useSelector(getSelectedArticleSuggestion);

    const dispatch = useAppDispatch();

    useLoadingLocationsQuery();

    const onClickAddDispatchProposalItem = (src: DispatchProposal | ManualDispatchProposalDraft) => () => {
        if (articleSuggestion === undefined) {
            dispatch(dispatchProposalsSlice.actions.startAddArticleToDispatchProposalWorkflow(src));
        }
    };

    const manualDispatchProposalDraftsToShow = manualDrafts.filter((it) =>
        areDaysEqual(it.cutoffDate, overviewSelectedDate),
    );

    const panelsForManualDrafts = manualDispatchProposalDraftsToShow.map((it) => {
        return (
            <ManualDispatchProposalDraftPanel
                key={it.id}
                draft={it}
                articleSuggestion={it.id === selectedProposalOrDraft?.id ? articleSuggestion : undefined}
                onClickAddArticle={onClickAddDispatchProposalItem(it)}
                isAddingDispatchProposalItem={addArticleSidebarOpen && it.id === selectedProposalOrDraft?.id}
            />
        );
    });

    const dispatchProposalsForSelectedDate: DispatchProposal[] = props.dispatchProposals.filter((it) =>
        areDaysEqual(it.termination.cutoffDate, overviewSelectedDate),
    );
    const selectedDateProblems: DispatchProposalProblem[] =
        useDispatchProposalProblemsForDay(overviewSelectedDate).data;
    const filteredSelectedDateProblems = removeDispatchProposalProblemsReferencingNonExistingDispatchProposal(
        selectedDateProblems,
        dispatchProposalsForSelectedDate,
    );

    const visibleDialog = useAppSelector((state) => state.webEdi.dispatchProposals.visibleDialog);

    const panelsForDispatchProposals = dispatchProposalsForSelectedDate.map((it) => {
        const dispatchProposalCourierOrderValidationsErrors = courierOrderValidationErrors.find(
            (problems) => problems.dispatchProposalId === it.id,
        );
        return (
            <Fragment key={it.id}>
                {dispatchProposalCourierOrderValidationsErrors && (
                    <DispatchProposalCourierOrderValidationErrorsBanner
                        validations={dispatchProposalCourierOrderValidationsErrors.errors}
                    />
                )}

                <DispatchProposalPanel
                    key={it.id}
                    dispatchProposal={it}
                    articleSuggestion={it.id === selectedProposalOrDraft?.id ? articleSuggestion : undefined}
                    addArticleClickHandler={onClickAddDispatchProposalItem(it)}
                    isAddingDispatchProposalItem={addArticleSidebarOpen && it.id === selectedProposalOrDraft?.id}
                    hasCourierValidationErrors={dispatchProposalCourierOrderValidationsErrors !== undefined}
                    problems={filteredSelectedDateProblems}
                />
            </Fragment>
        );
    });

    const panels = panelsForManualDrafts.concat(panelsForDispatchProposals);

    return (
        <div data-testid={DISPATCH_PROPOSALS_VIEW_TEST_ID}>
            <DispatchProposalToolbar />
            <CalendarPanel className={'margin-bottom-25'} dispatchProposals={props.dispatchProposals} />
            <DispatchProposalProblemBanners
                problems={filteredSelectedDateProblems}
                dispatchProposals={dispatchProposalsForSelectedDate}
            />
            <CourierOrderIntentDialog />
            {panels.length > 0 ? panels : <NoDispatchProposalsAlert />}
            <EditLoadItemAmountDialog />
            <PackageTogetherDialog
                showCreateDialog={visibleDialog === Dialog.PACKAGE_TOGETHER}
                close={() => dispatch(hideDialog)}
            />
        </div>
    );
};

const hideDialog = dispatchProposalsSlice.actions.setOverlayDialogue({ dialog: undefined });

const NoDispatchProposalsAlert = () => {
    return (
        <NotFoundState
            headline={<FormattedMessage id={'webedi.dispatchProposals.overview.table.noData.header'} />}
            message={<FormattedMessage id={'webedi.dispatchProposals.overview.table.noData.message'} />}
        />
    );
};
