import Button from '@rio-cloud/rio-uikit/Button';
import ExpanderPanel from '@rio-cloud/rio-uikit/ExpanderPanel';
import Notification from '@rio-cloud/rio-uikit/Notification';
import { MouseEvent, ReactNode } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useAppDispatch, useAppSelector } from '../../../../../configuration/setup/typedReduxHooks';
import { formatWeight, neverReachedFor } from '../../../../../utils';
import { usePostOperationMutation } from '../../../api/dispatchProposal/dispatchProposalApi';
import {
    DeleteDispatchProposalIntentsOperation,
    DispatchProposalOperationType,
} from '../../../api/dispatchProposal/operations.types';
import {
    ArticleSuggestion,
    DispatchProposal,
    DispatchProposalProblem,
    DispatchProposalProblemsCount,
    FreightForwarder,
    PackagedDispatchProposalItem,
    TransportOrderStatus,
    groupDispatchProposalProblemsByLevel,
} from '../../../domain/dispatchProposal.types';
import { TransportConcept } from '../../../domain/meansOfTransport.types';
import { useDunsNumberFromPath } from '../../../hooks/Routing.hooks';
import { dispatchProposalsSlice } from '../../../reducers/dispatchProposal/DispatchProposalsReducer';
import { getSelectedDispatchProposalItems } from '../../../selectors/dispatchProposals/DispatchProposals.selector';
import { FormattedDateOrDateTime } from '../../common/i18n/FormattedDateOrDateTime';
import { OrderCourierButton } from '../courierOrderIntent/OrderCourierButton';
import { useCanTransportOrderIntentsBeDeleted, useDispatchProposalCompatibility } from '../dispatchProposalHooks';
import { DispatchProposalItemsTable } from './DispatchProposalItemsTable';
import { PanelPlantItem, PanelTransportConceptInputItem, StatusIndicator } from './panel.common';

export const DISPATCH_PROPOSAL_PANEL_TEST_ID = 'DISPATCH_PROPOSAL_PANEL_TEST_ID';

export const DispatchProposalPanel = (props: {
    dispatchProposal: DispatchProposal;
    articleSuggestion?: ArticleSuggestion;
    addArticleClickHandler: () => void;
    isAddingDispatchProposalItem: boolean;
    hasCourierValidationErrors?: boolean;
    problems: DispatchProposalProblem[];
}) => {
    const {
        dispatchProposal,
        addArticleClickHandler,
        isAddingDispatchProposalItem,
        hasCourierValidationErrors,
        problems,
    } = props;
    const addDispatchProposalItemButtonColor = `${isAddingDispatchProposalItem ? 'btn-primary' : 'btn-default'}`;

    const problemsCount = groupDispatchProposalProblemsByLevel(problems, dispatchProposal.id);
    if (dispatchProposal.termination.transportConcept === TransportConcept.KEP && !!hasCourierValidationErrors) {
        problemsCount.error++;
    }

    const headerClassName = getHeaderClassName(dispatchProposal, problemsCount);
    return (
        <ExpanderPanel
            data-testid={DISPATCH_PROPOSAL_PANEL_TEST_ID}
            title={<DispatchProposalPanelHeader dispatchProposal={dispatchProposal} problemsCount={problemsCount} />}
            open
            bsStyle={'default'}
            className={'margin-bottom-25'}
            headerClassName={`padding-0 ${headerClassName}`}
            titleClassName={'width-100pct margin-0 overflow-hidden'}
            bodyClassName={'padding-0'}
            iconClassName={'position-absolute top-0 right-15 height-100pct display-flex align-items-center'}
        >
            <DispatchProposalItemsTable
                key={dispatchProposal.id}
                dispatchProposal={dispatchProposal}
                articleSuggestion={props.articleSuggestion}
                problems={problems}
            />
            <button
                type={'button'}
                className={`btn ${addDispatchProposalItemButtonColor} center-block margin-y-15`}
                onClick={addArticleClickHandler}
                disabled={!dispatchProposal.operations.addArticle.allowed}
            >
                <span className={'rioglyph rioglyph-plus-light'} />
                <FormattedMessage id={'webedi.deliveryNote.addArticle'} />
            </button>
        </ExpanderPanel>
    );
};

const getHeaderClassName = (dispatchProposal: DispatchProposal, problemsCount: DispatchProposalProblemsCount) => {
    if (problemsCount.error > 0) {
        return 'callout callout-danger border-none';
    }
    if (problemsCount.info > 0) {
        return 'callout callout-info border-none';
    }

    if (dispatchProposal.transportOrderStatus === 'ORDERED') {
        return 'callout callout-success border-none';
    } else if (dispatchProposal.transportOrderStatus === 'PLANNED') {
        return 'callout callout-default border-none';
    }
    return '';
};

const DispatchProposalPanelHeader = (props: {
    dispatchProposal: DispatchProposal;
    problemsCount: DispatchProposalProblemsCount;
}) => {
    const { dispatchProposal, problemsCount } = props;
    const { termination } = dispatchProposal;
    const isDeleteIntentsAllowed = useCanTransportOrderIntentsBeDeleted(dispatchProposal);
    const isKep = props.dispatchProposal.termination.transportConcept === TransportConcept.KEP;
    const colSpanForGeneralInfo = isKep ? 3 : 4;
    return (
        <table className={'table table-layout-fixed width-100pct'}>
            <thead>
                <tr>
                    <td className={'width-300-lg width-350-xl'} colSpan={2}>
                        <PanelTitleItem dispatchProposal={dispatchProposal} problemsCount={problemsCount} />
                    </td>
                    {isKep ? <td key={'kepPlaceholder'} /> : <></>}
                    <td colSpan={colSpanForGeneralInfo}>
                        <div
                            className={
                                'display-flex flex-wrap flex-nowrap-xl align-items-center justify-content-start gap-5'
                            }
                        >
                            {termination.transportConcept !== TransportConcept.KEP && (
                                <PanelPickUpDateInputItem pickupDate={termination.pickupDate} />
                            )}
                            <PanelTransportConceptInputItem transportConcept={termination.transportConcept} />
                            {termination.transportConcept !== TransportConcept.KEP && (
                                <PanelFreightForwarderItem freightForwarder={termination.freightForwarder} />
                            )}
                            <PanelPlantItem plantCodes={dispatchProposal.items.map((it) => it.shipTo.plantCode)} />
                        </div>
                    </td>
                    <td>
                        <TotalWeight dispatchProposal={dispatchProposal} />
                    </td>
                    <td>
                        <NumberOfPackages dispatchProposal={dispatchProposal} />
                    </td>
                    <td key={'generalPlaceholder'} />
                    <td className='width-250' colSpan={2}>
                        {isDeleteIntentsAllowed ? (
                            <DeleteIntentsButton dispatchProposal={dispatchProposal} />
                        ) : (
                            <OrderButton dispatchProposal={dispatchProposal} isKep={isKep} />
                        )}
                    </td>
                </tr>
            </thead>
        </table>
    );
};

const OrderButton = (props: { dispatchProposal: DispatchProposal; isKep: boolean }) => {
    if (props.isKep) {
        return <KepOrderButton dispatchProposal={props.dispatchProposal} />;
    } else {
        return <NonKepOrderButton dispatchProposal={props.dispatchProposal} />;
    }
};

const KepOrderButton = (props: { dispatchProposal: DispatchProposal }) => {
    const areDispatchProposalItemsSelected = useAppSelector(getSelectedDispatchProposalItems).length > 0;
    const isDispatchProposalUnplanned = props.dispatchProposal.transportOrderStatus === 'UNPLANNED';

    return (
        <OrderCourierButton
            dispatchProposalId={props.dispatchProposal.id}
            enabled={!areDispatchProposalItemsSelected && isDispatchProposalUnplanned}
        />
    );
};

const NonKepOrderButton = (props: { dispatchProposal: DispatchProposal }) => {
    const dispatch = useAppDispatch();
    const orderButtonEnabled = useDispatchProposalCompatibility(props.dispatchProposal);
    const alreadySelectedItemIds = useAppSelector(getSelectedDispatchProposalItems).map((it) => it.identifier);
    const noItemOfThisProposalAlreadySelected = !props.dispatchProposal.items.some((item) =>
        alreadySelectedItemIds.includes(item.identifier),
    );
    const onClick = () => {
        if (noItemOfThisProposalAlreadySelected) {
            props.dispatchProposal.items.forEach((item) => {
                const payload = { dispatchProposal: props.dispatchProposal, item };
                dispatch(dispatchProposalsSlice.actions.addDispatchProposalItemToTransportPlanningDraft(payload));
            });
        }
        dispatch(dispatchProposalsSlice.actions.setPlanDispatchProposalsSidebarOpen(true));
    };

    return <PanelOrderButton enabled={orderButtonEnabled} onClick={onClick} />;
};

const getLabelColorClassName = (
    transportOrderStatus: TransportOrderStatus,
    problemsCount: DispatchProposalProblemsCount,
) => {
    if (problemsCount.error > 0) {
        return 'text-color-danger';
    }

    if (problemsCount.info > 0) {
        return 'text-color-info';
    }
    switch (transportOrderStatus) {
        case 'PLANNED':
            return 'text-color-dark';
        case 'ORDERED':
            return 'text-color-success';
        default:
            return '';
    }
};

const PanelTitleItem = (props: {
    dispatchProposal: DispatchProposal;
    problemsCount: DispatchProposalProblemsCount;
}) => {
    const labelColor = getLabelColorClassName(props.dispatchProposal.transportOrderStatus, props.problemsCount);
    const getLabelKey = (dispatchProposal: DispatchProposal) => {
        const status = dispatchProposal.transportOrderStatus;
        switch (status) {
            case 'UNPLANNED':
                return 'unplanned';
            case 'PLANNED':
                return props.problemsCount.error > 0 || props.problemsCount.warning > 0 ? 'cannotBeOrdered' : 'planned';
            case 'ORDERED':
                return 'ordered';
            default:
                return neverReachedFor(status);
        }
    };
    const labelId = `webedi.dispatchProposals.overview.expander.header.title.${getLabelKey(props.dispatchProposal)}`;
    const touchedLabel = (
        <label className={'margin-0 text-lowercase text-color-gray'}>
            &nbsp;
            <FormattedMessage id={'webedi.dispatchProposals.overview.expander.header.title.touched'} />
        </label>
    );

    return (
        <div
            className={'display-flex align-items-center flex-row gap-5 margin-right-15'}
            data-testid={'DISPATCH_PROPOSAL_PANEL_TITLE_ITEM_TEST_ID'}
        >
            <div className={`ellipsis-1 text-medium text-uppercase expander-header-text ${labelColor}`}>
                <span>
                    <StatusIndicator
                        status={props.dispatchProposal.transportOrderStatus}
                        problemsCount={props.problemsCount}
                    />
                    <FormattedMessage id={labelId} />
                    {props.dispatchProposal.transportOrderStatus === 'UNPLANNED' && !props.dispatchProposal.untouched
                        ? touchedLabel
                        : null}
                </span>
            </div>
        </div>
    );
};
const PanelPickUpDateInputItem = (props: { pickupDate: string }) => {
    return (
        <div className={'display-flex flex-nowrap'}>
            <span className={'ellipsis-1 text-color-gray'}>
                <FormattedMessage id='webedi.dispatchProposals.overview.expander.header.pickUpDate' />:
            </span>
            <span className={'margin-left-5 margin-right-15'}>
                <FormattedDateOrDateTime date={props.pickupDate} />
            </span>
        </div>
    );
};

const PanelFreightForwarderItem = (props: { freightForwarder: FreightForwarder }) => {
    return (
        <div className={'ellipsis-1'}>
            <span className={'ellipsis-1 text-color-gray'}>
                <FormattedMessage id='webedi.dispatchProposals.overview.expander.header.freightForwarder' />:
            </span>
            <span className={'rioglyph rioglyph-truck text-size-18 margin-left-5 margin-right-5'} />
            <span className={'margin-right-15'}>{props.freightForwarder.name}</span>
        </div>
    );
};
const TotalWeight = (props: { dispatchProposal: DispatchProposal }) => {
    const totalWeight = props.dispatchProposal.items
        .filter((it) => it.type === 'PACKAGED_DISPATCH_PROPOSAL_ITEM')
        .map((it) => it as PackagedDispatchProposalItem)
        .reduce((acc, item) => acc + item.grossWeightInKg, 0);
    const intl = useIntl();
    const formattedWeight = formatWeight(intl.locale, totalWeight);
    return (
        <div className='ellipsis-1'>
            <span className='rioglyph rioglyph-weight text-size-18 margin-right-3' />
            <span className='margin-right-5 ellipsis-1 word-break'>{formattedWeight}</span>
        </div>
    );
};

const NumberOfPackages = (props: { dispatchProposal: DispatchProposal }) => {
    const totalNumberOfPackages = props.dispatchProposal.items
        .filter((it) => it.type === 'PACKAGED_DISPATCH_PROPOSAL_ITEM')
        .map((it) => it as PackagedDispatchProposalItem)
        .reduce((acc, item) => acc + item.outerPackaging.count, 0);
    return (
        <div className='ellipsis-1'>
            <span className='rioglyph rioglyph-parcel text-size-18 margin-right-3' />
            <span className='ellipsis-1 word-break'>
                <FormattedMessage
                    id={'webedi.dispatchProposals.planDispatchProposal.body.table.packagingSummary'}
                    values={{
                        amount: totalNumberOfPackages,
                        type: undefined,
                        strong: (chunks: ReactNode) => <strong className={'padding-left-3'}>{chunks}</strong>,
                    }}
                />
            </span>
        </div>
    );
};

const PanelOrderButton = (props: { enabled: boolean; onClick: () => void }) => {
    return (
        <div
            className={
                'display-flex justify-content-end align-items-center max-width-400 margin-right-20 padding-right-10'
            }
        >
            <Button
                disabled={!props.enabled}
                onClick={(e: MouseEvent<HTMLElement>) => {
                    e.stopPropagation(); // to prevent the Expander from collapsing
                    props.onClick();
                }}
                bsStyle={'primary'}
            >
                <span className={'rioglyph rioglyph-truck'} />
                <FormattedMessage id='webedi.dispatchProposals.overview.expander.header.order' />
            </Button>
        </div>
    );
};

const DeleteIntentsButton = (props: { dispatchProposal: DispatchProposal }) => {
    const [deleteTransportOrderIntents] = usePostOperationMutation();
    const dunsNumber = useDunsNumberFromPath()!;
    const operation: DeleteDispatchProposalIntentsOperation = {
        type: DispatchProposalOperationType.DELETE_TRANSPORT_ORDER_INTENTS,
        dunsNumber,
        dispatchProposalId: props.dispatchProposal.id,
    };
    const onClick = () => {
        deleteTransportOrderIntents(operation)
            .unwrap()
            .then(() => {
                Notification.success(<FormattedMessage id={'webedi.label.success.deleteTransportOrderIntents'} />);
            })
            .catch((error) => {
                const errorCode = error?.data?.errorCode;
                Notification.error(
                    <FormattedMessage
                        id={errorCode ? `webedi.error.${error?.data?.errorCode}` : 'webedi.error.general'}
                    />,
                );
            });
    };
    return (
        <div
            className={
                'display-flex justify-content-end align-items-center max-width-400 margin-right-20 padding-right-10'
            }
        >
            <Button
                onClick={(e: MouseEvent<HTMLElement>) => {
                    e.stopPropagation(); // to prevent the Expander from collapsing
                    onClick();
                }}
            >
                <span className={'rioglyph rioglyph-revert'} />
                <FormattedMessage id='webedi.dispatchProposals.overview.expander.header.discardOrder' />
            </Button>
        </div>
    );
};
