import Checkbox from '@rio-cloud/rio-uikit/Checkbox';
import { BaseSyntheticEvent, ReactNode, useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useAppDispatch, useAppSelector } from '../../../../../../../configuration/setup/typedReduxHooks';
import { updateSelectedGroupedHandlingUnits } from '../../../../../actions/shipments/Packaging.actions';
import { GroupedHandlingUnits } from '../../../../../reducers/shipments/packaging.types';
import { getGroupedHandlingUnitsForSelectedShipment } from '../../../../../selectors/packaging/Packaging.selector';
import { GroupedPackagingHandlingUnits } from '../GroupedPackagingHandlingUnits';

export const PACKAGING_GROUPING_SELECTION_TEST_ID = 'PACKAGING_GROUPING_SELECTION_TEST_ID';
export const PACKAGING_GROUPING_SELECTION_LIST_TEST_ID = 'PACKAGING_GROUPING_SELECTION_LIST_TEST_ID';
export const PACKAGING_GROUPING_SELECTION_SELECT_ALL_CHECKBOX_TEST_ID =
    'PACKAGING_GROUPING_SELECTION_SELECT_ALL_CHECKBOX_TEST_ID';

const unionByHash = <T extends { hash: string }>(arr1: T[], arr2: T[]): T[] => {
    const result = [...arr1];
    arr2.map((value2) => result.some((value1) => value1.hash === value2.hash) || result.push(value2));
    return result;
};

const differenceByHash = <T extends { hash: string }>(arr1: T[], arr2: T[]): T[] => {
    return arr1.filter((value1) => !arr2.some((value2) => value1.hash === value2.hash));
};

export const PackagingGroupingSelection = () => {
    const dispatch = useAppDispatch();
    const groupedHandlingUnits = useAppSelector(getGroupedHandlingUnitsForSelectedShipment);
    const [selectedGroupedHandlingUnits, setSelectedGroupedHandlingUnits] = useState<GroupedHandlingUnits[]>([]);

    const handleSelection = (event: BaseSyntheticEvent, changedHandlingUnitGroups: GroupedHandlingUnits[]) => {
        if (event.target.checked) {
            setSelectedGroupedHandlingUnits(unionByHash(selectedGroupedHandlingUnits, changedHandlingUnitGroups));
        } else {
            setSelectedGroupedHandlingUnits(differenceByHash(selectedGroupedHandlingUnits, changedHandlingUnitGroups));
        }
    };

    useEffect(() => {
        dispatch(updateSelectedGroupedHandlingUnits(selectedGroupedHandlingUnits));
    }, [dispatch, selectedGroupedHandlingUnits]);

    return (
        <div
            data-testid={PACKAGING_GROUPING_SELECTION_TEST_ID}
            className={
                'display-flex flex-column margin--20 padding-top-5pct padding-bottom-5pct ' +
                'margin-left-20pct margin-right-20pct min-height-500'
            }
        >
            <div className={'display-flex flex-column'}>
                <div className={'text-size-18 text-center margin-bottom-25'}>
                    <FormattedMessage id={'webedi.packaging.whatToPackage'} />
                </div>
                <div className={'counter color-highlight'}>
                    <div data-count={'1'}>
                        <span>
                            <FormattedMessage
                                id={'webedi.packaging.regular.groupingSelection.explanation'}
                                values={{
                                    b: (chunks: ReactNode) => <b>{chunks}</b>,
                                }}
                            />
                            {':'}
                        </span>
                    </div>
                </div>
            </div>
            <div className={'display-flex flex-column flex-1-1-0 justify-content-center width-100pct'}>
                <div
                    className={
                        'border-style-solid border-bottom-only border-color-light border-width-1 padding-15 margin-bottom-15'
                    }
                    data-testid={PACKAGING_GROUPING_SELECTION_SELECT_ALL_CHECKBOX_TEST_ID}
                >
                    <Checkbox
                        checked={selectedGroupedHandlingUnits.length === groupedHandlingUnits.length}
                        onClick={(event: BaseSyntheticEvent) => handleSelection(event, groupedHandlingUnits)}
                        label={
                            <span className={'text-medium'}>
                                <FormattedMessage id={'webedi.label.selectAll'} />
                                &nbsp; ({groupedHandlingUnits.length})
                            </span>
                        }
                    />
                </div>
                <div data-testid={PACKAGING_GROUPING_SELECTION_LIST_TEST_ID}>
                    <GroupedPackagingHandlingUnits
                        groupedHandlingUnits={groupedHandlingUnits}
                        selectable={true}
                        selectedGroupedHandlingUnits={selectedGroupedHandlingUnits}
                        handleSelection={handleSelection}
                    />
                </div>
            </div>
        </div>
    );
};
