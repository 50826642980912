import Dialog from '@rio-cloud/rio-uikit/Dialog';
import { FormattedMessage } from 'react-intl';
import { useAppDispatch, useAppSelector } from '../../../../../../configuration/setup/typedReduxHooks';
import { neverReachedFor } from '../../../../../../utils';
import { packagingSlice } from '../../../../reducers/shipments/Packaging.reducer';
import { PackagingStep, PackagingType } from '../../../../reducers/shipments/packaging.types';
import { getArticleToBePackaged, getPackagingWizardInfo } from '../../../../selectors/packaging/Packaging.selector';
import { Footer } from './Footer';
import { InnerPackaging } from './InnerPackaging/InnerPackaging';
import { OuterPackaging } from './Packaging/OuterPackaging';

export const PackagingDialog = () => {
    const dispatch = useAppDispatch();
    const packagingWizardInfo = useAppSelector(getPackagingWizardInfo);
    const step = packagingWizardInfo?.step;
    const articleToBePackaged = useAppSelector(getArticleToBePackaged);

    if (step === undefined) {
        return null;
    }

    const hideDialog = () => {
        // due to the unknown behaviour in the dialog the "esc" action leads to crashes
        // because of that, the updateArticleToPackage is always dispatched, even for the regular packaging
        dispatch(packagingSlice.actions.updateArticleToPackage(undefined));
        dispatch(packagingSlice.actions.hidePackagingDialog());
        dispatch(packagingSlice.actions.clearUpdatePackagingConfiguration());
    };

    const renderBody = (packagingType: PackagingType) => {
        switch (packagingType) {
            case PackagingType.INNER:
                return (
                    <div className={'padding-20 overflow-y-scroll max-height-75vh'}>
                        <InnerPackaging step={step} />
                    </div>
                );
            case PackagingType.REGULAR:
                return (
                    <div className={'padding-20 overflow-y-scroll max-height-75vh'}>
                        <OuterPackaging step={step} />
                    </div>
                );
            default:
                return neverReachedFor(packagingType);
        }
    };

    const renderTitle = (packagingType: PackagingType) => {
        switch (packagingType) {
            case PackagingType.INNER:
                return (
                    <FormattedMessage
                        id={'webedi.packaging.inner.dialog.header'}
                        values={{ articleNumber: articleToBePackaged?.articleNumberBuyer }}
                    />
                );
            case PackagingType.REGULAR:
                return <FormattedMessage id={'webedi.packaging.createPackaging'} />;
            default:
                throw Error(`Should never be called. Was called with ${packagingType}`);
        }
    };

    return (
        <Dialog
            show={!!packagingWizardInfo}
            title={packagingWizardInfo && renderTitle(packagingWizardInfo.type)}
            body={packagingWizardInfo && renderBody(packagingWizardInfo.type)}
            footer={
                packagingWizardInfo && (
                    <Footer finalStep={step === PackagingStep.AUXILIARY_CONFIGURATION} onCancel={hideDialog} />
                )
            }
            showCloseButton={true}
            bodyClassName={'padding-0'}
            onClose={hideDialog}
            bsSize={Dialog.SIZE_LG}
        />
    );
};
