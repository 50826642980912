import Notification from '@rio-cloud/rio-uikit/Notification';
import { Dispatch } from '../../../../configuration/setup/store';
import { ProblemError } from '../../api/apiUtils';
import { uiSlice } from '../../reducers/ui/UI.reducer';
import { nonVanishingFormattedErrorNotification } from '../NotificationUtils';

export const handleError = (error: Error) => async (dispatch: Dispatch) => {
    if (error instanceof ProblemError && error.errorCode) {
        dispatch(uiSlice.actions.setError(error.errorCode));
    } else if (error instanceof ProblemError && error.statusCode === 500) {
        nonVanishingFormattedErrorNotification({ messageId: 'webedi.error.unknown', errorCode: 'UNKNOWN ERROR' });
    } else {
        Notification.error(error.message);
    }
};
