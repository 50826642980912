import SimpleButtonDropdown from '@rio-cloud/rio-uikit/SimpleButtonDropdown';
import { ReactElement, useEffect } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useAppDispatch, useAppSelector } from '../../../../../../configuration/setup/typedReduxHooks';
import {
    fetchAndStorePackagingConfigurationForTemplates,
    fetchAndStorePackagingTemplatesForShipment,
} from '../../../../actions/shipments/Packaging.actions';
import { useDunsNumberFromPath } from '../../../../hooks/Routing.hooks';
import { PackagingTemplateType } from '../../../../reducers/deliverySchedules/types';
import { packagingSlice } from '../../../../reducers/shipments/Packaging.reducer';
import {
    PackagingTemplateForShipment,
    PackagingTemplatesDictionary,
    PackagingType,
} from '../../../../reducers/shipments/packaging.types';
import {
    getPackagingTemplates,
    getUnpackagedArticlesForSelectedShipment,
} from '../../../../selectors/packaging/Packaging.selector';
import { getSelectedShipmentId } from '../../../../selectors/shipments/Shipments.selector';
import { MeasurementUnitCodeDisplay } from '../../../common/MeasurementUnitCodeDisplay';
import { Tooltip } from '../../../common/Tooltip';
import { UnpackagedArticle } from './types';

export const APPLY_PACKAGING_TEMPLATE_BUTTON_TEST_ID = 'APPLY_PACKAGING_TEMPLATE_BUTTON_TEST_ID';
export const PACKAGE_ARTICLES_BUTTON_TEST_ID = 'PACKAGE_ARTICLES_BUTTON_TEST_ID';

const findApplicablePackagingTemplate = (
    article: UnpackagedArticle,
    packagingTemplates: PackagingTemplatesDictionary,
): PackagingTemplateForShipment[] => packagingTemplates[article.articleNumberBuyer];

export const UnpackagedArticleList = () => {
    const dispatch = useAppDispatch();

    const dunsNumber = useDunsNumberFromPath();
    const shipmentId = useAppSelector(getSelectedShipmentId);
    const articles = useAppSelector(getUnpackagedArticlesForSelectedShipment);
    const packagingTemplates = useAppSelector(getPackagingTemplates);
    const intl = useIntl();

    useEffect(() => {
        dispatch(fetchAndStorePackagingTemplatesForShipment(dunsNumber!, shipmentId!));
    }, [dispatch, dunsNumber, shipmentId]);

    if (dunsNumber === undefined || shipmentId === undefined) {
        return null;
    }

    const renderArticle = (article: UnpackagedArticle, index: number) => {
        const applicablePackagingTemplates = findApplicablePackagingTemplate(article, packagingTemplates);
        const packageArticlesClickHandler = () => {
            dispatch(packagingSlice.actions.updateArticleToPackage(article.loadItemId));
            dispatch(packagingSlice.actions.showDialog({ type: PackagingType.INNER }));
        };

        const measurementUnitCodeTranslation = <MeasurementUnitCodeDisplay unit={article.measurementUnitCode} />;

        const applyTemplateWrapperClickEvent = (e: { target: { value: string } }) => {
            const selectedPackagingTemplate = e.target.value;
            fetchTemplateConfiguration(selectedPackagingTemplate);
        };

        const applyTemplateWrapperSelectEvent = (value: string | undefined) => {
            value && fetchTemplateConfiguration(value);
        };

        const fetchTemplateConfiguration = (templateId: string) => {
            dispatch(
                fetchAndStorePackagingConfigurationForTemplates(dunsNumber, shipmentId, [
                    {
                        identifier: {
                            deliveryNoteNumber: article.deliveryNoteNumber,
                            deliveryNotePosition: article.position,
                        },
                        quantity: article.unpackagedQuantityValue,
                        templateId,
                    },
                ]),
            );
        };

        const renderPackagingTemplateButtonSwitch = (): ReactElement | null => {
            if (applicablePackagingTemplates?.length > 1) {
                const lisonTemplate = applicablePackagingTemplates.find((template) => {
                    return template.type === PackagingTemplateType.LISON;
                });
                const customTemplates = applicablePackagingTemplates.filter((template) => {
                    return template.type !== PackagingTemplateType.LISON;
                });
                const allTemplates = [lisonTemplate, ...customTemplates].filter(
                    (template) => template,
                ) as PackagingTemplateForShipment[];
                const getTemplateLabel = (template: PackagingTemplateForShipment): string => {
                    if (template.type === PackagingTemplateType.LISON) {
                        return `${intl.formatMessage({ id: 'webedi.shipment.applyLisonPackagingTemplate' })}`;
                    } else {
                        return `${intl.formatMessage({ id: 'webedi.shipment.applyCustomPackagingTemplate' })}: ${template.name}`;
                    }
                };

                return (
                    <div className={'row margin-top-15 margin-bottom-15 '}>
                        <SimpleButtonDropdown
                            className={'width-100pct'}
                            bsStyle={'primary'}
                            toggleClassName={'width-100pct'}
                            title={
                                <>
                                    <FormattedMessage id={'webedi.shipment.selectPackagingTemplate'} />
                                    <span className={'caret'} />
                                </>
                            }
                            items={allTemplates.map((template) => ({
                                value: getTemplateLabel(template),
                                onSelect: () => applyTemplateWrapperSelectEvent(template.id),
                            }))}
                        />
                    </div>
                );
            } else if (applicablePackagingTemplates?.length === 1) {
                const isLISON =
                    applicablePackagingTemplates &&
                    applicablePackagingTemplates[0].type === PackagingTemplateType.LISON;
                return (
                    <div className={'row margin-top-15 margin-bottom-15'}>
                        <button
                            type={'button'}
                            className={'btn btn-primary btn-block margin-bottom-15'}
                            data-testid={APPLY_PACKAGING_TEMPLATE_BUTTON_TEST_ID}
                            value={applicablePackagingTemplates?.[0]?.id}
                            onClick={applyTemplateWrapperClickEvent}
                        >
                            <FormattedMessage id={'webedi.shipment.applyPackagingTemplate'} />
                            {isLISON ? ` (${applicablePackagingTemplates[0].type})` : null}
                        </button>
                    </div>
                );
            } else if (applicablePackagingTemplates === undefined || applicablePackagingTemplates.length === 0) {
                return (
                    <div className={'row margin-top-15 margin-bottom-15'}>
                        <Tooltip
                            text={<FormattedMessage id={'webedi.shipment.applyPackagingTemplate.disabled.tooltip'} />}
                            placement={'bottom'}
                        >
                            <span>
                                <button
                                    type={'button'}
                                    className={'btn btn-primary btn-block margin-bottom-15'}
                                    data-testid={APPLY_PACKAGING_TEMPLATE_BUTTON_TEST_ID}
                                    disabled={true}
                                >
                                    <FormattedMessage id={'webedi.shipment.applyPackagingTemplate'} />
                                </button>
                            </span>
                        </Tooltip>
                    </div>
                );
            }
            return null;
        };

        return (
            <div
                className={'border-style-solid border-width-1 border-color-gray padding-20 margin-bottom-20'}
                key={index}
                data-testid={UNPACKAGED_ARTICLE_LIST_ENTRY_TEST_ID}
            >
                <div className={'row'}>
                    <div className={'col-xs-1'}>
                        <span className={'rioglyph rioglyph-cards-grid text-size-20'} />
                    </div>
                    <div className={'col-xs-7 text-size-16'}>{article.articleNumberBuyer}</div>
                    <div className={'col-xs-4 text-right'}>
                        <span className={'label label-primary label-filled'}>
                            <FormattedMessage id={'webedi.handlingUnit.type.article'} />
                        </span>
                    </div>
                </div>
                <div className={'row'}>
                    <div className={'col-xs-1'} />
                    <div className={'col-xs-11 text-size-12 text-color-dark'}>
                        <div>
                            <FormattedMessage id={'webedi.deliveryNoteNr.short'} />: {article.deliveryNoteNumber}
                        </div>
                        <div>
                            <FormattedMessage id={'webedi.position'} /> <span>{article.position}</span>
                        </div>
                    </div>
                </div>
                <div className={'row text-size-14 margin-top-15 margin-bottom-15'}>
                    <div className={'col-xs-1'} />
                    <div className={'col-xs-4 border-right-only border-style-solid border-width-1 border-color-gray'}>
                        <div>
                            <FormattedMessage id={'webedi.article.unpackaged'} />
                        </div>
                        <div>
                            {article.unpackagedQuantityValue} {measurementUnitCodeTranslation}
                        </div>
                    </div>
                    <div className={'col-xs-4 text-color-gray'}>
                        <div>
                            <FormattedMessage id={'webedi.article.packaged'} />
                        </div>
                        <div>
                            {article.packagedQuantityValue} {measurementUnitCodeTranslation}
                        </div>
                    </div>
                    <div className={'col-xs-3'} />
                </div>
                <div className={'row margin-top-15 margin-bottom-15'}>
                    <button
                        type={'button'}
                        className={'btn btn-default btn-block'}
                        data-testid={PACKAGE_ARTICLES_BUTTON_TEST_ID}
                        onClick={packageArticlesClickHandler}
                    >
                        <FormattedMessage
                            id={'webedi.article.packageNArticles'}
                            values={{
                                number: article.unpackagedQuantityValue,
                                measurementUnitCode: measurementUnitCodeTranslation,
                            }}
                        />
                    </button>
                </div>
                {renderPackagingTemplateButtonSwitch()}
            </div>
        );
    };

    return (
        <div className={'panel panel-default margin-bottom-20'} data-testid={UNPACKAGED_ARTICLE_LIST_CONTAINER_TEST_ID}>
            <div className={'panel-body padding-right-25 padding-left-25'}>
                <div className={'display-flex align-items-center text-color-dark margin-bottom-15'}>
                    <span className={'rioglyph rioglyph-empty text-size-20 padding-right-10'} />
                    <FormattedMessage id={'webedi.article.unpackagedArticles'} tagName='span' />
                </div>
                {articles.length > 0 ? (
                    articles.map((unpackagedArticle: UnpackagedArticle, index: number) =>
                        renderArticle(unpackagedArticle, index),
                    )
                ) : (
                    <div
                        className={
                            'display-flex align-items-center justify-content-center min-height-250 text-color-gray text-italic text-size-14'
                        }
                    >
                        <FormattedMessage id={'webedi.article.noUnpackagedArticles'} />
                    </div>
                )}
            </div>
        </div>
    );
};

export const UNPACKAGED_ARTICLE_LIST_ENTRY_TEST_ID = 'UNPACKAGED_ARTICLE_LIST_ENTRY_TEST_ID';
export const UNPACKAGED_ARTICLE_LIST_CONTAINER_TEST_ID = 'UNPACKAGED_ARTICLE_LIST_CONTAINER_TEST_ID';
