import { TagProps } from '@rio-cloud/rio-uikit/Tag';
import Tag from '@rio-cloud/rio-uikit/Tag';
import TagList from '@rio-cloud/rio-uikit/TagList';
import { FormattedMessage, useIntl } from 'react-intl';
import { State } from '../../../../configuration/setup/store';
import { useAppDispatch, useAppSelector } from '../../../../configuration/setup/typedReduxHooks';
import { getShipmentsAction } from '../../actions/shipments/Shipments.actions';
import { ShipmentsQueryParams } from '../../reducers/shipments/types';
import { getShipmentsQuery } from '../../selectors/shipments/Shipments.selector';

const SELECTED_FILTERS_TAG_LIST = 'SELECTED_FILTERS_TAG_LIST';

interface ShipmentsSelectedFiltersProps {
    exported: boolean;
}

export const ShipmentsSelectedFilters = (props: ShipmentsSelectedFiltersProps) => {
    const { exported } = props;

    const query = useAppSelector((state: State) => getShipmentsQuery(state, exported));
    const dispatch = useAppDispatch();
    const intl = useIntl();
    const dispatchQueryChangeFactory = (changes: Partial<ShipmentsQueryParams>) => () => {
        dispatch(getShipmentsAction(query.dunsNumber!, exported, { offset: 0, ...changes }));
    };

    const tags = [
        {
            messageKeyId: 'webedi.shipment.exportDateFrom',
            value: query.params.exportedAtFrom && intl.formatDate(query.params.exportedAtFrom),
            onDelete: dispatchQueryChangeFactory({ exportedAtFrom: undefined }),
        },
        {
            messageKeyId: query.params.exportedAtFrom
                ? 'webedi.shipment.exportDateToShort'
                : 'webedi.shipment.exportDateTo',
            value: query.params.exportedAtTo && intl.formatDate(query.params.exportedAtTo),
            onDelete: dispatchQueryChangeFactory({ exportedAtTo: undefined }),
        },
        {
            messageKeyId: 'webedi.plantIdentifier',
            value: query.params.plantNumber,
            onDelete: dispatchQueryChangeFactory({ plantNumber: undefined }),
        },
        {
            messageKeyId: 'webedi.common.placeOfDischarge',
            value: query.params.placeOfDischarge,
            onDelete: dispatchQueryChangeFactory({ placeOfDischarge: undefined }),
        },
    ].filter((kv): kv is FilterTagProps => !!kv.value);

    return (
        <>
            <TagList data-testid={SELECTED_FILTERS_TAG_LIST}>
                {tags.map((tag) => (
                    <FilterTag
                        key={tag.messageKeyId}
                        messageKeyId={tag.messageKeyId}
                        value={tag.value}
                        onDelete={tag.onDelete}
                    />
                ))}
            </TagList>
        </>
    );
};

interface FilterTagProps {
    messageKeyId: string;
    value: string;
    onDelete: () => void;
}

const FilterTag = (props: FilterTagProps) => {
    const { onDelete, messageKeyId, value } = props;

    const tagProperties: TagProps = {
        deletable: true,
        onClick: onDelete,
        size: 'small',
    };
    return (
        <Tag {...tagProperties}>
            <FormattedMessage id={messageKeyId} />: {value}
        </Tag>
    );
};
