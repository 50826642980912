import ClearableInput from '@rio-cloud/rio-uikit/ClearableInput';
import NotFoundState from '@rio-cloud/rio-uikit/NotFoundState';
import { ReactNode, useState } from 'react';
import { FormattedMessage } from 'react-intl';

interface TextSearchFilterProps<T> {
    title: ReactNode;
    values: T[];
    getSearchableStrings: (value: T) => (string | undefined)[];
    renderFiltered: (values: T[]) => ReactNode;
}

export const TextSearchFilter = <T,>(props: TextSearchFilterProps<T>) => {
    const [searchValue, setSearchValue] = useState<string>('');
    const caseInsensitiveSearch = (value: string) => value.toLowerCase().indexOf(searchValue.toLowerCase()) > -1;
    const { values, getSearchableStrings, renderFiltered } = props;
    const filteredValues = values.filter((it) =>
        getSearchableStrings(it).some((value) => value && caseInsensitiveSearch(value)),
    );

    return (
        <>
            <div className={'margin-left-20 margin-right-20 margin-top-10 margin-bottom-10'}>
                <div className={'form-group'}>
                    <label htmlFor={'search-phrase'}>{props.title}</label>
                    <div className={'input-group'}>
                        <span className={'input-group-addon'}>
                            <span className={'rioglyph rioglyph-search'} aria-hidden={'true'} />
                        </span>
                        <ClearableInput
                            id={'search-phrase'}
                            value={searchValue}
                            onChange={(value: string) => setSearchValue(value)}
                        />
                    </div>
                </div>
            </div>
            {filteredValues.length > 0 ? (
                renderFiltered(filteredValues)
            ) : (
                <NotFoundState
                    outerClassName={'border-none'}
                    headline={<FormattedMessage id={'webedi.nothingFound'} />}
                    message={<FormattedMessage id={'webedi.common.search.noResult'} />}
                />
            )}
        </>
    );
};
