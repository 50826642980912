import SimpleTooltip from '@rio-cloud/rio-uikit/SimpleTooltip';
import { FormattedMessage } from 'react-intl';
import { useAppSelector } from '../../../../../configuration/setup/typedReduxHooks';
import { MeasurementUnitCode } from '../../../domain/common.types';
import { PackagingTemplate } from '../../../reducers/deliverySchedules/types';
import { getSelectedMetadataEntry } from '../../../selectors/deliverySchedules/DeliverySchedules.selector';
import { MeasurementUnitCodeDisplay } from '../../common/MeasurementUnitCodeDisplay';
import { DIMENSIONS_INFO_ICON_TEST_ID } from './PackagingTemplateDimensionsForm';
import { PackagingTemplateLisonRefreshButton } from './PackagingTemplateLisonRefreshButton';

export const PACKAGING_TEMPLATE_LISON_HEADER_VIEW_TEST_ID = 'PACKAGING_TEMPLATE_LISON_HEADER_VIEW_TEST_ID';

export const PackagingTemplateLisonHeaderView = () => {
    const selectedTemplate = useAppSelector(getSelectedMetadataEntry) as PackagingTemplate;

    const displayDimension = (value: number | undefined) => (
        <span>
            {value ?? 0} <MeasurementUnitCodeDisplay unit={MeasurementUnitCode.MILLIMETRE} />
        </span>
    );

    return (
        <div
            className={'display-flex bg-white padding-10 justify-content-between align-items-center'}
            data-testid={PACKAGING_TEMPLATE_LISON_HEADER_VIEW_TEST_ID}
        >
            <span className={'padding-left-5 text-color-dark ellipsis-1'}>
                <FormattedMessage id={'webedi.packaging.packagingConfiguration'} /> - {selectedTemplate.name}
            </span>

            <div className={'pull-right display-flex btn-toolbar align-items-center'}>
                <div className={'display-flex flex-row gap-5 padding-right-10'}>
                    <span className={'text-color-dark padding-right-10 ellipsis-1'}>
                        <FormattedMessage id={'webedi.packaging.outerDimensions'} />:
                        <SimpleTooltip content={<FormattedMessage id={'webedi.packaging.outerDimensions.tooltip'} />}>
                            <span
                                className={'rioglyph rioglyph-info-sign text-size-14 text-color-info margin-x-5'}
                                data-testid={DIMENSIONS_INFO_ICON_TEST_ID}
                            />
                        </SimpleTooltip>
                        (<FormattedMessage id={'webedi.packaging.outerDimensions.lengthWidthHeight'} />
                        ): {displayDimension(selectedTemplate.dimensions.lengthInMm)}
                        {' × '}
                        {displayDimension(selectedTemplate.dimensions.widthInMm)}
                        {' × '}
                        {displayDimension(selectedTemplate.dimensions.heightInMm)}
                    </span>
                </div>
                <PackagingTemplateLisonRefreshButton />
            </div>
        </div>
    );
};
