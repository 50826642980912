import Sidebar from '@rio-cloud/rio-uikit/Sidebar';
import { useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useAppDispatch } from '../../../../../configuration/setup/typedReduxHooks';
import { neverReachedFor } from '../../../../../utils';
import { TransportConcept } from '../../../domain/meansOfTransport.types';
import { TransportOrder } from '../../../domain/transportOrder.types';
import { transportOrdersSlice } from '../../../reducers/transportOrders/TransportOrders.reducer';
import { dateFormatProps } from '../../common/i18n/FormattedDateOrDateTime';
import { useShowDetailsForTransportOrder } from '../transportOrdersHooks';
import { ShipmentSidebarView } from './ShipmentSidebarView';
import { SidebarFooter } from './TransportOrderSidebarFooter';
import { TransportOrderSidebarView } from './TransportOrderSidebarView';

const enum View {
    TRANSPORT_ORDER = 'TRANSPORT_ORDER',
    SHIPMENT = 'SHIPMENT',
}

const SidebarContent = (props: { view: View; transportOrder: TransportOrder }) => {
    const { transportOrder, view } = props;
    switch (view) {
        case View.TRANSPORT_ORDER:
            return <TransportOrderSidebarView transportOrder={transportOrder} />;
        case View.SHIPMENT:
            return transportOrder.shipment ? <ShipmentSidebarView shipment={transportOrder.shipment} /> : null;
        default:
            neverReachedFor(view);
            return null;
    }
};

const getLoadingFromTime = (transportOrder: TransportOrder): string =>
    transportOrder.freightForwarderResponse?.confirmedLoadingTimeWindow?.from ??
    transportOrder.loading.plannedLoadingTimeWindow.from;

interface ButtonProps {
    currentlySelectedView: View;
    onClick: (value: View) => void;
}

const ShowTransportOrderViewButton = (props: ButtonProps & { transportConcept: TransportConcept }) => (
    <button
        className={`btn ${props.currentlySelectedView === View.TRANSPORT_ORDER ? 'btn-primary' : 'btn-muted'} btn-action text-size-h5`}
        onClick={() => {
            props.onClick(View.TRANSPORT_ORDER);
        }}
    >
        <span
            className={`rioglyph ${props.transportConcept === 'KEP' ? 'rioglyph-van' : 'rioglyph-truck'} 
            ${props.currentlySelectedView !== View.TRANSPORT_ORDER ? ' text-color-gray' : ''}`}
            aria-hidden={'true'}
        />
        <FormattedMessage id={'webedi.transportOrders.overview.table.header.transportOrderNumber'} />
    </button>
);

const ShowShipmentViewButton = (props: ButtonProps & { disabled: boolean }) => (
    <button
        className={`btn ${props.currentlySelectedView === View.SHIPMENT ? 'btn-primary' : 'btn-muted'} btn-action text-size-h5 ${props.disabled ? 'disabled' : ''}`}
        onClick={() => props.onClick(View.SHIPMENT)}
    >
        <span
            className={`rioglyph rioglyph-order ${props.currentlySelectedView !== View.SHIPMENT ? ' text-color-gray' : ''}`}
            aria-hidden={'true'}
        />
        <FormattedMessage id={'webedi.shipment'} />
    </button>
);

export const TransportOrderSidebar = () => {
    const intl = useIntl();
    const dispatch = useAppDispatch();
    const [currentView, setCurrentView] = useState(View.TRANSPORT_ORDER);
    const selectedTransportOrder = useShowDetailsForTransportOrder();

    if (selectedTransportOrder === undefined) {
        return null;
    }

    const deselectTransportOrder = () => {
        dispatch(transportOrdersSlice.actions.setShowDetailsForTransportOrder(undefined));
    };

    return (
        <Sidebar
            className={'margin-30'}
            title={
                <FormattedMessage
                    id={'webedi.transportOrders.overview.sidebar.title'}
                    values={{
                        date: intl.formatDate(getLoadingFromTime(selectedTransportOrder), dateFormatProps),
                    }}
                />
            }
            position={'right'}
            titleClassName={'padding-left-10'}
            showHeaderBorder={true}
            onClose={deselectTransportOrder}
            footer={<SidebarFooter transportOrder={selectedTransportOrder} />}
            width={600}
            minWidth={360}
            maxWidth={640}
            resizable={true}
        >
            <>
                <div className={'display-flex flex-row justify-content-around align-items-center margin-top-15'}>
                    <ShowTransportOrderViewButton
                        transportConcept={selectedTransportOrder.transportConcept}
                        currentlySelectedView={currentView}
                        onClick={setCurrentView}
                    />
                    <ShowShipmentViewButton
                        currentlySelectedView={currentView}
                        onClick={setCurrentView}
                        disabled={selectedTransportOrder.shipment === undefined}
                    />
                </div>
                <SidebarContent view={currentView} transportOrder={selectedTransportOrder} />
            </>
        </Sidebar>
    );
};
