import Notification from '@rio-cloud/rio-uikit/Notification';
import { Mutator } from 'final-form';
import { useEffect } from 'react';
import { DropResult } from 'react-beautiful-dnd';
import { useFormState } from 'react-final-form';
import { FieldArray } from 'react-final-form-arrays';
import { FormattedMessage } from 'react-intl';
import { v4 as uuid } from 'uuid';
import { useAppDispatch, useAppSelector } from '../../../../../../configuration/setup/typedReduxHooks';
import { wouldChangesToShipmentInvalidatePackaging } from '../../../../../../utils';
import {
    closeSidebar,
    getNextAvailableDeliveryNoteNumberAction,
    resetArticleSuggestionForDeliveryNote,
} from '../../../../actions/shipments/Shipments.actions';
import {
    ArticleSuggestionForDeliveryNote,
    DeliveryNote,
    LoadItem,
    Shipment,
} from '../../../../reducers/shipments/types';
import {
    getArticleSuggestionToAdd,
    getNextAvailableDeliveryNoteNumber,
    getSelectedShipment,
} from '../../../../selectors/shipments/Shipments.selector';
import { DeliveryNoteEdit } from './DeliveryNoteEdit';
import { DeliveryNoteContentEditViewProps, DeliveryNoteFormFields } from './types';

export const DELIVERY_NOTES_CONTENT_TEST_ID = 'DELIVERY_NOTES_CONTENT_TEST_ID';

const MAX_DELIVERY_NOTE_NUMBER = 99_999_999;

export const DeliveryNotesContentEditView = (props: DeliveryNoteContentEditViewProps) => {
    const { formMutators, duns } = props;
    const dispatch = useAppDispatch();
    const articleSuggestionToAdd: ArticleSuggestionForDeliveryNote | undefined =
        useAppSelector(getArticleSuggestionToAdd);
    const nextAvailableDeliveryNoteNumber = useAppSelector(getNextAvailableDeliveryNoteNumber);
    const onDeliveryNoteAddHandler = () => formMutators.addDeliveryNote(nextAvailableDeliveryNoteNumber);
    const savedShipment = useAppSelector(getSelectedShipment);
    const shipment = useFormState().values;

    useEffect(() => {
        if (articleSuggestionToAdd) {
            const newLoadItem: LoadItem = {
                id: uuid(),
                orderNumber: articleSuggestionToAdd.articleSuggestion.orderNumber,
                articleNumberBuyer: articleSuggestionToAdd.articleSuggestion.articleNumberBuyer,
                articleMasterData: articleSuggestionToAdd.articleSuggestion.articleMasterData,
                amount: {
                    value: 1,
                    measurementUnitCode: articleSuggestionToAdd.articleSuggestion.measurementUnitCode,
                },
                isSubjectToPreferenceAuthorization: false,
                referencedDeliveryScheduleId: articleSuggestionToAdd.articleSuggestion.referencedDeliveryScheduleId,
            };
            formMutators.addLoadItem(articleSuggestionToAdd.matchingDeliveryNoteNumber, newLoadItem);
            dispatch(resetArticleSuggestionForDeliveryNote());
        }
    }, [dispatch, formMutators, articleSuggestionToAdd]);

    useEffect(
        () => () => {
            dispatch(closeSidebar());
        },
        [dispatch],
    );

    // biome-ignore lint/correctness/useExhaustiveDependencies: Migrated from eslint
    useEffect(() => {
        dispatch(getNextAvailableDeliveryNoteNumberAction(duns));
    }, [dispatch, nextAvailableDeliveryNoteNumber, duns]);

    if (savedShipment === undefined) {
        return null;
    }

    const changesWillResetPackagingErrorBox = () => (
        <div className={'alert alert-danger margin-bottom-20 display-flex'} role={'alert'}>
            <span className={'text-color-danger text-size-h4 margin-right-10 rioglyph rioglyph-error-sign'} />
            <FormattedMessage id={'webedi.shipment.resetPackagingWarning'} />
        </div>
    );

    return (
        <div data-testid={DELIVERY_NOTES_CONTENT_TEST_ID}>
            {wouldChangesToShipmentInvalidatePackaging(shipment as Shipment, savedShipment!) &&
                changesWillResetPackagingErrorBox()}
            {
                <FieldArray name={`load` as unknown as keyof Shipment as string}>
                    {({ fields }: { fields: DeliveryNoteFormFields }) => {
                        return fields.map((_deliveryNotePath: string, deliveryNoteIndex: number) => {
                            return (
                                <DeliveryNoteEdit
                                    deliveryNote={shipment.load[deliveryNoteIndex]}
                                    deliveryNotePath={`load[${deliveryNoteIndex}]`}
                                    // biome-ignore lint/suspicious/noArrayIndexKey: Fix this see RIOINBBL-1932
                                    key={`load[${deliveryNoteIndex}]`}
                                    deleteDeliveryNote={formMutators.deleteDeliveryNote}
                                    updateDeliveryNote={formMutators.updateDeliveryNote}
                                />
                            );
                        });
                    }}
                </FieldArray>
            }
            <button
                type={'button'}
                className={'btn btn-default center-block'}
                onClick={onDeliveryNoteAddHandler}
                disabled={!nextAvailableDeliveryNoteNumber}
            >
                <span className={'rioglyph rioglyph-plus'} />
                <FormattedMessage id={'webedi.shipment.addDeliveryNote'} />
            </button>
        </div>
    );
};

export const handleOnDragEnd: Mutator<Shipment> = (args: [DropResult, ...unknown[]], state, { changeValue, getIn }) => {
    const { source, destination } = args[0];
    if (!destination) {
        return;
    }
    if (source.droppableId === destination.droppableId) {
        const loadItems: LoadItem[] = [...getIn(state, `formState.values.${source.droppableId}.loadItems`)];
        const removed = loadItems.splice(source.index, 1)[0];
        loadItems.splice(destination.index, 0, removed);
        changeValue(state, `${source.droppableId}.loadItems`, () => loadItems);
    } else {
        const sourceLoadItems: LoadItem[] = [...getIn(state, `formState.values.${source.droppableId}.loadItems`)];
        const destinationLoadItems: LoadItem[] = [
            ...getIn(state, `formState.values.${destination.droppableId}.loadItems`),
        ];
        const removed = sourceLoadItems.splice(source.index, 1)[0];
        destinationLoadItems.splice(destination.index, 0, removed);
        changeValue(state, `${source.droppableId}.loadItems`, () => sourceLoadItems);
        changeValue(state, `${destination.droppableId}.loadItems`, () => destinationLoadItems);
    }
};

export const getNextDeliveryNoteNumber = (
    existingDeliveryNoteNumbers: number[],
    suggestedAvailableNumber: number,
): number => {
    const maxExistingDeliveryNoteNumber = Math.max(...existingDeliveryNoteNumbers);
    let newDeliveryNoteNumber = Math.max(suggestedAvailableNumber, maxExistingDeliveryNoteNumber + 1);

    if (newDeliveryNoteNumber === MAX_DELIVERY_NOTE_NUMBER + 1) {
        newDeliveryNoteNumber = 1;
        while (existingDeliveryNoteNumbers.includes(newDeliveryNoteNumber)) {
            newDeliveryNoteNumber++;
        }
    }
    return newDeliveryNoteNumber;
};

export const addDeliveryNote: Mutator<Shipment> = (
    args: [nextDeliveryNoteNumber: number],
    state,
    { changeValue, getIn },
) => {
    const suggestedAvailableNumber = args[0];
    const deliveryNotes: DeliveryNote[] = [...getIn(state, `formState.values.load`)];
    const deliveryNoteNumber = getNextDeliveryNoteNumber(
        deliveryNotes.map((it) => it.deliveryNoteNumber),
        suggestedAvailableNumber,
    );
    const newDeliveryNote: DeliveryNote = { deliveryNoteNumber, loadItems: [] };
    changeValue(state, 'load', () => [...deliveryNotes, newDeliveryNote]);
};

const findDeliveryNoteIndex = (deliveryNotes: DeliveryNote[], deliveryNoteNumber: number): number => {
    return deliveryNotes.findIndex((deliveryNote) => deliveryNote.deliveryNoteNumber === deliveryNoteNumber);
};

export const addLoadItem: Mutator<Shipment> = (
    args: [deliveryNoteNumber: number, newLoadItem: LoadItem],
    state,
    { changeValue, getIn },
) => {
    const deliveryNoteNumber: number = args[0];
    const newLoadItem: LoadItem = args[1];
    const deliveryNotes: DeliveryNote[] = [...getIn(state, `formState.values.load`)];
    const indexOfDeliveryNoteToUpdate: number = findDeliveryNoteIndex(deliveryNotes, deliveryNoteNumber);

    if (indexOfDeliveryNoteToUpdate >= 0) {
        const newDeliveryNote: DeliveryNote = {
            ...deliveryNotes[indexOfDeliveryNoteToUpdate],
            loadItems: [...deliveryNotes[indexOfDeliveryNoteToUpdate].loadItems, newLoadItem],
        };

        changeValue(state, 'load', () => {
            deliveryNotes.splice(indexOfDeliveryNoteToUpdate, 1, newDeliveryNote);
            return deliveryNotes;
        });
    }
};

export const deleteDeliveryNote: Mutator<Shipment> = (
    args: [DeliveryNote, ...unknown[]],
    state,
    { changeValue, getIn },
) => {
    const deliveryNote: DeliveryNote = args[0];
    if (deliveryNote.loadItems.length > 0) {
        Notification.error(<FormattedMessage id={'webedi.deliveryNote.errorMessage.deleteNonEmptyDeliveryNote'} />);
        return;
    }
    const deliveryNotes = [...getIn(state, `formState.values.load`)];
    if (deliveryNotes.length === 1) {
        Notification.error(<FormattedMessage id={'webedi.deliveryNote.errorMessage.deleteLastDeliveryNote'} />);
        return;
    }
    changeValue(state, 'load', () =>
        deliveryNotes.filter((note) => note.deliveryNoteNumber !== deliveryNote.deliveryNoteNumber),
    );
};

export const updateDeliveryNote: Mutator<Shipment> = (
    args: [DeliveryNote, ...unknown[]],
    state,
    { changeValue, getIn },
) => {
    const deliveryNoteToUpdate: DeliveryNote = args[0];
    const deliveryNotes: DeliveryNote[] = [...getIn(state, `formState.values.load`)];
    const indexOfDeliveryNoteToUpdate: number = findDeliveryNoteIndex(
        deliveryNotes,
        deliveryNoteToUpdate.deliveryNoteNumber,
    );

    if (indexOfDeliveryNoteToUpdate >= 0) {
        changeValue(state, 'load', () => {
            deliveryNotes.splice(indexOfDeliveryNoteToUpdate, 1, deliveryNoteToUpdate);
            return deliveryNotes;
        });
    }
};
