import EditableContent from '@rio-cloud/rio-uikit/EditableContent';
import { FormattedMessage } from 'react-intl';
import { useDiscoveryNextGenFeatures } from '../../../../../configuration/featureToggle/hooks';
import { getValueOrDefaultWhenLoading } from '../../../../../configuration/featureToggle/utils';
import { useAppDispatch, useAppSelector } from '../../../../../configuration/setup/typedReduxHooks';
import { isReadOnlyAdmin } from '../../../../../configuration/tokenHandling/selectors';
import { deletePackagingTemplateAndRefresh } from '../../../actions/deliverySchedules/PackagingTemplate.actions';
import { useDeliveryScheduleIdFromPath } from '../../../hooks/Routing.hooks';
import { deliverySchedulesSlice } from '../../../reducers/deliverySchedules/DeliverySchedules.reducer';
import { PackagingStepType, PackagingTemplate } from '../../../reducers/deliverySchedules/types';
import {
    getPackagingTemplateNameFromForm,
    getSelectedMetadataEntry,
} from '../../../selectors/deliverySchedules/DeliverySchedules.selector';
import { Tooltip } from '../../common/Tooltip';
import { PackagingTemplateDimensionsForm } from './PackagingTemplateDimensionsForm';

export const PACKAGING_TEMPLATE_HEADER_CREATE_PACKAGING_BUTTON_TEST_ID =
    'PACKAGING_TEMPLATE_HEADER_CREATE_PACKAGING_BUTTON';
export const PACKAGING_TEMPLATE_HEADER_CREATE_DELETE_BUTTON_TEST_ID =
    'PACKAGING_TEMPLATE_HEADER_DELETE_PACKAGING_BUTTON';
export const PACKAGING_TEMPLATE_HEADER_VIEW_TEST_ID = 'PACKAGING_TEMPLATE_HEADER_VIEW_TEST_ID';

export const PackagingTemplateHeaderView = () => {
    const dispatch = useAppDispatch();
    const selectedTemplate = useAppSelector(getSelectedMetadataEntry) as PackagingTemplate;
    const packagingTemplateNameFromForm = useAppSelector(getPackagingTemplateNameFromForm);
    const isReadOnly = useAppSelector(isReadOnlyAdmin);
    const packagingTemplateName = packagingTemplateNameFromForm ?? selectedTemplate.name;
    const webSCMPlusFeaturesAreActive = getValueOrDefaultWhenLoading(useDiscoveryNextGenFeatures());
    const deliveryScheduleId = useDeliveryScheduleIdFromPath() ?? '';

    const deletePackagingConfigurationButton = () => {
        const content = (
            <button
                type={'button'}
                data-testid={PACKAGING_TEMPLATE_HEADER_CREATE_DELETE_BUTTON_TEST_ID}
                className={'btn btn-danger btn-outline'}
                onClick={() => {
                    dispatch(
                        deletePackagingTemplateAndRefresh(
                            selectedTemplate.id,
                            selectedTemplate.dunsNumberOwner,
                            selectedTemplate.persisted,
                            deliveryScheduleId,
                        ),
                    );
                }}
                disabled={isReadOnly}
            >
                <span className={isReadOnly ? '' : 'text-normal'}>
                    <span className={'rioglyph rioglyph-trash margin-right-5'} />
                    <FormattedMessage id={'webedi.label.delete'} />
                    {isReadOnly && (
                        <span
                            className={
                                'rioglyph rioglyph-info-sign text-color-info text-size-18 padding-left-5 margin-right-0'
                            }
                        />
                    )}
                </span>
            </button>
        );

        const readOnlyContent = (
            <Tooltip text={<FormattedMessage id={'webedi.error.insufficientPermissions'} />} placement={'top'}>
                <span>{content}</span>
            </Tooltip>
        );

        return isReadOnly ? readOnlyContent : content;
    };

    const handleSaveName = (name: string) => dispatch(deliverySchedulesSlice.actions.updatePackagingTemplateName(name));

    return (
        <div
            className={'display-flex bg-white padding-10 justify-content-between align-items-center'}
            data-testid={PACKAGING_TEMPLATE_HEADER_VIEW_TEST_ID}
        >
            <div className={'display-flex align-items-center gap-5 cursor-pointer text-size-16 padding-right-25'}>
                <EditableContent inputClassName={'width-350'} onSave={handleSaveName}>
                    <span>{packagingTemplateName} </span>
                    <span className={'rioglyph rioglyph-pencil'} />
                </EditableContent>
            </div>
            <div className={'pull-right display-flex align-items-center btn-toolbar'}>
                {webSCMPlusFeaturesAreActive ? <PackagingTemplateDimensionsForm /> : undefined}

                <button
                    type={'button'}
                    data-testid={PACKAGING_TEMPLATE_HEADER_CREATE_PACKAGING_BUTTON_TEST_ID}
                    className={'btn btn-primary'}
                    onClick={() => {
                        dispatch(deliverySchedulesSlice.actions.showTemplateDialog(PackagingStepType.HOMOGENEOUS));
                    }}
                >
                    <span className={'rioglyph rioglyph-parcel'} />
                    <FormattedMessage id={'webedi.packaging.createPackaging'} />
                </button>
                {deletePackagingConfigurationButton()}
            </div>
        </div>
    );
};
