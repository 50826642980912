import Dialog from '@rio-cloud/rio-uikit/Dialog';
import { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useAppSelector } from '../../../../../configuration/setup/typedReduxHooks';
import { getSelectedDeliveryScheduleId } from '../../../selectors/deliverySchedules/DeliverySchedules.selector';
import { DeliveryScheduleChildDialogProps, DisplayMode } from '../DeliveryScheduleDialog';
import { DeliveryInstructionsArchiveTable } from './DeliveryInstructionsArchiveTable';

export const DELIVERY_INSTRUCTION_ARCHIVE_DIALOG_TEST_ID = 'DELIVERY_INSTRUCTION_ARCHIVE_DIALOG';
export const DELIVERY_SCHEDULES_DIALOG_ARCHIVE_CONTENT_TEST_ID = 'DELIVERY_SCHEDULES_DIALOG_ARCHIVE_CONTENT_TEST_ID';

export const DeliveryInstructionsArchiveDialog = (props: DeliveryScheduleChildDialogProps) => {
    const deliveryScheduleId = useAppSelector(getSelectedDeliveryScheduleId);
    const [showDialog, setShowDialog] = useState<boolean>(false);

    useEffect(() => {
        setShowDialog(true);
    }, []);

    if (deliveryScheduleId === undefined) {
        return null;
    }

    const headerButtons = (
        <>
            <button className={'btn btn-muted'} onClick={() => props.setDisplayMode(DisplayMode.DETAILS)}>
                <span className={'rioglyph rioglyph-detail-view-info'} />
                <FormattedMessage id={'webedi.deliverySchedule.deliveryScheduleDetails.title'} />
            </button>
        </>
    );

    const body = (
        <div data-testid={DELIVERY_SCHEDULES_DIALOG_ARCHIVE_CONTENT_TEST_ID}>
            <DeliveryInstructionsArchiveTable referenceId={deliveryScheduleId} />
        </div>
    );

    const footer = (
        <div className={'display-flex align-items-center btn-toolbar pull-right'}>
            <button
                className={'btn btn-default'}
                onClick={() => {
                    setShowDialog(false);
                    props.onHide();
                }}
            >
                <FormattedMessage id={'webedi.common.close'} />
            </button>
        </div>
    );

    return (
        <div data-testid={DELIVERY_INSTRUCTION_ARCHIVE_DIALOG_TEST_ID}>
            <Dialog
                show={showDialog}
                title={<FormattedMessage id={'webedi.deliveryScheduleDetails.archive.label'} />}
                headerButtons={headerButtons}
                body={body}
                bodyClassName={'padding-0 padding-top-25 bg-lightest'}
                onHide={props.onHide}
                bsSize={Dialog.SIZE_XL}
                footer={footer}
                showCloseButton
                useOverflow
            />
        </div>
    );
};
