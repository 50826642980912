import SimpleTooltip from '@rio-cloud/rio-uikit/SimpleTooltip';
import { FormattedMessage } from 'react-intl';
import { useAppDispatch, useAppSelector } from '../../../../../configuration/setup/typedReduxHooks';
import { deliverySchedulesSlice } from '../../../reducers/deliverySchedules/DeliverySchedules.reducer';
import { PackagingTemplate } from '../../../reducers/deliverySchedules/types';
import {
    getPackagingTemplateDimensionsFromForm,
    getSelectedMetadataEntry,
} from '../../../selectors/deliverySchedules/DeliverySchedules.selector';
import { DimensionInputComponent } from '../../common/DimensionInputComponent';

export const PackagingTemplateDimensionsForm = () => {
    const dispatch = useAppDispatch();
    const selectedTemplate = useAppSelector(getSelectedMetadataEntry) as PackagingTemplate;

    const packagingTemplateDimensionsFromForm = useAppSelector(getPackagingTemplateDimensionsFromForm);
    const packagingTemplateDimensions = packagingTemplateDimensionsFromForm ?? selectedTemplate.dimensions;

    const handleSaveDimension = (dimension: 'widthInMm' | 'lengthInMm' | 'heightInMm', value: number | undefined) => {
        if (packagingTemplateDimensionsFromForm === undefined) {
            dispatch(deliverySchedulesSlice.actions.setPackagingTemplateDimensions(packagingTemplateDimensions));
        }
        dispatch(deliverySchedulesSlice.actions.updatePackagingTemplateDimension({ dimension, value }));
    };

    return (
        <div data-testid={DIMENSIONS_PLACEHOLDER_TEST_ID} className={'display-flex flex-row gap-5 padding-right-10'}>
            <span className={'text-color-dark padding-right-10 ellipsis-1'}>
                <FormattedMessage id={'webedi.packaging.outerDimensions'} />:
                <SimpleTooltip content={<FormattedMessage id={'webedi.packaging.outerDimensions.tooltip'} />}>
                    <span
                        className={'rioglyph rioglyph-info-sign text-size-14 text-color-info margin-x-5'}
                        data-testid={DIMENSIONS_INFO_ICON_TEST_ID}
                    />
                </SimpleTooltip>
                (<FormattedMessage id={'webedi.packaging.outerDimensions.lengthWidthHeight'} />
                ):
            </span>
            <div className={'display-flex align-items-center gap-5 cursor-pointer'}>
                <DimensionInput
                    value={packagingTemplateDimensions?.lengthInMm}
                    saveHandler={(value: number | undefined) => handleSaveDimension('lengthInMm', value)}
                    pencilTestId={DIMENSIONS_PENCIL_ICON_LENGTH_TEST_ID}
                    valueTestId={DIMENSIONS_LENGTH_VALUE_TEST_ID}
                />
            </div>
            <span>×</span>
            <div className={'display-flex align-items-center gap-5 cursor-pointer'}>
                <DimensionInput
                    value={packagingTemplateDimensions?.widthInMm}
                    saveHandler={(value: number | undefined) => handleSaveDimension('widthInMm', value)}
                    pencilTestId={DIMENSIONS_PENCIL_ICON_WIDTH_TEST_ID}
                    valueTestId={DIMENSIONS_WIDTH_VALUE_TEST_ID}
                />
            </div>
            <span>×</span>
            <div className={'display-flex align-items-center gap-5 cursor-pointer'}>
                <DimensionInput
                    value={packagingTemplateDimensions?.heightInMm}
                    saveHandler={(value: number | undefined) => handleSaveDimension('heightInMm', value)}
                    pencilTestId={DIMENSIONS_PENCIL_ICON_HEIGHT_TEST_ID}
                    valueTestId={DIMENSIONS_HEIGHT_VALUE_TEST_ID}
                />
            </div>
        </div>
    );
};

const DimensionInput = (props: {
    value: number | undefined;
    saveHandler: (value: number | undefined) => void;
    pencilTestId: string;
    valueTestId: string;
}) => {
    const content = (
        <span>
            <span>{`${props.value ?? '0'} mm`}</span>
            <span className={'rioglyph rioglyph-pencil'} data-testid={props.pencilTestId} />
        </span>
    );

    return (
        <DimensionInputComponent
            value={props.value}
            saveHandler={props.saveHandler}
            valueTestId={props.valueTestId}
            validationErrorTestId={undefined}
            content={content}
        />
    );
};

export const DIMENSIONS_INFO_ICON_TEST_ID = 'DIMENSIONS_INFO_ICON_TEST_ID';
export const DIMENSIONS_PLACEHOLDER_TEST_ID = 'DIMENSIONS_PLACEHOLDER_TEST_ID';
export const DIMENSIONS_PENCIL_ICON_LENGTH_TEST_ID = 'DIMENSIONS_PENCIL_ICON_LENGTH_TEST_ID';
export const DIMENSIONS_PENCIL_ICON_WIDTH_TEST_ID = 'DIMENSIONS_PENCIL_ICON_WIDTH_TEST_ID';
export const DIMENSIONS_PENCIL_ICON_HEIGHT_TEST_ID = 'DIMENSIONS_PENCIL_ICON_HEIGHT_TEST_ID';
export const DIMENSIONS_LENGTH_VALUE_TEST_ID = 'DIMENSIONS_LENGTH_VALUE_TEST_ID';
export const DIMENSIONS_WIDTH_VALUE_TEST_ID = 'DIMENSIONS_WIDTH_VALUE_TEST_ID';
export const DIMENSIONS_HEIGHT_VALUE_TEST_ID = 'DIMENSIONS_HEIGHT_VALUE_TEST_ID';
