import { SerializedError } from '@reduxjs/toolkit';
import { FetchBaseQueryError } from '@reduxjs/toolkit/query';
import NotFoundState from '@rio-cloud/rio-uikit/NotFoundState';
import { FormattedMessage } from 'react-intl';
import { useAppDispatch } from '../../../../../configuration/setup/typedReduxHooks';
import { nonVanishingFormattedErrorNotification } from '../../../actions/NotificationUtils';
import { downloadFileContent } from '../../../actions/files/Files.actions';
import { generateDeliveryForecastDocument } from '../../../actions/shipmentDocuments/shipmentDocuments.actions';
import { useGetEdiFileMetadataQuery } from '../../../api/file/ediFilesApi';
import { ReferenceType, VDA4984EdiFileSummary } from '../../../domain/files.types';
import { useDunsNumberFromPath } from '../../../hooks/Routing.hooks';
import { LoadingIndicator } from '../../common/LoadingIndicator';
import { FormattedDateOrDateTime } from '../../common/i18n/FormattedDateOrDateTime';

export const DELIVERY_INSTRUCTION_ARCHIVE_TABLE_HEADER = 'DELIVERY_INSTRUCTION_ARCHIVE_TABLE_HEADER';
export const DELIVERY_INSTRUCTION_ARCHIVE_TABLE_BODY = 'DELIVERY_INSTRUCTION_ARCHIVE_TABLE_BODY';

export interface DeliveryInstructionArchiveTableProps {
    referenceId: string;
}

export const DeliveryInstructionsArchiveTable = (props: DeliveryInstructionArchiveTableProps) => {
    const { referenceId } = props;

    const dispatch = useAppDispatch();
    const dunsNumber = useDunsNumberFromPath()!;

    const result = useGetEdiFileMetadataQuery({
        dunsNumber,
        referenceType: ReferenceType.DELIVERY_INSTRUCTION,
        referenceId,
    });

    const downloadFileHandler = (fileSummary: VDA4984EdiFileSummary) =>
        dispatch(downloadFileContent(fileSummary.dunsNumber, fileSummary.id, fileSummary.filename));

    const printFileHandler = (fileSummary: VDA4984EdiFileSummary) =>
        dispatch(generateDeliveryForecastDocument(dunsNumber, fileSummary.id, fileSummary.filename));

    const roundBytesToKb = (bytes: number) => Math.round(bytes / 100) / 10;

    const renderRow = (fileSummary: VDA4984EdiFileSummary) => {
        const referencedDeliveryInstruction = fileSummary.referencedDeliveryInstructions[referenceId];
        return (
            <tr
                key={`${fileSummary.id}_${referencedDeliveryInstruction.id}`}
                data-key={referencedDeliveryInstruction.id}
            >
                <td className={'padding-left-25'}>
                    {referencedDeliveryInstruction.deliveryInstructionDate ? (
                        <FormattedDateOrDateTime date={referencedDeliveryInstruction.deliveryInstructionDate} />
                    ) : (
                        '-'
                    )}
                </td>
                <td>{referencedDeliveryInstruction.deliveryInstructionNumber || '-'}</td>
                <td>{fileSummary.deliveryInstructionType}</td>
                <td>{roundBytesToKb(fileSummary.fileSize)} kB</td>
                <td className={'width-100 padding-right-25'}>
                    <div className={'btn-group gap-10'}>
                        <button className={'btn btn-default'} onClick={() => downloadFileHandler(fileSummary)}>
                            <span className={'rioglyph rioglyph-download'} />{' '}
                            <FormattedMessage id={'webedi.download'} />
                        </button>
                        <button className={'btn btn-default'} onClick={() => printFileHandler(fileSummary)}>
                            <span className={'rioglyph rioglyph-print'} />{' '}
                            <FormattedMessage id={'webedi.label.print'} />
                        </button>
                    </div>
                </td>
            </tr>
        );
    };

    const tableClassNames = [
        'table',
        'table-column-overflow-hidden',
        'table-bordered',
        'table-sticky',
        'table-head-filled',
    ].join(' ');

    if (result.isError) {
        nonVanishingFormattedErrorNotification({
            messageId: 'webedi.error.unknown',
            errorCode:
                (result.error as FetchBaseQueryError).status !== undefined
                    ? (result.error as FetchBaseQueryError).status.toString()
                    : (result.error as SerializedError).code,
        });
    }

    if (result.isLoading) {
        return <LoadingIndicator />;
    }

    if (result.isSuccess) {
        return (
            <table className={tableClassNames}>
                <thead data-testid={DELIVERY_INSTRUCTION_ARCHIVE_TABLE_HEADER}>
                    <tr>
                        <th className={'padding-left-25'}>
                            <FormattedMessage id={'webedi.deliveryInstruction.archive.date'} />
                        </th>
                        <th>
                            <FormattedMessage id={'webedi.deliveryInstruction.archive.number'} />
                        </th>
                        <th>
                            <FormattedMessage id={'webedi.deliveryInstruction.archive.type'} />
                        </th>
                        <th>
                            <FormattedMessage id={'webedi.deliveryInstruction.archive.fileSize'} />
                        </th>
                        <th className={'padding-right-25'} />
                    </tr>
                </thead>
                <tbody data-testid={DELIVERY_INSTRUCTION_ARCHIVE_TABLE_BODY}>
                    {result.data!.map((it) => renderRow(it as VDA4984EdiFileSummary))}
                </tbody>
            </table>
        );
    }

    return <NotFoundState headline={<FormattedMessage id={'webedi.nothingFound'} />} message={''} />;
};
