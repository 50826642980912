import DatePickerUiKit from '@rio-cloud/rio-uikit/DatePicker';
import moment from 'moment';
import '../../../../common/momentLocales';
import { HTMLProps } from 'react';
import { getLocale } from '../../../../configuration/lang/selectors';
import { useAppSelector } from '../../../../configuration/setup/typedReduxHooks';
import { isValidIso8601 } from '../../../../dateUtils';

export interface DatePickerProps {
    value?: string;
    onChange?: (value?: string) => void;
    initialValue?: string;
    inputProps?: HTMLProps<HTMLInputElement>;
    isValidDate?: (currentDate?: string) => boolean;
    dateValidation?: (selectedDate?: string) => boolean;
    clearableInput?: boolean;
    mandatory?: boolean;
    className?: string;
}

export const DatePicker = (props: DatePickerProps) => {
    const locale = useAppSelector(getLocale);
    const formatAndValidate = (value: moment.Moment | string): string | undefined => {
        const dateString = moment.isMoment(value) ? value.format('YYYY-MM-DD') : value;
        return isValidIso8601(dateString) ? dateString : undefined;
    };

    return (
        <DatePickerUiKit
            {...props}
            value={props.value !== undefined ? moment(props.value) : undefined}
            initialValue={props.initialValue !== undefined ? moment(props.initialValue) : undefined}
            onChange={
                props.onChange !== undefined
                    ? (value: moment.Moment | string) => props.onChange!(formatAndValidate(value))
                    : undefined
            }
            isValidDate={
                props.isValidDate !== undefined
                    ? (currentDate: moment.Moment | string) => props.isValidDate!(formatAndValidate(currentDate))
                    : undefined
            }
            dateValidation={
                props.dateValidation !== undefined
                    ? (selectedDate: moment.Moment | string) => props.dateValidation!(formatAndValidate(selectedDate))
                    : undefined
            }
            timeFormat={false}
            closeOnSelect={true}
            locale={locale}
            inputProps={{ ...props.inputProps, readOnly: true }}
        />
    );
};
