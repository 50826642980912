import Checkbox from '@rio-cloud/rio-uikit/Checkbox';
import { ReactNode } from 'react';
import { FieldRenderProps } from 'react-final-form';

export interface CheckboxInputProps extends FieldRenderProps<boolean> {
    id?: string;
    disabled?: boolean;
    children?: ReactNode;
    tabIndex?: number;
}

export const CheckboxInput = (props: CheckboxInputProps) => {
    const { input, ...rest } = props;
    const { id, disabled, tabIndex, children } = rest;

    if (input.checked === undefined) {
        throw new Error('when using a checkbox with react final form field type must be set to "checkbox"');
    }

    return (
        <Checkbox id={id} checked={input.checked} onClick={input.onChange} disabled={disabled} tabIndex={tabIndex}>
            {children}
        </Checkbox>
    );
};
