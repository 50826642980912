import Sidebar from '@rio-cloud/rio-uikit/Sidebar';
import { FormattedMessage, useIntl } from 'react-intl';
import { useAppDispatch, useAppSelector } from '../../../../../../configuration/setup/typedReduxHooks';
import { zeroPadNumber } from '../../../../../../utils';
import { closeSidebar } from '../../../../actions/shipments/Shipments.actions';
import {
    getSelectedShipment,
    isShipmentArticleSuggestionSidebarOpen,
} from '../../../../selectors/shipments/Shipments.selector';
import { ArticleSuggestionTable } from './ArticleSuggestionTable';

export const SHIPMENT_EDIT_ARTICLE_SUGGESTION_SIDEBAR_TEST_ID = 'SHIPMENT_EDIT_ARTICLE_SUGGESTION_SIDEBAR_TEST_ID';

export const DeliveryNoteEditArticleSuggestionSidebar = () => {
    const isOpen = useAppSelector(isShipmentArticleSuggestionSidebarOpen);
    const shipment = useAppSelector(getSelectedShipment);
    const dispatch = useAppDispatch();
    const intl = useIntl();

    const title = intl.formatMessage(
        { id: 'webedi.articleSuggestionSidebar.header' },
        { shipment: shipment !== undefined ? zeroPadNumber(shipment.shipmentNumber, 8) : '' },
    );

    const onCloseSidebar = () => {
        dispatch(closeSidebar());
    };

    const sidebarContent = (
        <div
            className={'border-style-solid border-color-light border-width-1 border-top-only'}
            data-testid={SHIPMENT_EDIT_ARTICLE_SUGGESTION_SIDEBAR_TEST_ID}
        >
            <ArticleSuggestionTable />
        </div>
    );

    return (
        isOpen && (
            <Sidebar
                className={'margin-30'}
                title={title}
                position={'left'}
                titleClassName={'padding-left-10'}
                width={600}
                closed={!isOpen}
                onClose={onCloseSidebar}
                footer={
                    <button className={'btn btn-primary'} type={'button'} onClick={onCloseSidebar}>
                        <FormattedMessage id={'webedi.common.close'} />
                    </button>
                }
                minWidth={360}
                maxWidth={640}
                resizable={true}
            >
                {sidebarContent}
            </Sidebar>
        )
    );
};
