import ToggleButton from '@rio-cloud/rio-uikit/ToggleButton';
import { FormattedMessage } from 'react-intl';
import { State } from '../../../../configuration/setup/store';
import { useAppSelector } from '../../../../configuration/setup/typedReduxHooks';
import { getDispatchProposalsSelectedFilters } from '../../selectors/dispatchProposals/DispatchProposals.selector';

export const DISPATCH_PROPOSAL_TOGGLE_FILTERS_PANEL_BUTTON = 'DISPATCH_PROPOSAL_TOGGLE_FILTERS_PANEL_BUTTON';
export const DISPATCH_PROPOSAL_TOGGLE_FILTERS_PANEL_ICON = 'DISPATCH_PROPOSAL_TOGGLE_FILTERS_PANEL_ICON';

interface DispatchProposalsFilterToggleButtonProps {
    toggleShow: () => void;
    active: boolean;
}

export const DispatchProposalsFilterToggleButton = (props: DispatchProposalsFilterToggleButtonProps) => {
    const { toggleShow } = props;

    const filterParams = useAppSelector((state: State) => getDispatchProposalsSelectedFilters(state));
    const { ...relevantFilters } = filterParams;
    const activeFilterCount = Object.values(relevantFilters).filter((it) => it !== undefined && it.length > 0).length;

    return (
        <ToggleButton
            data-testid={DISPATCH_PROPOSAL_TOGGLE_FILTERS_PANEL_BUTTON}
            active={props.active}
            bsStyle={'default'}
            onClick={() => toggleShow()}
        >
            <span
                data-testid={DISPATCH_PROPOSAL_TOGGLE_FILTERS_PANEL_ICON}
                className={activeFilterCount > 0 ? 'rioglyph rioglyph-filter-active' : 'rioglyph rioglyph-filter'}
            />
            <FormattedMessage id={'webedi.common.filter'} />
        </ToggleButton>
    );
};
