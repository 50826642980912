import Notification from '@rio-cloud/rio-uikit/Notification';
import { Dispatch } from '../../../../configuration/setup/store';
import {
    deletePackagingTemplate,
    fetchLisonPackagingTemplate,
    fetchPackagingTemplates,
    patchPackagingTemplate,
    postPackagingTemplate,
} from '../../api/packagingTemplate/packagingTemplateCalls';
import { deliverySchedulesSlice } from '../../reducers/deliverySchedules/DeliverySchedules.reducer';
import {
    PackagingStep,
    PackagingTemplate,
    PackagingTemplateCreationRequest,
    PackagingTemplateType,
    PackagingTemplateUpdateRequest,
} from '../../reducers/deliverySchedules/types';
import { Dimensions } from '../../reducers/shipments/types';
import { notifySuccess } from '../NotificationUtils';
import { fetchAndStoreDeliverySchedule } from './DeliverySchedules.actions';

export const fetchAndStorePackagingTemplates =
    (dunsNumber: string, deliveryScheduleId: string) => async (dispatch: Dispatch) => {
        dispatch(deliverySchedulesSlice.actions.setPackagingTemplatesAreLoading);
        return fetchPackagingTemplates(dunsNumber, deliveryScheduleId)
            .then((packagingTemplates: PackagingTemplate[]) =>
                dispatch(deliverySchedulesSlice.actions.updatePackagingTemplates(packagingTemplates)),
            )
            .catch((error: Error) => {
                dispatch(deliverySchedulesSlice.actions.setPackagingTemplatesFailed());
                Notification.error(error.message);
            });
    };

export const fetchAndStoreLisonPackagingTemplate =
    (dunsNumber: string, deliveryScheduleId: string) => async (dispatch: Dispatch) => {
        dispatch(deliverySchedulesSlice.actions.setIsLoadingLisonPackagingTemplate(true));
        return fetchLisonPackagingTemplate(dunsNumber, deliveryScheduleId)
            .then((packagingTemplate: PackagingTemplate) =>
                dispatch(deliverySchedulesSlice.actions.updateLisonPackagingTemplate(packagingTemplate)),
            )
            .catch(() => {
                dispatch(deliverySchedulesSlice.actions.setLisonPackagingTemplateFailed());
            })
            .then(() => dispatch(fetchAndStoreDeliverySchedule(dunsNumber, deliveryScheduleId)))
            .finally(() => dispatch(deliverySchedulesSlice.actions.setIsLoadingLisonPackagingTemplate(false)));
    };

export const createPackagingTemplate =
    (
        dunsNumber: string,
        deliveryScheduleId: string,
        type: PackagingTemplateType,
        referencedDeliveryScheduleIds: string[],
        steps: PackagingStep[],
        dimensions: Dimensions,
        name: string,
    ) =>
    async (dispatch: Dispatch) => {
        const creationRequest: PackagingTemplateCreationRequest = {
            name,
            type,
            referencedDeliveryScheduleIds,
            steps,
            dimensions,
        };
        dispatch(deliverySchedulesSlice.actions.setIsSavingArticleMetadata(true));
        return await postPackagingTemplate(dunsNumber, creationRequest)
            .then((createdTemplate) =>
                dispatch(deliverySchedulesSlice.actions.replaceSelectedPackagingTemplate(createdTemplate)),
            )
            .then(() => dispatch(fetchAndStorePackagingTemplates(dunsNumber, deliveryScheduleId)))
            .then(() => {
                dispatch(deliverySchedulesSlice.actions.setIsMetadataViewContentDirty(false));
                dispatch(deliverySchedulesSlice.actions.clearSelectedDeliveryScheduleId());
                dispatch(deliverySchedulesSlice.actions.clearPackagingTemplateName());
                dispatch(deliverySchedulesSlice.actions.clearPackagingTemplateStepsAdded());
                dispatch(deliverySchedulesSlice.actions.clearPackagingTemplateDimensions());
                notifySuccess('webedi.label.success.save');
            })
            .catch((error: Error) => {
                Notification.error(error.message);
            })
            .finally(() => {
                dispatch(deliverySchedulesSlice.actions.setIsSavingArticleMetadata(false));
            });
    };

export const updatePackagingTemplate =
    (
        deliveryScheduleId: string,
        id: string,
        dunsNumber: string,
        steps: PackagingStep[],
        dimensions: Dimensions,
        name?: string,
    ) =>
    async (dispatch: Dispatch) => {
        const updateRequest: PackagingTemplateUpdateRequest = {
            name,
            steps,
            dimensions,
        };
        dispatch(deliverySchedulesSlice.actions.setIsSavingArticleMetadata(true));
        return patchPackagingTemplate(id, dunsNumber, updateRequest)
            .then(() => dispatch(fetchAndStorePackagingTemplates(dunsNumber, deliveryScheduleId)))
            .then(() => {
                dispatch(deliverySchedulesSlice.actions.setIsMetadataViewContentDirty(false));
                dispatch(deliverySchedulesSlice.actions.clearSelectedDeliveryScheduleId());
                dispatch(deliverySchedulesSlice.actions.clearPackagingTemplateName());
                dispatch(deliverySchedulesSlice.actions.clearPackagingTemplateStepsAdded());
                dispatch(deliverySchedulesSlice.actions.clearPackagingTemplateDimensions());
                notifySuccess('webedi.label.success.save');
            })
            .catch((error: Error) => {
                Notification.error(error.message);
            })
            .finally(() => {
                dispatch(deliverySchedulesSlice.actions.setIsSavingArticleMetadata(false));
            });
    };

export const deletePackagingTemplateAndRefresh =
    (id: string, dunsNumber: string, persisted: boolean, deliveryScheduleId: string) => async (dispatch: Dispatch) => {
        dispatch(deliverySchedulesSlice.actions.deletePackagingTemplate(id));

        if (persisted) {
            return deletePackagingTemplate(id, dunsNumber)
                .then(() => notifySuccess('webedi.label.success.delete'))
                .then(() => dispatch(fetchAndStorePackagingTemplates(dunsNumber, deliveryScheduleId)))
                .then(() => dispatch(fetchAndStoreDeliverySchedule(dunsNumber, deliveryScheduleId)))
                .catch((error: Error) => Notification.error(error.message));
        }
        return Promise.resolve();
    };
