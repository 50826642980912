import Checkbox from '@rio-cloud/rio-uikit/Checkbox';
import { ReactNode, useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { FormattedMessage } from 'react-intl';
import { PackagedDispatchProposalItem } from '../../../domain/dispatchProposal.types';
import { useImage } from '../../../hooks/useImage';
import { Tooltip } from '../../common/Tooltip';
import { PositiveNumberFormInputWithFeedback } from '../../common/form/PositiveNumberFormInput';
import { ManyHandlingUnitsWarning } from '../../shipments/packaging/dialogs/ManyHandlingUnitsWarning';
import { PackagedArticlesInfoTooltip } from '../../shipments/packaging/dialogs/PackagedArticlesInfoTooltip';
import { PackagedPackages } from '../../shipments/packaging/dialogs/Packaging/PackagedPackages';
import { UnpackagedPackages } from '../../shipments/packaging/dialogs/Packaging/UnpackagedPackages';
import { SplitView } from '../../shipments/packaging/dialogs/SplitView';
import { PackageTogetherFormValues, PackagedDispatchProposalItemWithAmount } from './PackageTogetherDialog';

export const AUTOFILL_QUANTITIES_BUTTON_TEST_ID = 'AUTOFILL_QUANTITIES_BUTTON_TEST_ID';

export const PackageTogetherStep3 = () => {
    const { watch, setValue } = useFormContext<PackageTogetherFormValues>();
    const [calculateNumberOfHandlingUnits, setCalculateNumberOfHandlingUnits] = useState(true);
    const selectedItemsToPackage = watch('selectedItemsToPackage');
    const amountOfHandlingUnits = watch('amountOfHandlingUnits');
    const packagingMaterial = watch('packagingMaterial');
    const boxCode = packagingMaterial?.boxCode ? packagingMaterial.boxCode : '';

    const calculatedAmountOfHandlingUnits = selectedItemsToPackage.every(
        (it) => it.amount !== undefined && it.amount > 0,
    )
        ? Math.min(
              ...selectedItemsToPackage.map((it) =>
                  Math.floor(it.dispatchProposalItem.outerPackaging.count / it.amount!),
              ),
          )
        : undefined;

    useEffect(() => {
        if (calculateNumberOfHandlingUnits && calculatedAmountOfHandlingUnits !== undefined) {
            setValue('amountOfHandlingUnits', calculatedAmountOfHandlingUnits, { shouldValidate: true });
        }
    }, [calculatedAmountOfHandlingUnits, calculateNumberOfHandlingUnits, setValue]);

    const mapPackagedDispatchProposalItemToGroupedHandlingUnit = (
        giphyWithAmount: PackagedDispatchProposalItemWithAmount,
    ) => {
        const totalAmount = giphyWithAmount.dispatchProposalItem.outerPackaging.count;
        const packagedAmount =
            giphyWithAmount.amount && amountOfHandlingUnits
                ? Math.min(giphyWithAmount.amount * amountOfHandlingUnits, totalAmount)
                : 0;
        return {
            toBePackagedAmount: totalAmount,
            packagedAmount,
            packagingMaterialBoxCode: giphyWithAmount.dispatchProposalItem.outerPackaging.type,
        };
    };

    const toggleCalculateNumberOfHandlingUnits = () =>
        setCalculateNumberOfHandlingUnits(!calculateNumberOfHandlingUnits);

    const autofillQuantities = () => {
        selectedItemsToPackage.forEach((item, index) => {
            setValue(`selectedItemsToPackage.${index}.amount`, item.dispatchProposalItem.outerPackaging.count);
        });
    };

    return (
        <SplitView
            left={{
                content: (
                    <UnpackagedPackages
                        groupedHandlingUnits={selectedItemsToPackage.map(
                            mapPackagedDispatchProposalItemToGroupedHandlingUnit,
                        )}
                    />
                ),
                alignItemsCenter: true,
            }}
            main={{
                content: (
                    <>
                        <AmountSelectionHeader
                            selectedItemsToPackage={selectedItemsToPackage}
                            boxCode={boxCode}
                            autofillQuantities={autofillQuantities}
                        />
                        <div>
                            {selectedItemsToPackage.map((it, index) => (
                                <div key={it.dispatchProposalItem.identifier.id}>
                                    <AmountSelectionItem
                                        dispatchProposalItem={it.dispatchProposalItem}
                                        itemIndex={index}
                                    />
                                    <hr className={'padding-top-10 padding-bottom-10'} />
                                </div>
                            ))}
                            <div className={'display-flex flex-row justify-content-center margin-bottom-20'}>
                                <Checkbox
                                    onChange={toggleCalculateNumberOfHandlingUnits}
                                    checked={calculateNumberOfHandlingUnits}
                                >
                                    <FormattedMessage id={'webedi.packaging.inner.calculateNumberOfHandlingUnits'} />
                                </Checkbox>
                            </div>
                            <TotalAmountInput
                                disabled={calculateNumberOfHandlingUnits}
                                dataCount={selectedItemsToPackage.length + 3}
                                maxValue={calculatedAmountOfHandlingUnits}
                                boxCode={boxCode}
                            />
                            <ManyHandlingUnitsWarning numberOfHandlingUnits={amountOfHandlingUnits} />
                        </div>
                    </>
                ),
                alignItemsCenter: true,
                renderAsColumn: true,
            }}
            right={{
                content: (
                    <PackagedPackages
                        groupedHandlingUnits={selectedItemsToPackage.map(
                            mapPackagedDispatchProposalItemToGroupedHandlingUnit,
                        )}
                        outerPackagingBoxCode={boxCode}
                    />
                ),
                alignItemsCenter: true,
            }}
        />
    );
};

const AmountSelectionHeader = (props: {
    selectedItemsToPackage: PackagedDispatchProposalItemWithAmount[];
    boxCode: string;
    autofillQuantities: () => void;
}) => {
    const { boxCode, selectedItemsToPackage, autofillQuantities } = props;
    return (
        <>
            <span className={'text-size-18 text-center margin-bottom-25'}>
                <FormattedMessage id={'webedi.packaging.homogeneousPackaging.title'} />
            </span>
            <span className={'margin-bottom-20 text-center'}>
                <FormattedMessage
                    id={'webedi.packaging.heterogeneousPackaging.explanation'}
                    values={{
                        innerPackagingMaterialBoxCodes: selectedItemsToPackage
                            .map((item) => item.dispatchProposalItem.outerPackaging.type)
                            .join(', '),
                        outerPackagingMaterial: boxCode,
                        b: (chunks: ReactNode) => <b>{chunks}</b>,
                    }}
                />
                {'.'}
                <br />
                <br />
                <div className='panel panel-default active'>
                    <div className='panel-body display-flex flex-row justify-content-between align-items-center gap-10'>
                        <div className='text-size-12 padding-bottom-5'>
                            <FormattedMessage
                                id={'webedi.packaging.heterogeneousPackaging.autofillQuantities.explanation'}
                            />
                        </div>
                        <div>
                            <button
                                type='button'
                                className='btn btn-default btn-sm'
                                onClick={autofillQuantities}
                                data-testid={AUTOFILL_QUANTITIES_BUTTON_TEST_ID}
                            >
                                <span aria-hidden='true' className='rioglyph rioglyph-flash' />
                                <span className='text-capitalize'>
                                    <FormattedMessage
                                        id={'webedi.packaging.heterogeneousPackaging.autofillQuantities.button'}
                                    />
                                </span>
                            </button>
                        </div>
                    </div>
                </div>
            </span>
        </>
    );
};

const AmountSelectionItem = (props: {
    dispatchProposalItem: PackagedDispatchProposalItem;
    itemIndex: number;
}) => {
    const { dispatchProposalItem, itemIndex } = props;
    const packagedArticlesInfo = [
        {
            articleNumberBuyer: dispatchProposalItem.articleContents[0].articleNumberBuyer,
            amount: dispatchProposalItem.articleContents[0].quantity,
            loadItemPositionReference: dispatchProposalItem.referencedDeliveryNotePositions[0],
        },
    ];
    const joinedArticleNumbers = dispatchProposalItem.articleContents
        .map((content) => content.articleNumberBuyer)
        .join(', ');

    const miscPackageImage = useImage('miscPackage');

    return (
        <div className={'display-flex flex-row align-items-center'}>
            <div className={'flex-column width-50pct text-center'}>
                <img src={miscPackageImage} className={'width-50pct height-50pct'} alt={'miscPackage'} />
            </div>
            <div className={`flex-column width-50pct counter color-highlight`}>
                <div
                    className={'display-flex flex-column align-items-baseline'}
                    data-count={(itemIndex + 3).toString()}
                >
                    <label
                        className={'text-size-14 text-color-darkest'}
                        htmlFor={`selectedItemsToPackage.${itemIndex}.amount`}
                    >
                        <FormattedMessage
                            id={'webedi.packaging.outer.numberOfPackagesPerHandlingUnit.label'}
                            values={{
                                innerHandlingUnitTypeAndDescription: `${dispatchProposalItem.outerPackaging.type}`,
                                b: (chunks: ReactNode) => <b>{chunks}</b>,
                            }}
                        />
                        &nbsp;
                        <PackagedArticlesInfoTooltip packagedArticlesInfo={packagedArticlesInfo} />
                        <div className={'text-size-12 text-color-gray ellipsis-1'}>
                            <Tooltip text={joinedArticleNumbers} placement={'bottom'}>
                                <span>
                                    <FormattedMessage id={'webedi.packaging.contents'} />
                                    {': '}
                                    {joinedArticleNumbers}
                                </span>
                            </Tooltip>
                        </div>
                    </label>
                    <PositiveNumberFormInputWithFeedback<PackageTogetherFormValues>
                        fieldName={`selectedItemsToPackage.${itemIndex}.amount`}
                        required={true}
                        decimalAllowed={false}
                        max={dispatchProposalItem.outerPackaging.count}
                        step={1}
                        getError={(errors) => errors.selectedItemsToPackage?.[itemIndex]?.amount}
                    />
                </div>
            </div>
        </div>
    );
};

const TotalAmountInput = (props: {
    disabled: boolean;
    dataCount: number;
    maxValue: number | undefined;
    boxCode: string;
}) => {
    const { disabled, dataCount, maxValue, boxCode } = props;
    const multiPackageImage = useImage('multiPackage');
    return (
        <div className={'display-flex flex-row align-items-center'}>
            <div className={'flex-column width-50pct text-center'}>
                <img src={multiPackageImage} className={'width-60pct height-60pct'} alt={'multiPackage'} />
            </div>
            <div className={`flex-column width-50pct counter color-highlight ${disabled ? 'opacity-40' : ''}`}>
                <div className={'display-flex flex-column align-items-baseline'} data-count={dataCount.toString()}>
                    <label className={'text-size-14 text-color-darkest'} htmlFor={'amountOfHandlingUnits'}>
                        <FormattedMessage
                            id={'webedi.packaging.homogeneousPackaging.numberOfHandlingUnits.label'}
                            values={{
                                b: (chunks: ReactNode) => <b>{chunks}</b>,
                                handlingUnit: boxCode,
                            }}
                        />
                    </label>
                    <PositiveNumberFormInputWithFeedback
                        fieldName={'amountOfHandlingUnits'}
                        required={true}
                        decimalAllowed={false}
                        step={1}
                        disabled={disabled}
                        max={maxValue}
                        getError={(errors) => errors.amountOfHandlingUnits}
                    />
                </div>
            </div>
        </div>
    );
};
