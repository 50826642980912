import Notification from '@rio-cloud/rio-uikit/Notification';
import { ReactElement } from 'react';
import { FormattedMessage } from 'react-intl';
import { useAppDispatch } from '../../../../../configuration/setup/typedReduxHooks';
import { localTimeZone, toUtcDateTime, toZonedDateTime } from '../../../../../dateUtils';
import { RTKQueryErrorResponse } from '../../../api/apiUtils';
import { usePostDispatchProposalMutation } from '../../../api/dispatchProposal/dispatchProposalApi';
import {
    ArticleSuggestion,
    ManualDispatchProposalDraft,
    TransportOrderStatus,
} from '../../../domain/dispatchProposal.types';
import { useDunsNumberFromPath } from '../../../hooks/Routing.hooks';
import { dispatchProposalsSlice } from '../../../reducers/dispatchProposal/DispatchProposalsReducer';
import { LoadingLocationSelector } from './LoadingLocationSelector';
import { BuyerColumn, EditableArticleNumberColumn, PlantColumn } from './table.common';

export const ManualDispatchProposalDraftItemsTable = (props: {
    draft: ManualDispatchProposalDraft;
    articleSuggestion: ArticleSuggestion;
}) => {
    const { draft, articleSuggestion } = props;
    return (
        <table className='table table-column-overflow-hidden table-head-filled table-layout-fixed border border-bottom-only'>
            <ColGroups tableConfig={tableConfiguration} />
            <TableHeader tableConfig={tableConfiguration} />
            <TableBody tableConfig={tableConfiguration} draft={draft} articleSuggestion={articleSuggestion} />
        </table>
    );
};

interface TableConfiguration {
    columns: TableColumnConfiguration[];
}

interface TableColumnConfiguration {
    name: string;
    hideLabel?: boolean;
    headerClassName?: string;
    colGroupClassName?: string;
    render: (props: {
        draft: ManualDispatchProposalDraft;
        item: ArticleSuggestion;
    }) => ReactElement;
}

const ColGroups = (props: { tableConfig: TableConfiguration }) => {
    const cols = props.tableConfig.columns.map((column) => (
        <col key={column.name} className={column.colGroupClassName} />
    ));

    return <colgroup>{cols}</colgroup>;
};

const TableHeader = (props: { tableConfig: TableConfiguration }) => {
    const headerColumns = props.tableConfig.columns.map((column: TableColumnConfiguration) => (
        <DispatchProposalItemTableHeaderColumn
            key={column.name}
            hideLabel={column.hideLabel ?? false}
            className={column.headerClassName ?? ''}
            columnId={column.name}
        />
    ));

    return (
        <thead>
            <tr>{headerColumns}</tr>
        </thead>
    );
};

const DispatchProposalItemTableHeaderColumn = (props: { columnId: string; className: string; hideLabel: boolean }) => {
    const text =
        props.columnId && !props.hideLabel ? (
            <FormattedMessage id={`webedi.dispatchProposals.overview.expander.table.header.column.${props.columnId}`} />
        ) : (
            <></>
        );
    return <th className={props.className}>{text}</th>;
};

const rowColorClassNameByTransportOrderStatus: { [Status in TransportOrderStatus]: string } = {
    // biome-ignore lint/style/useNamingConvention: Enum values are not camelCase
    ORDERED: 'bg-lightest success',
    // biome-ignore lint/style/useNamingConvention: Enum values are not camelCase
    PLANNED: 'bg-lightest info',
    // biome-ignore lint/style/useNamingConvention: Enum values are not camelCase
    UNPLANNED: '',
};

const TableBody = (props: {
    draft: ManualDispatchProposalDraft;
    tableConfig: TableConfiguration;
    articleSuggestion: ArticleSuggestion;
}) => {
    const articleSuggestion = props.articleSuggestion;
    const rowClassName = rowColorClassNameByTransportOrderStatus['UNPLANNED'];
    const columns = props.tableConfig.columns.map((column) =>
        column.render({
            draft: props.draft,
            item: articleSuggestion,
        }),
    );

    return (
        <tbody>
            <tr className={rowClassName}>{columns}</tr>
        </tbody>
    );
};

const ArticleNumberColumn = (props: {
    draft: ManualDispatchProposalDraft;
    item: ArticleSuggestion;
}) => {
    const { draft } = props;

    const dispatch = useAppDispatch();
    const [createDispatchProposal] = usePostDispatchProposalMutation();
    const dunsNumber = useDunsNumberFromPath()!;

    const onSaveArticleSuggestion = (item: ArticleSuggestion, quantity: number) => {
        // Needs to be a zoned dateTime so that in the BE we can validate the correct local date
        // Maybe in the future we will send a local date and a timezone id in 2 separate fields
        const zonedCutoffDateTime = toZonedDateTime(toUtcDateTime(draft.cutoffDate), localTimeZone());
        createDispatchProposal({
            transportConcept: draft.transportConcept,
            dunsNumber,
            id: draft.id,
            cutoffDateTime: zonedCutoffDateTime,
            referencedDeliveryScheduleId: (props.item as ArticleSuggestion).referencedDeliveryScheduleId,
            amount: { value: quantity, measurementUnitCode: item.measurementUnitCode },
        })
            .unwrap()
            .then(() => {
                Notification.success(<FormattedMessage id={'webedi.label.success.save'} />);
                dispatch(dispatchProposalsSlice.actions.removeManualDispatchProposalDraft(draft.id));
            })
            .catch((error: RTKQueryErrorResponse) => {
                if (error.data !== undefined && error.data.errorCode !== undefined) {
                    Notification.error(<FormattedMessage id={`webedi.error.${error.data.errorCode}`} />);
                } else {
                    Notification.error(<FormattedMessage id={'webedi.error.general'} />);
                }
            })
            .finally(() => {
                dispatch(dispatchProposalsSlice.actions.articleAddedToDispatchProposal());
            });
    };

    return (
        <EditableArticleNumberColumn
            item={props.item}
            dispatchProposal={undefined}
            onSaveArticleSuggestion={onSaveArticleSuggestion}
            onSaveDispatchProposalItem={() => {}}
        />
    );
};

const tableConfiguration: TableConfiguration = {
    columns: [
        {
            name: 'articleNumber',
            colGroupClassName: 'width-30pct',
            render: (props) => (
                <ArticleNumberColumn draft={props.draft} item={props.item} key={'ArticleNumberColumn'} />
            ),
        },
        { name: 'customer', render: (props) => <BuyerColumn item={props.item} key={'BuyerColumn'} /> },
        { name: 'plantUnloading', render: (props) => <PlantColumn item={props.item} key={'PlantColumn'} /> },
        {
            name: 'loading',
            render: (props) => (
                <td key={'LoadingLocationColumn'}>
                    <LoadingLocationSelector
                        item={props.item}
                        dispatchProposalId={undefined}
                        isOperationAllowed={false}
                    />
                </td>
            ),
        },
    ],
};
