import Button from '@rio-cloud/rio-uikit/Button';
import { ReactNode } from 'react';
import { FormattedMessage } from 'react-intl';
import { useAppSelector } from '../../../../../../configuration/setup/typedReduxHooks';
import {
    useDismissDispatchProposalProblemMutation,
    useGetDispatchProposalsQuery,
} from '../../../../api/dispatchProposal/dispatchProposalApi';
import {
    DispatchProposal,
    DispatchProposalFreightForwarderChangedProblem,
    DispatchProposalProblem,
    DispatchProposalProblemBase,
    FtlTermination,
    LtlTermination,
    isDispatchProposalFreightForwarderChangedProblem,
} from '../../../../domain/dispatchProposal.types';
import { TransportConcept } from '../../../../domain/meansOfTransport.types';
import { useDunsNumberFromPath } from '../../../../hooks/Routing.hooks';
import { getOverviewDateRangeISO } from '../../../../selectors/dispatchProposals/DispatchProposals.selector';
import { DispatchProposalProblemBannerFunctions, ProblemDescriptorRow } from '../DispatchProposalProblemBanner';
import { BannerIcon, HorizontalLine, colorByProblemLevel } from '../DispatchProposalProblemBannerFormatters';

const filterDispatchProposalFreightForwarderChangedProblem = (problems: DispatchProposalProblem[]) =>
    problems.filter(isDispatchProposalFreightForwarderChangedProblem);

const useMapToDispatchProposalFreightForwarderChangedProblem = (
    problemBase: DispatchProposalProblemBase[], // DPP matching affected date with selected date in UI
    dispatchProposals: DispatchProposal[], // DP matching cutoff date with selected date in UI
) => {
    const dunsNumber = useDunsNumberFromPath() ?? '';
    const dispatchProposalsDateRange = useAppSelector(getOverviewDateRangeISO);
    const { data: allDispatchProposals } = useGetDispatchProposalsQuery({
        dunsNumber,
        cutoffDateFrom: dispatchProposalsDateRange.from,
        cutoffDateTo: dispatchProposalsDateRange.to,
    });

    const problems = problemBase as DispatchProposalFreightForwarderChangedProblem[];

    if (problems.length === 0) {
        return undefined;
    }

    const alertLevel = problems[0].level;
    const rows = problems
        .map((problem) => {
            const dispatchProposal = dispatchProposals.find((dp) => dp.id === problem.dispatchProposalId);

            if (dispatchProposal === undefined) {
                if (problem.newCutoffDate === undefined) {
                    return undefined;
                } else {
                    if (allDispatchProposals === undefined) {
                        return undefined;
                    }

                    const dispatchProposalWithNewCutoffDate = allDispatchProposals.find(
                        (dp) =>
                            dp.id === problem.dispatchProposalId && dp.termination.cutoffDate === problem.newCutoffDate,
                    );

                    if (dispatchProposalWithNewCutoffDate === undefined) {
                        return undefined;
                    }
                    return createProblemDescriptorRow(problem, dispatchProposalWithNewCutoffDate);
                }
            }
            return createProblemDescriptorRow(problem, dispatchProposal);
        })
        .filter((item): item is ProblemDescriptorRow => item !== undefined);
    return {
        title: dispatchProposalFreightForwarderChangedProblemTitle(problems),
        rows,
        formatting: {
            testId: problems[0].type,
            horizontalDivider: <HorizontalLine level={alertLevel} />,
            icon: <BannerIcon level={alertLevel} />,
            buttonStyle: colorByProblemLevel(alertLevel),
        },
    };
};

const createProblemDescriptorRow = (
    problem: DispatchProposalFreightForwarderChangedProblem,
    dispatchProposal: DispatchProposal,
) => {
    return {
        id: problem.id,
        identifier: (
            <DispatchProposalFreightForwarderChangedProblemIdentifier
                problem={problem}
                dispatchProposal={dispatchProposal}
            />
        ),
        description: (
            <DispatchProposalFreightForwarderChangedProblemDescription
                problem={problem}
                dispatchProposal={dispatchProposal}
            />
        ),
        actionButton: <DismissActionButton problem={problem} />,
    } as ProblemDescriptorRow;
};

const dispatchProposalFreightForwarderChangedProblemTitle = (
    entries: DispatchProposalFreightForwarderChangedProblem[],
) => {
    if (entries.some(isCutoffDateChangedProblem)) {
        return <DispatchProposalFreightForwarderAndCutoffDateChangedProblemTitle entries={entries} />;
    }
    return <DispatchProposalFreightForwarderChangedProblemTitle entries={entries} />;
};

const DispatchProposalFreightForwarderChangedProblemTitle = (props: {
    entries: DispatchProposalFreightForwarderChangedProblem[];
}) => {
    const id =
        props.entries.length === 1
            ? 'webedi.dispatchProposals.problemBanner.dispatchProposalFreightForwarderChanged.title.one'
            : 'webedi.dispatchProposals.problemBanner.dispatchProposalFreightForwarderChanged.title.multiple';
    return (
        <strong className='text-size-16'>
            <FormattedMessage id={id} />
        </strong>
    );
};

const DispatchProposalFreightForwarderAndCutoffDateChangedProblemTitle = (props: {
    entries: DispatchProposalFreightForwarderChangedProblem[];
}) => {
    const id =
        props.entries.length === 1
            ? 'webedi.dispatchProposals.problemBanner.dispatchProposalFreightForwarderChanged.andOfCutoffDate.title.one'
            : 'webedi.dispatchProposals.problemBanner.dispatchProposalFreightForwarderChanged.andOfCutoffDate.title.multiple';
    return (
        <strong className='text-size-16'>
            <FormattedMessage id={id} />
        </strong>
    );
};

const DispatchProposalFreightForwarderChangedProblemIdentifier = (props: {
    problem: DispatchProposalFreightForwarderChangedProblem;
    dispatchProposal: DispatchProposal;
}) => {
    const plantCodes = Array.from(new Set(props.dispatchProposal.items.map((item) => item.shipTo.plantCode))).join(
        ', ',
    );
    const icon = <span className={'rioglyph rioglyph-factory text-size-18 margin-left-5 margin-right-5'} />;
    return (
        <p>
            {props.dispatchProposal.termination.transportConcept} {icon} {plantCodes}
        </p>
    );
};

const DispatchProposalFreightForwarderChangedProblemDescription = (props: {
    problem: DispatchProposalFreightForwarderChangedProblem;
    dispatchProposal: DispatchProposal;
}) => {
    const { termination } = props.dispatchProposal;
    const { transportConcept } = termination;

    if (transportConcept === TransportConcept.KEP) {
        return undefined;
    }
    if (isCutoffDateChangedProblem(props.problem)) {
        return renderDispatchProposalFreightForwarderChangedDueToNewCutoffDateProblemDescription(props.problem);
    }
    return renderDispatchProposalFreightForwarderChangedProblemDescription(termination);
};

const renderDispatchProposalFreightForwarderChangedDueToNewCutoffDateProblemDescription = (
    problem: DispatchProposalFreightForwarderChangedProblem,
) => (
    <p className={'white-space-pre-line'}>
        <FormattedMessage
            id={
                'webedi.dispatchProposals.problemBanner.dispatchProposalFreightForwarderChanged.andOfCutoffDate.description'
            }
            values={{
                newCutoffDate: problem.newCutoffDate,
                b: (chunks: ReactNode) => <b>{chunks}</b>,
            }}
        />
    </p>
);

const renderDispatchProposalFreightForwarderChangedProblemDescription = (
    termination: FtlTermination | LtlTermination,
) => (
    <p>
        <strong>
            <FormattedMessage
                id={
                    'webedi.dispatchProposals.problemBanner.dispatchProposalFreightForwarderChanged.newFreightForwarder'
                }
            />
            :
        </strong>{' '}
        {termination.freightForwarder.name}
    </p>
);

const isCutoffDateChangedProblem = (problem: DispatchProposalFreightForwarderChangedProblem) =>
    problem.newCutoffDate !== undefined;

const DismissActionButton = (props: {
    problem: DispatchProposalFreightForwarderChangedProblem;
}) => {
    const buttonStyle = colorByProblemLevel(props.problem.level);
    const label = 'webedi.dispatchProposals.problemBanner.understood';

    const [dismissDispatchProposalProblem] = useDismissDispatchProposalProblemMutation();
    const onClick = () =>
        dismissDispatchProposalProblem({
            dunsNumber: props.problem.dunsNumber,
            problemId: props.problem.id,
        });

    return (
        <Button bsStyle={buttonStyle} className={'margin-left-auto'} onClick={onClick}>
            <FormattedMessage id={label} />
        </Button>
    );
};

export const dispatchProposalFreightForwarderChangedProblemHandler: DispatchProposalProblemBannerFunctions = {
    filterForOneTypeOfProblem: filterDispatchProposalFreightForwarderChangedProblem,
    useGenerateProblemDescriptor: useMapToDispatchProposalFreightForwarderChangedProblem,
};
