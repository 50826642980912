import Checkbox from '@rio-cloud/rio-uikit/Checkbox';
import { Field, FieldRenderProps } from 'react-final-form';
import { FormattedMessage } from 'react-intl';
import { fieldNames } from '../courierOrderIntentDialog.types';

export const TermsConditionsCheckbox = () => {
    return (
        <Field name={fieldNames.termsAndConditions} type='checkbox'>
            {({ input, meta }: FieldRenderProps<boolean>) => (
                <div className={`form-group ${meta.error && 'has-error has-feedback'}`}>
                    <Checkbox
                        {...input}
                        error={meta.error && meta.touched}
                        required
                        className='display-block position-relative'
                    >
                        <FormattedMessage
                            id={'webedi.dispatchProposals.expressParcel.dialog.firstStep.termsAndConditions'}
                        />
                        {meta.error && meta.touched && (
                            <>
                                <span className='help-block' style={{ marginLeft: '-23px' }}>
                                    <FormattedMessage id={meta.error} />
                                </span>
                            </>
                        )}
                    </Checkbox>
                </div>
            )}
        </Field>
    );
};
