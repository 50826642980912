import ExpanderPanel from '@rio-cloud/rio-uikit/ExpanderPanel';
import { ReactNode } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { formatVolume, formatWeight } from '../../../../../utils';
import { MeasurementUnitCode } from '../../../domain/common.types';
import {
    HandlingUnit,
    PartialShippingParty,
    ShipmentContent,
    ShippingParty,
    TransportOrderContent,
} from '../../../domain/transportOrder.types';
import { MeasurementUnitCodeDisplay } from '../../common/MeasurementUnitCodeDisplay';
import { NoDataAvailableWrapper } from '../../common/NoDataAvailable';

export const TransportOrderSidebarPanel = (props: {
    open: boolean;
    title: string;
    children: ReactNode;
}) => {
    return (
        <ExpanderPanel
            title={<FormattedMessage id={props.title} />}
            bsStyle='default'
            className={'margin-x-20 margin-top-15'}
            open={props.open}
            bodyClassName={'padding-0'}
        >
            {props.children}
        </ExpanderPanel>
    );
};

type TransportOrderSidebarElementProps = {
    translationId: string;
    children: ReactNode;
};

const borderCSS = 'border-style-solid border-width-1 border-color-light border-right-0 border-top-0 border-left-0';
export const TransportOrderSidebarElement = ({ translationId, children }: TransportOrderSidebarElementProps) => (
    <div className={`display-flex padding-10 padding-top-5 padding-bottom-5 ${borderCSS}`}>
        <div className={'width-40pct text-color-dark margin-left-5 margin-right'}>
            <FormattedMessage id={translationId} />
        </div>
        <div className='width-60pct margin-left-5 margin-right-5'>{children}</div>
    </div>
);
export const TransportOrderSidebarAddressElement = (props: {
    title: string;
    shippingParty: ShippingParty | PartialShippingParty | undefined;
    loadingLocationId: string | undefined;
    loadingLocationTitle: string;
}) => {
    const { shippingParty } = props;
    return (
        <TransportOrderSidebarElement translationId={props.title}>
            <NoDataAvailableWrapper hasNoData={props.shippingParty?.name}>
                <span className='text-medium'>{props.shippingParty?.name}</span>
                <br />
            </NoDataAvailableWrapper>
            <NoDataAvailableWrapper hasNoData={props.shippingParty?.address}>
                <div>
                    {shippingParty?.address.street}, {shippingParty?.address.postalCode} {shippingParty?.address.city}
                </div>
            </NoDataAvailableWrapper>
            <span className={'display-flex align-items-center'}>
                <FormattedMessage id={props.loadingLocationTitle} />
                :&nbsp;
                <NoDataAvailableWrapper hasNoData={props.loadingLocationId}>
                    {props.loadingLocationId}
                </NoDataAvailableWrapper>
            </span>
        </TransportOrderSidebarElement>
    );
};

export const TransportOrderSidebarWeightAndVolumeElement = (props: {
    title: string;
    totalWeightInKg: number | undefined;
    totalVolumeInCubicMetres: number | undefined;
}) => {
    const intl = useIntl();
    return (
        <TransportOrderSidebarElement translationId={props.title}>
            <span className={'display-flex align-items-center'}>
                <span className='rioglyph rioglyph-weight text-size-18 margin-right-5' />
                <span className='margin-right-5 ellipsis-1 word-break'>
                    <NoDataAvailableWrapper hasNoData={props.totalWeightInKg}>
                        {formatWeight(intl.locale, props.totalWeightInKg)}
                    </NoDataAvailableWrapper>
                </span>
                <span className='rioglyph rioglyph-volume text-size-18 margin-left-5 margin-right-5' />
                <span className='ellipsis-1 word-break'>
                    <NoDataAvailableWrapper hasNoData={props.totalVolumeInCubicMetres}>
                        {formatVolume(intl.locale, props.totalVolumeInCubicMetres)}
                    </NoDataAvailableWrapper>
                </span>
            </span>
        </TransportOrderSidebarElement>
    );
};
export const TransportOrderSidebarContentElement = (props: {
    title: string;
    content: ShipmentContent | TransportOrderContent | undefined;
}) => {
    return (
        <TransportOrderSidebarElement translationId={props.title}>
            <NoDataAvailableWrapper hasNoData={props.content}>
                <div className='display-flex flex-row'>
                    <div className='width-50pct'>
                        <label>
                            <FormattedMessage id={'webedi.articleNumber.buyer'} />
                        </label>
                    </div>
                    <div className='width-50pct'>
                        <label>
                            <FormattedMessage id={'webedi.packaging.createPackaging'} />
                        </label>
                    </div>
                </div>
                {props.content?.handlingUnits?.map((handlingUnit, index) => {
                    const showBorder =
                        props.content && props.content?.handlingUnits!.length - 1 > index ? borderCSS : '';
                    return (
                        <HandlingUnitElement
                            handlingUnit={handlingUnit}
                            showBorder={showBorder}
                            // biome-ignore lint/suspicious/noArrayIndexKey: Fix this see RIOINBBL-1932
                            key={`handling-unit-${index}`}
                        />
                    );
                })}
            </NoDataAvailableWrapper>
        </TransportOrderSidebarElement>
    );
};

const HANDLING_UNIT = 'HANDLING_UNIT_TEST_ID';
const HandlingUnitElement = (props: { handlingUnit: HandlingUnit; showBorder: string }) => {
    return (
        <div className={`display-flex flex-row padding-top-5 ${props.showBorder}`}>
            <div className='width-50pct'>
                {props.handlingUnit.articles?.map((article, index) => {
                    return (
                        <div className='text-size-14 padding-bottom-5' key={`${article.articleNumberBuyer}-${index}`}>
                            <span className='text-medium'>{article.articleNumberBuyer}</span>
                            <span>
                                <br />
                                {article.quantity.value}{' '}
                                <MeasurementUnitCodeDisplay unit={article.quantity.measurementUnitCode} />
                            </span>
                        </div>
                    );
                })}
            </div>
            <div className='width-50pct'>
                <div>
                    <span className='rioglyph rioglyph-parcel padding-right-3' />
                    <span data-testid={HANDLING_UNIT}>
                        <span className='text-medium padding-left-3'>{props.handlingUnit.type}</span>
                        <br />
                        {props.handlingUnit.amount} <MeasurementUnitCodeDisplay unit={MeasurementUnitCode.PIECE} />
                    </span>
                </div>
            </div>
        </div>
    );
};
