import ResponsiveColumnStripe from '@rio-cloud/rio-uikit/ResponsiveColumnStripe';
import { FormattedMessage } from 'react-intl';
import { useAppDispatch, useAppSelector } from '../../../../configuration/setup/typedReduxHooks';
import { areDaysEqual, isToday, isTodayOrLater, listOfDaysStartingAt } from '../../../../dateUtils';
import {
    DispatchProposal,
    DispatchProposalProblemsCount,
    groupDispatchProposalProblemsByLevel,
} from '../../domain/dispatchProposal.types';
import { dispatchProposalsSlice } from '../../reducers/dispatchProposal/DispatchProposalsReducer';
import {
    getOverviewSelectedDate,
    getOverviewStartDate,
} from '../../selectors/dispatchProposals/DispatchProposals.selector';

import { FormattedDateOrDateTime, FormattedWeekday } from '../common/i18n/FormattedDateOrDateTime';
import { removeDispatchProposalProblemsReferencingNonExistingDispatchProposal } from './DispatchProposalsView';
import { useDispatchProposalProblemsForDay } from './dispatchProposalProblemHooks';

export interface CalendarPanelProps {
    className?: string;
    dispatchProposals: DispatchProposal[];
}

export const CalendarPanel = (props: CalendarPanelProps) => {
    const overviewSelectedDate = useAppSelector(getOverviewSelectedDate);
    const overviewStartDate = useAppSelector(getOverviewStartDate);
    const days = listOfDaysStartingAt(overviewStartDate, 21);
    const activePage = Math.floor(days.indexOf(overviewSelectedDate) / 7);

    const dispatch = useAppDispatch();

    return (
        <ResponsiveColumnStripe
            className={`bg-white ${props.className}`}
            columnsWrapperClassName='margin-0 padding-0 space-x--1'
            buttonClassName={'hover-bg-highlight-decent'}
            minColumnWith={220}
            minColumns={7}
            maxColumns={7}
            activePage={activePage}
            asType='ul'
        >
            {days.map((day) => {
                const dispatchProposals = props.dispatchProposals.filter((it) =>
                    areDaysEqual(it.termination.cutoffDate, day),
                );
                const active = areDaysEqual(overviewSelectedDate, day);
                return (
                    <div key={day} data-testid={day}>
                        <DateTile
                            date={day}
                            dispatchProposals={dispatchProposals}
                            active={active}
                            onSelect={(date) => {
                                dispatch(dispatchProposalsSlice.actions.clearTransportPlanningDraft());
                                dispatch(dispatchProposalsSlice.actions.stopAddArticleToDispatchProposalWorkflow());
                                dispatch(dispatchProposalsSlice.actions.setOverviewSelectedDate(date));
                            }}
                        />
                    </div>
                );
            })}
        </ResponsiveColumnStripe>
    );
};

interface DateTileProps {
    date: string;
    dispatchProposals: DispatchProposal[];
    active?: boolean;
    onSelect: (date: string) => void;
}

const backgroundColorForDay = (
    isActive: boolean | undefined,
    today: boolean,
    noDispatchProposal: boolean,
    problemsCount: DispatchProposalProblemsCount,
): string => {
    if (isActive) {
        if (problemsCount.error > 0) {
            return 'bg-danger';
        } else if (problemsCount.warning > 0) {
            return 'bg-warning';
        } else if (problemsCount.info > 0) {
            return 'bg-info';
        } else {
            return 'bg-primary';
        }
    } else {
        if (today) {
            return 'bg-highlight-lightest';
        } else if (noDispatchProposal) {
            return 'bg-lightest';
        }
        return '';
    }
};
const DateTile = (props: DateTileProps) => {
    const { dispatchProposals, active, date } = props;
    const today = isToday(date);
    const todayOrLater = isTodayOrLater(date);
    const noDispatchProposals = dispatchProposals.length === 0;
    const allowSelect = !active;
    const problemsForDay = useDispatchProposalProblemsForDay(date).data;
    const filteredProblemsForDay = removeDispatchProposalProblemsReferencingNonExistingDispatchProposal(
        problemsForDay,
        dispatchProposals,
    );

    const problemsCount = groupDispatchProposalProblemsByLevel(filteredProblemsForDay);
    const bgColor = backgroundColorForDay(active, today, noDispatchProposals, problemsCount);
    const dayTileTextColor = dateTextColorByProblemNumbers(problemsCount, active);
    const hoverClassName = noDispatchProposals ? 'hover-none' : '';
    const cursorClassName = allowSelect ? 'cursor-pointer' : '';

    return (
        <div
            className={`padding-15 display-flex flex-wrap line-height-20 gap-5 border border-top-none border-bottom-none 
                ${bgColor} ${hoverClassName} ${cursorClassName}`}
            onClick={() => allowSelect && props.onSelect(date)}
        >
            <DayTile date={date} textColorClassName={dayTileTextColor} />
            <DispatchProposalsStats
                dispatchProposals={dispatchProposals}
                show={todayOrLater ? 'PLANNED' : 'ORDERED'}
                active={active}
            />
        </div>
    );
};

const dateTextColorByProblemNumbers = (problemsCount: DispatchProposalProblemsCount, isActive: boolean | undefined) => {
    if (isActive && problemsCount.error + problemsCount.warning + problemsCount.info > 0) {
        return 'text-color-white';
    }
    if (problemsCount.error > 0) {
        return 'text-color-danger';
    }
    if (problemsCount.warning > 0) {
        return 'text-color-warning';
    }
    if (problemsCount.info > 0) {
        return 'text-color-info';
    }
    return '';
};

export const DayTile = (props: {
    date: string;
    textColorClassName: string;
}) => {
    return (
        <div className='flex-1-1'>
            <div>
                <FormattedWeekday date={props.date} />
            </div>
            <div className={`text-size-16 text-medium ${props.textColorClassName}`}>
                <FormattedDateOrDateTime date={props.date} />
            </div>
        </div>
    );
};

const DispatchProposalsStats = (props: {
    dispatchProposals: DispatchProposal[];
    show: 'PLANNED' | 'ORDERED';
    active?: boolean;
}) => {
    const countToDisplay = count(props.dispatchProposals, props.show);
    const textColor =
        countToDisplay > 0
            ? props.active
                ? 'text-color-white'
                : props.show === 'ORDERED'
                  ? 'text-color-success'
                  : 'text-color-dark'
            : props.active
              ? 'text-color-white'
              : 'text-color-gray';

    const iconClass = countToDisplay === 0 ? undefined : props.show === 'ORDERED' ? 'rioglyph-ok-sign' : 'rioglyph-pm';

    const icon = iconClass ? <span className={`$rioglyph ${iconClass} margin-right-5 text-size-18`} /> : undefined;

    return (
        <div className='flex-1-1'>
            <div className='display-flex flex-row justify-content-end-lg gap-10 text-size-12 text-uppercase text-medium'>
                <span className={textColor}>
                    {props.show === 'PLANNED' ? (
                        <FormattedMessage id={'webedi.dispatchProposals.overview.calendar.tile.label.planned'} />
                    ) : (
                        <FormattedMessage id={'webedi.dispatchProposals.overview.calendar.tile.label.ordered'} />
                    )}
                </span>
            </div>
            <div className='display-flex flex-row justify-content-end-lg gap-10'>
                <span className={`text-medium ${textColor}`}>
                    {icon}
                    {props.show === 'PLANNED'
                        ? countPlanned(props.dispatchProposals)
                        : countOrdered(props.dispatchProposals)}{' '}
                    / {props.dispatchProposals.length}
                </span>
            </div>
        </div>
    );
};

const count = (dispatchProposals: DispatchProposal[], show: 'PLANNED' | 'ORDERED') => {
    if (show === 'PLANNED') {
        return countPlanned(dispatchProposals);
    } else if (show === 'ORDERED') {
        return countOrdered(dispatchProposals);
    }
    return 0;
};

const countPlanned = (dispatchProposals: DispatchProposal[]) =>
    dispatchProposals.filter((dispatchProposal) => dispatchProposal.transportOrderStatus === 'PLANNED').length;

const countOrdered = (dispatchProposals: DispatchProposal[]) =>
    dispatchProposals.filter((dispatchProposal) => dispatchProposal.transportOrderStatus === 'ORDERED').length;
