import { Controller, useFormContext } from 'react-hook-form';

import { SelectOption } from '@rio-cloud/rio-uikit';
import { SelectProps } from '@rio-cloud/rio-uikit/Select';
import Select from '@rio-cloud/rio-uikit/Select';
import _ from 'lodash';

type SelectHookFormProps = Omit<SelectProps<SelectOptionWithValue>, 'onChange'> & {
    fieldName: string;
    required?: boolean;
};
export const SelectHookForm = ({ fieldName, required, ...props }: SelectHookFormProps) => {
    const {
        control,
        formState: { errors },
        watch,
    } = useFormContext();
    const error = _.get(errors, fieldName);
    const errorClassName = 'has-error has-feedback';
    const selectedValue = watch(fieldName);
    const currentlySelectedOptions = props.options?.map((option) => {
        return {
            ...option,
            selected: selectedValue === option.value,
        };
    });

    return (
        <div className={`form-group ${error ? errorClassName : ''}`}>
            <Controller
                name={fieldName}
                rules={{
                    validate: (value) => {
                        if (required && value === undefined) {
                            return 'required';
                        }
                        return true;
                    },
                }}
                control={control}
                render={({ field }) => {
                    return (
                        <Select
                            {...props}
                            id={field.name}
                            options={currentlySelectedOptions}
                            onChange={(selectedOption?: SelectOptionWithValue) => {
                                field.onChange(selectedOption?.value);
                            }}
                        />
                    );
                }}
            />
        </div>
    );
};

export type SelectOptionWithValue<T = unknown> = SelectOption & { value: T };
