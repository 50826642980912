import { SelectOption } from '@rio-cloud/rio-uikit';
import SelectUiKit from '@rio-cloud/rio-uikit/Select';
import { Field } from 'react-final-form';
import { FormattedMessage } from 'react-intl';
import { getDisplayCountries } from '../../../../../configuration/lang/selectors';
import { useAppSelector } from '../../../../../configuration/setup/typedReduxHooks';
import { formatCountryForDisplay } from '../../managedFreightForwarders/ManagedFreightForwarderForm';

export interface CountrySelectionProps {
    fieldName: string;
    warning?: any;
    formGroupClassName?: string;
    dropUp?: boolean;
}

export const CountrySelectionField = (props: CountrySelectionProps) => {
    const formInputClassName = 'form-control';
    const isoCountries = useAppSelector(getDisplayCountries);

    const feedbackClassName = 'has-feedback';
    const warningClassName = 'has-warning';
    const getInputRowClassName = () => {
        const showWarning = props.warning !== undefined;
        const showFeedback = showWarning;

        return (
            `${showFeedback ? feedbackClassName : ''} ` +
            `${showWarning ? warningClassName : ''} ` +
            `form-control-feedback-wrapper form-group ${props.formGroupClassName}`
        );
    };

    const makeCountryCodeOptions = (countries: object) => {
        return Object.entries(countries).map(
            ([key, value]) =>
                ({
                    id: key,
                    label: formatCountryForDisplay(value, key),
                }) as SelectOption,
        );
    };

    return (
        <Field className={formInputClassName} name={props.fieldName} component={'input'} type={'text'}>
            {(fieldProps) => (
                <div className={getInputRowClassName()}>
                    <SelectUiKit
                        useFilter={true}
                        dropup={props.dropUp}
                        id={props.fieldName}
                        onChange={(event?: SelectOption) => fieldProps.input.onChange(event?.id)}
                        options={makeCountryCodeOptions(isoCountries)}
                        placeholder={<FormattedMessage id={'webedi.inputPlaceholder.dropdown.standard'} />}
                        value={fieldProps.input.value ? [fieldProps.input.value] : undefined}
                    />
                    {props.warning ? (
                        <>
                            <span className='form-control-feedback rioglyph rioglyph-exclamation-sign' />
                            <span className={'help-block'}>
                                <FormattedMessage id={props.warning} />
                            </span>
                        </>
                    ) : null}
                </div>
            )}
        </Field>
    );
};
