import { SelectOption } from '@rio-cloud/rio-uikit';
import ExpanderPanel from '@rio-cloud/rio-uikit/ExpanderPanel';
import { Field } from 'react-final-form';
import { FormattedMessage, useIntl } from 'react-intl';
import { PackagingMaterial } from '../../../reducers/packaging/types';
import { Ownership, PackagingMaterialType } from '../../../reducers/shipments/packaging.types';
import { PackagingMaterialFormPropertyNames } from './BoxFinderInput';
import { NumberInput } from './NumberInput';
import { SelectInput } from './SelectInput';
import { FieldErrorType } from './types';

export const PACKAGING_MATERIAL_EXPANDABLE_TEST_ID = 'PACKAGING_MATERIAL_EXPANDABLE_TEST_ID';
export const PACKAGING_MATERIAL_EXPANDABLE_CONTENT_TEST_ID = 'PACKAGING_MATERIAL_EXPANDABLE_CONTENT_TEST_ID';
export const PACKAGING_MATERIAL_EXPANDABLE_INPUT_ERROR_BLOCK_TEST_ID =
    'PACKAGING_MATERIAL_EXPANDABLE_INPUT_ERROR_BLOCK_TEST_ID';
export const PACKAGING_MATERIAL_EXPANDABLE_INPUT_SUCCESS_BLOCK_TEST_ID =
    'PACKAGING_MATERIAL_EXPANDABLE_INPUT_SUCCESS_BLOCK_TEST_ID';
export const PACKAGING_MATERIAL_EXPANDABLE_OWNERSHIP_SELECT_TEST_ID =
    'PACKAGING_MATERIAL_EXPANDABLE_OWNERSHIP_SELECT_TEST_ID';
export const PACKAGING_MATERIAL_EXPANDABLE_IS_REUSABLE_SELECT_TEST_ID =
    'PACKAGING_MATERIAL_EXPANDABLE_IS_REUSABLE_SELECT_TEST_ID';
export const PACKAGING_MATERIAL_EXPANDABLE_TARE_WEIGHT_INPUT_TEST_ID =
    'PACKAGING_MATERIAL_EXPANDABLE_TARE_WEIGHT_INPUT_TEST_ID';
export const PACKAGING_MATERIAL_EXPANDABLE_STACK_FACTOR_INPUT_TEST_ID =
    'PACKAGING_MATERIAL_EXPANDABLE_STACK_FACTOR_INPUT_TEST_ID';

export const PackagingMaterialExpandable = (props: {
    packagingMaterial?: PackagingMaterial;
    fetched: boolean;
    prefix: string;
    disabled: boolean;
}) => {
    const intl = useIntl();
    const { packagingMaterial, fetched, disabled, prefix } = props;

    const getPackagingMaterialDimensionsFooter = () => {
        const parts: string[] = [];
        const dimensionParts: string[] = [];

        if (packagingMaterial?.outerLengthInMm) {
            dimensionParts.push(`${packagingMaterial.outerLengthInMm} mm`);
        }
        if (packagingMaterial?.outerWidthInMm) {
            dimensionParts.push(`${packagingMaterial.outerWidthInMm} mm`);
        }
        if (packagingMaterial?.outerHeightInMm) {
            dimensionParts.push(`${packagingMaterial.outerHeightInMm} mm`);
        }
        if (dimensionParts.length > 0) {
            parts.push(dimensionParts.join(' x '));
        }

        if (fetched && parts.length === 0) {
            return intl.formatMessage({ id: 'webedi.error.notAvailable' });
        }

        return parts.join(' - ');
    };

    const ownershipOptions: SelectOption[] = [
        {
            id: Ownership.SELLER,
            label: intl.formatMessage({ id: 'webedi.packaging.ownership.option.seller' }),
        },
        {
            id: Ownership.BUYER,
            label: intl.formatMessage({ id: 'webedi.packaging.ownership.option.buyer' }),
        },
    ];

    const isReusableOptions: SelectOption[] = [
        {
            id: PackagingMaterialType.DISPOSABLE,
            label: intl.formatMessage({ id: 'webedi.packaging.packagingType.option.disposable' }),
        },
        {
            id: PackagingMaterialType.REUSABLE,
            label: intl.formatMessage({ id: 'webedi.packaging.packagingType.option.reusable' }),
        },
    ];

    const responsivenessClasses = 'col-xs-12 col-sm-6 col-md-6 col-lg-6';
    const content = (
        <div data-testid={PACKAGING_MATERIAL_EXPANDABLE_CONTENT_TEST_ID}>
            <div className={'row col-12'}>
                <div
                    className={`${responsivenessClasses} padding-right-5 padding-bottom-5`}
                    data-testid={PACKAGING_MATERIAL_EXPANDABLE_OWNERSHIP_SELECT_TEST_ID}
                >
                    <span className={'text-size-12 text-color-gray'}>
                        <FormattedMessage id={'webedi.packaging.ownership'} />
                    </span>
                    <Field
                        name={`${prefix}.${PackagingMaterialFormPropertyNames.ownership}`}
                        // @ts-ignore
                        component={SelectInput}
                        options={ownershipOptions}
                        initialValue={packagingMaterial?.ownership}
                        disabled={disabled || !fetched || packagingMaterial?.name === undefined}
                    />
                </div>
                <div
                    className={`${responsivenessClasses} padding-right-5 padding-bottom-5`}
                    data-testid={PACKAGING_MATERIAL_EXPANDABLE_IS_REUSABLE_SELECT_TEST_ID}
                >
                    <span className={'text-size-12 text-color-gray '}>
                        <FormattedMessage id={'webedi.packaging.packagingType'} />
                    </span>
                    <Field
                        name={`${prefix}.${PackagingMaterialFormPropertyNames.isReusable}`}
                        // @ts-ignore
                        component={SelectInput}
                        options={isReusableOptions}
                        initialValue={packagingMaterial?.isReusable}
                        // @ts-ignore
                        format={(value) => (value ? PackagingMaterialType.REUSABLE : PackagingMaterialType.DISPOSABLE)}
                        // @ts-ignore
                        parse={(value) => value === PackagingMaterialType.REUSABLE}
                        disabled={disabled || !fetched || packagingMaterial?.name === undefined}
                    />
                </div>
                <div className={`${responsivenessClasses} padding-right-5 padding-bottom-5`}>
                    <span className={'text-size-12 text-color-gray'}>
                        <FormattedMessage id={'webedi.packaging.tareWeight'} />
                    </span>
                    <Field<number>
                        className={'form-control'}
                        id={'tareWeightInKg'}
                        name={`${prefix}.${PackagingMaterialFormPropertyNames.tareWeightInKg}`}
                        initialValue={packagingMaterial?.tareWeightInKg}
                        forbidScientificNotation
                        min={0}
                        step={0.001}
                        digitPrecision={3}
                        component={NumberInput}
                        parse={(value) => value && parseFloat(value.toString())}
                        data-testid={PACKAGING_MATERIAL_EXPANDABLE_TARE_WEIGHT_INPUT_TEST_ID}
                        disabled={disabled || !fetched || packagingMaterial?.name === undefined}
                    />
                </div>
                <div className={`${responsivenessClasses} padding-right-5`}>
                    <span className={'text-size-12 text-color-gray'}>
                        <FormattedMessage id={'webedi.packaging.stackFactor'} />
                    </span>
                    <Field<number>
                        className={'form-control'}
                        id={'stackingFactor'}
                        name={`${prefix}.${PackagingMaterialFormPropertyNames.stackingFactor}`}
                        initialValue={packagingMaterial?.stackingFactor}
                        forbidScientificNotation
                        min={1}
                        max={999}
                        component={NumberInput}
                        parse={(value) => value && parseInt(value.toString(), 10)}
                        data-testid={PACKAGING_MATERIAL_EXPANDABLE_STACK_FACTOR_INPUT_TEST_ID}
                        disabled={disabled || !fetched || packagingMaterial?.name === undefined}
                        validate={(value) => (value < 1 || value > 999) && FieldErrorType.FORBIDDEN_VALUE}
                    />
                </div>
            </div>
            <div className={'padding-top-15'}>
                <span className={'text-size-13'}>
                    <FormattedMessage id={'webedi.packaging.dimensions'} />: {getPackagingMaterialDimensionsFooter()}
                </span>
            </div>
        </div>
    );

    const renderHeader = () => {
        if (!fetched) {
            return <span className={'text-size-18 margin-right-5'} />;
        } else if (packagingMaterial?.name === undefined) {
            return (
                <span
                    className={'text-center margin-bottom-15 text-color-danger'}
                    data-testid={PACKAGING_MATERIAL_EXPANDABLE_INPUT_ERROR_BLOCK_TEST_ID}
                >
                    <div className={'text-size-18 margin-right-5 display-inline'}>
                        <span className={'rioglyph rioglyph-remove-sign'} />
                    </div>
                    <FormattedMessage id={'webedi.packagingMaterial.notFound'} />
                </span>
            );
        } else {
            return (
                <span
                    className={'text-center margin-bottom-15 text-color-success'}
                    data-testid={PACKAGING_MATERIAL_EXPANDABLE_INPUT_SUCCESS_BLOCK_TEST_ID}
                >
                    <div className={'text-size-18 margin-right-5 display-inline'}>
                        <span className={'rioglyph rioglyph-ok-sign'} />
                    </div>
                    {packagingMaterial.name}
                </span>
            );
        }
    };

    return (
        <div data-testid={PACKAGING_MATERIAL_EXPANDABLE_TEST_ID}>
            <ExpanderPanel title={renderHeader()} bsStyle='default' open={true}>
                {content}
            </ExpanderPanel>
        </div>
    );
};
