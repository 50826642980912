import Button from '@rio-cloud/rio-uikit/Button';
import Dialog from '@rio-cloud/rio-uikit/Dialog';
import { ReactNode, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { v4 as uuid } from 'uuid';
import { useAppDispatch, useAppSelector } from '../../../../configuration/setup/typedReduxHooks';
import { ManualDispatchProposalDraft } from '../../domain/dispatchProposal.types';
import { TransportConcept } from '../../domain/meansOfTransport.types';
import { PlaceOfDischargeMasterData } from '../../domain/placeOfDischargeMasterData.types';
import { dispatchProposalsSlice } from '../../reducers/dispatchProposal/DispatchProposalsReducer';
import {
    getOverviewSelectedDate,
    hasAnyActiveFilter,
} from '../../selectors/dispatchProposals/DispatchProposals.selector';
import { PlaceOfDischargeSelect } from './PlaceOfDischargeSelect';
import { PlantSelect } from './PlantSelect';

interface CreateKepDispatchProposalDialogProps {
    show: boolean;
    onClose: () => void;
    transportConcept: TransportConcept;
}

export const CreateDispatchProposalDialog = (props: CreateKepDispatchProposalDialogProps) => {
    const dispatch = useAppDispatch();
    const overviewSelectedDate = useAppSelector(getOverviewSelectedDate);
    const anyActiveFilter = useAppSelector(hasAnyActiveFilter);
    const [selectedPlaceOfDischarge, setSelectedPlaceOfDischarge] = useState<PlaceOfDischargeMasterData | undefined>(
        undefined,
    );

    let dialogTitleTranslationKey: string;
    let dialogBodyTitleTranslationKey: string;
    let dialogBodyTextTranslationKey: string;
    switch (props.transportConcept) {
        case TransportConcept.FTL:
            dialogTitleTranslationKey = 'webedi.dispatchProposals.dialog.addManualDispatchProposal.ftl.title';
            dialogBodyTitleTranslationKey =
                'webedi.dispatchProposals.dialog.addManualDispatchProposal.selectPlaceOfDischarge.title';
            dialogBodyTextTranslationKey =
                'webedi.dispatchProposals.dialog.addManualDispatchProposal.selectPlaceOfDischarge.ftl.text';
            break;
        case TransportConcept.LTL:
            dialogTitleTranslationKey = 'webedi.dispatchProposals.dialog.addManualDispatchProposal.ltl.title';
            dialogBodyTitleTranslationKey =
                'webedi.dispatchProposals.dialog.addManualDispatchProposal.selectPlant.title';
            dialogBodyTextTranslationKey =
                'webedi.dispatchProposals.dialog.addManualDispatchProposal.selectPlant.ltl.text';
            break;
        case TransportConcept.KEP:
            dialogTitleTranslationKey = 'webedi.dispatchProposals.dialog.addManualDispatchProposal.kep.title';
            dialogBodyTitleTranslationKey =
                'webedi.dispatchProposals.dialog.addManualDispatchProposal.selectPlaceOfDischarge.title';
            dialogBodyTextTranslationKey =
                'webedi.dispatchProposals.dialog.addManualDispatchProposal.selectPlaceOfDischarge.kep.text';
            break;
        default:
            throw new Error(`Unknown transport concept: ${props.transportConcept}`);
    }

    const body = (
        <div className={'display-flex flex-column text-center padding-25 min-height-400'}>
            <div className={'text-size-18 margin-bottom-25'}>
                <FormattedMessage id={dialogBodyTitleTranslationKey} />
            </div>
            <div>
                <FormattedMessage
                    id={dialogBodyTextTranslationKey}
                    values={{ b: (chunk: ReactNode) => <span className={'text-medium'}>{chunk}</span> }}
                />
            </div>
            <div className={'panel panel-default border-none padding-20 margin-y-0'}>
                {props.transportConcept === TransportConcept.LTL ? (
                    <PlantSelect onChange={setSelectedPlaceOfDischarge} />
                ) : (
                    <PlaceOfDischargeSelect
                        onChange={setSelectedPlaceOfDischarge}
                        eligibleForKep={props.transportConcept === TransportConcept.KEP ? true : undefined}
                    />
                )}
            </div>
            {anyActiveFilter && (
                <div className={'text-left padding-20'}>
                    <FormattedMessage
                        id={'webedi.dispatchProposal.create.activeFilter.hint'}
                        values={{ b: (chunks: ReactNode) => <b>{chunks}</b> }}
                    />
                </div>
            )}
        </div>
    );

    const onCreateClick = () => {
        if (selectedPlaceOfDischarge !== undefined) {
            const dispatchProposalDraft: ManualDispatchProposalDraft =
                props.transportConcept === TransportConcept.LTL
                    ? {
                          id: uuid(),
                          transportConcept: props.transportConcept,
                          cutoffDate: overviewSelectedDate,
                          plantCode: selectedPlaceOfDischarge.plantCode,
                      }
                    : {
                          id: uuid(),
                          transportConcept: props.transportConcept,
                          cutoffDate: overviewSelectedDate,
                          placeOfDischargeMasterDataId: selectedPlaceOfDischarge.id,
                          plantCode: selectedPlaceOfDischarge.plantCode,
                      };
            dispatch(dispatchProposalsSlice.actions.addManualDispatchProposalDraft(dispatchProposalDraft));
            props.onClose();
        }
    };

    const footer = (
        <div className={'display-flex flex-row align-items-center justify-content-between'}>
            <div className={'ellipsis-1'} />
            <div className={'display-flex flex-row gap-10 margin-left-10'}>
                <Button onClick={props.onClose}>
                    <FormattedMessage id={'webedi.label.cancel'} />
                </Button>
                <Button
                    bsStyle={Button.PRIMARY}
                    onClick={onCreateClick}
                    disabled={selectedPlaceOfDischarge === undefined}
                >
                    <FormattedMessage
                        id={'webedi.dispatchProposals.dialog.addManualDispatchProposal.create.button.label'}
                    />
                </Button>
            </div>
        </div>
    );

    return (
        <Dialog
            show={props.show}
            title={<FormattedMessage id={dialogTitleTranslationKey} />}
            body={body}
            bodyClassName={'padding-0 padding-top-25'}
            onClose={props.onClose}
            onEsc={props.onClose}
            footer={footer}
            showCloseButton
            useOverflow
        />
    );
};
