import Collapse from '@rio-cloud/rio-uikit/Collapse';
import { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useDiscoveryNextGenFeatures } from '../../../../configuration/featureToggle/hooks';
import { getValueOrDefaultWhenLoading } from '../../../../configuration/featureToggle/utils';
import { DeliverySchedulesFilterRow } from './DeliverySchedulesFilterRow';
import { DeliverySchedulesFilterToggleButton } from './DeliverySchedulesFilterToggleButton';
import { DeliverySchedulesSearch } from './DeliverySchedulesSearch';
import { DeliverySchedulesSelectedFilters } from './DeliverySchedulesSelectedFilters';
import { DeliverySchedulesShowDeletedCheckbox } from './DeliverySchedulesShowDeletedCheckbox';
import { DeliverySchedulesTable } from './DeliverySchedulesTable';
import { DeliveryScheduleCsvExportButton } from './csvExport/DeliveryScheduleCsvExportButton';
import { ManualCreationOfDeliveryScheduleDialog } from './deliveryScheduleCreation/ManualCreationOfDeliveryScheduleDialog';

const DELIVERY_SCHEDULE_CSV_EXPORT_BUTTON = 'DELIVERY_SCHEDULE_CSV_EXPORT_BUTTON';
export const DELIVERY_SCHEDULES_TABLE_TOOLBAR_GROUP_RIGHT = 'DELIVERY_SCHEDULES_TABLE_TOOLBAR_GROUP_RIGHT';
export const DELIVERY_SCHEDULES_TABLE_TOOLBAR_GROUP_LEFT = 'DELIVERY_SCHEDULES_TABLE_TOOLBAR_GROUP_LEFT';

export const DeliverySchedulesView = () => {
    const [showFilter, setShowFilter] = useState<boolean>(false);
    const [showCreateDialog, setShowCreateDialog] = useState<boolean>(false);
    const webSCMPlusFeaturesAreActive = getValueOrDefaultWhenLoading(useDiscoveryNextGenFeatures());

    const TableToolbarLeftButtons = (props: { webSCMPlusFeaturesAreActive: boolean }) => {
        if (props.webSCMPlusFeaturesAreActive) {
            return (
                <>
                    <div className={'table-toolbar-column'}>
                        <button type={'button'} className={'btn btn-default'} onClick={() => setShowCreateDialog(true)}>
                            <span className={'rioglyph rioglyph-plus-light'} />
                            <span>
                                <FormattedMessage id={'webedi.deliverySchedules.createManual'} />
                            </span>
                        </button>
                        <ManualCreationOfDeliveryScheduleDialog
                            showCreateDialog={showCreateDialog}
                            close={() => setShowCreateDialog(false)}
                        />
                    </div>
                    <div className={'table-toolbar-column'}>
                        <DeliverySchedulesShowDeletedCheckbox />
                    </div>
                </>
            );
        } else {
            return (
                <div className={'table-toolbar-column'}>
                    <DeliverySchedulesShowDeletedCheckbox />
                </div>
            );
        }
    };

    return (
        <>
            <div className={'table-toolbar margin-bottom-0'}>
                <div className={'table-toolbar-container'}>
                    <div
                        data-testid={DELIVERY_SCHEDULES_TABLE_TOOLBAR_GROUP_LEFT}
                        className={'table-toolbar-group-left'}
                    >
                        <TableToolbarLeftButtons webSCMPlusFeaturesAreActive={webSCMPlusFeaturesAreActive} />
                    </div>
                    <div
                        data-testid={DELIVERY_SCHEDULES_TABLE_TOOLBAR_GROUP_RIGHT}
                        className={'table-toolbar-group-right'}
                    >
                        <div className={'table-toolbar-column'}>
                            <DeliverySchedulesSelectedFilters />
                        </div>
                        <div className={'table-toolbar-column'}>
                            <DeliverySchedulesFilterToggleButton
                                active={showFilter}
                                toggleShow={() => setShowFilter((current) => !current)}
                            />
                        </div>
                        <div className={'table-toolbar-column'}>
                            <DeliverySchedulesSearch />
                        </div>
                        <div data-testid={DELIVERY_SCHEDULE_CSV_EXPORT_BUTTON} className={'table-toolbar-column'}>
                            <DeliveryScheduleCsvExportButton />
                        </div>
                    </div>
                </div>
            </div>
            <div className={'table-row margin-bottom-10'}>
                <Collapse open={showFilter} unmountOnExit={true}>
                    <div className={'panel-wrapper'}>
                        <div className={'panel panel-default'}>
                            <div className={'display-flex justify-content-end'}>
                                <button
                                    className={'btn btn-xs btn-icon-only btn-outline'}
                                    onClick={() => setShowFilter(false)}
                                >
                                    <span className={'rioglyph rioglyph-remove toggle-icon close'} />
                                </button>
                            </div>
                            <div className={'panel-body'}>
                                <DeliverySchedulesFilterRow />
                            </div>
                        </div>
                    </div>
                </Collapse>
            </div>
            <DeliverySchedulesTable />
        </>
    );
};
