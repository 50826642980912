import { TagProps } from '@rio-cloud/rio-uikit/Tag';
import Tag from '@rio-cloud/rio-uikit/Tag';
import TagList from '@rio-cloud/rio-uikit/TagList';
import { FormattedMessage } from 'react-intl';
import { useAppDispatch, useAppSelector } from '../../../../../configuration/setup/typedReduxHooks';
import { transportOrdersSlice } from '../../../reducers/transportOrders/TransportOrders.reducer';
import { getSelectedFilters } from '../../../selectors/transportOrders/transportOrders.selector';
import { SELECTED_FILTERS_TAG_LIST } from '../../deliverySchedules/DeliverySchedulesSelectedFilters';

export const TransportOrderFilterTags = () => {
    const filters = useAppSelector(getSelectedFilters);
    const dispatch = useAppDispatch();

    const tags: FilterTagProps[] = [
        {
            messageKeyId: 'webedi.transportOrders.overview.table.header.freightForwarder',
            value: filters.freightForwarderName,
            onDelete: () => {
                dispatch(transportOrdersSlice.actions.setFreightForwarderNameFilter(undefined));
            },
        },
        {
            messageKeyId: 'webedi.transportOrders.overview.table.header.transportConcept',
            value: filters.transportConcept,
            onDelete: () => {
                dispatch(transportOrdersSlice.actions.setTransportConceptFilter(undefined));
            },
        },
        {
            messageKeyId: 'webedi.transportOrders.overview.table.header.placeOfDischarge',
            value: filters.placeOfDischarge,
            onDelete: () => {
                dispatch(transportOrdersSlice.actions.setPlaceOfDischargeFilter(undefined));
            },
        },
        {
            messageKeyId: 'webedi.transportOrders.overview.table.header.buyer',
            value: filters.buyerName,
            onDelete: () => {
                dispatch(transportOrdersSlice.actions.setBuyerNameFilter(undefined));
            },
        },
        {
            messageKeyId: 'webedi.transportOrders.overview.table.header.plantNumber',
            value: filters.plantNumber,
            onDelete: () => {
                dispatch(transportOrdersSlice.actions.setPlantNumberFilter(undefined));
            },
        },
    ].filter((kv): kv is FilterTagProps => !!kv.value);

    return (
        <>
            <TagList data-testid={SELECTED_FILTERS_TAG_LIST}>
                {tags.map((tag) => (
                    <FilterTag
                        key={tag.messageKeyId}
                        messageKeyId={tag.messageKeyId}
                        value={tag.value}
                        onDelete={tag.onDelete}
                    />
                ))}
            </TagList>
        </>
    );
};

interface FilterTagProps {
    messageKeyId: string;
    value: string;
    onDelete: () => void;
}

const FilterTag = (props: FilterTagProps) => {
    const { onDelete, messageKeyId, value } = props;

    const tagProperties: TagProps = {
        deletable: true,
        onClick: onDelete,
        size: 'small',
    };
    return (
        <Tag {...tagProperties}>
            <FormattedMessage id={messageKeyId} />: {value}
        </Tag>
    );
};
