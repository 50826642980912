import Collapse from '@rio-cloud/rio-uikit/Collapse';
import SteppedProgressBar from '@rio-cloud/rio-uikit/SteppedProgressBar';
import { ReactNode, useState } from 'react';
import { FormattedDate, FormattedMessage, useIntl } from 'react-intl';
import { useNavigate } from 'react-router';
import { useAppDispatch } from '../../../../../configuration/setup/typedReduxHooks';
import { formatAddress, neverReachedFor, zeroPadNumber } from '../../../../../utils';
import { downloadEdiFile } from '../../../actions/shipments/Shipments.actions';
import { useDunsNumberFromPath } from '../../../hooks/Routing.hooks';
import { renderDetailAttributeTextCard } from '../../common/DetailAttributeCard';
import { FormattedDateOrDateTime, dateTimeFormatProps } from '../../common/i18n/FormattedDateOrDateTime';
import { FormattedManufacturingCompany } from '../../deliverySchedules/FormattedManufacturingCompany';
import { Routing } from '../../routing/Routes';
import {
    SteppedProgressBarStep,
    labelsShipmentProgressBar,
    mapNumberToStep,
    mapStepToNumber,
} from '../ShipmentSteppedProgressConfig';
import { PackagedArticles } from '../packaging/PackagedArticles';
import { DocumentGenerationInProgressInfoDialog } from '../shipmentDocuments/DocumentGenerationInProgressInfoDialog';
import { ShipmentDocuments } from '../shipmentDocuments/ShipmentDocuments';
import { DeliveryNoteReadView } from './DeliveryNoteReadView';
import { TransportContentReadView } from './TransportContentReadView';
import { ShipmentReadViewProps } from './types';

export const SHIPMENT_READ_VIEW_TEST_ID = 'SHIPMENT_READ_VIEW_TEST_ID';
export const SHIPMENT_READ_VIEW_HEADER_TEST_ID = 'SHIPMENT_READ_VIEW_HEADER_TEST_ID';
export const SHIPMENT_READ_VIEW_HEADER_DOWNLOAD_EDI_FILE_BUTTON_TEST_ID =
    'SHIPMENT_READ_VIEW_HEADER_DOWNLOAD_EDI_FILE_BUTTON_TEST_ID';
export const SHIPMENT_READ_VIEW_HEADER_COLLAPSE_BUTTON_TEST_ID = 'SHIPMENT_READ_VIEW_HEADER_COLLAPSE_BUTTON_TEST_ID';
export const SHIPMENT_READ_VIEW_HEADER_CLOSE_BUTTON_TEST_ID = 'SHIPMENT_READ_VIEW_HEADER_CLOSE_BUTTON_TEST_ID';
export const SHIPMENT_READ_STEPPED_PROGRESS_BAR_TEST_ID = 'SHIPMENT_READ_STEPPED_PROGRESS_BAR_TEST_ID';
export const EXPORTED_SHIPMENT_LABEL_TEST_ID = `EXPORTED_SHIPMENT_LABEL_TEST_ID`;
export const ShipmentExportedLabel = () => (
    <span className={'text-size-10 label label-default label-condensed margin-left-10'}>
        <FormattedMessage id={'webedi.shipment.exported'} />
    </span>
);

export const ShipmentReadView = (props: ShipmentReadViewProps) => {
    const dispatch = useAppDispatch();
    const { shipment } = props;
    const [headerExpanded, setHeaderExpanded] = useState(true);
    const navigate = useNavigate();
    const intl = useIntl();
    const dunsNumber = useDunsNumberFromPath() ?? '';
    const [selectedStep, setSelectedStep] = useState<SteppedProgressBarStep>(SteppedProgressBarStep.DELIVERY_NOTE);
    const moveToPage = (direction: 'next' | 'previous') =>
        direction === 'next'
            ? setSelectedStep(mapNumberToStep(mapStepToNumber(selectedStep) + 1))
            : setSelectedStep(mapNumberToStep(mapStepToNumber(selectedStep) - 1));

    const getPageContentBasedOnSteppedProgress = (step: SteppedProgressBarStep): ReactNode => {
        switch (step) {
            case SteppedProgressBarStep.DELIVERY_NOTE:
                return (
                    <>
                        {shipment.load.map((deliveryNote, index) => {
                            // biome-ignore lint/suspicious/noArrayIndexKey: Fix this see RIOINBBL-1932
                            return <DeliveryNoteReadView deliveryNote={deliveryNote} key={index} />;
                        })}
                    </>
                );
            case SteppedProgressBarStep.PACKAGING:
                return <PackagedArticles readOnly={true} />;
            case SteppedProgressBarStep.TRANSPORT:
                return (
                    <div>
                        <TransportContentReadView {...shipment} />
                    </div>
                );
            case SteppedProgressBarStep.SHIPMENT_DOCUMENTS:
                return <ShipmentDocuments />;
            default:
                return neverReachedFor(step);
        }
    };

    const routeBack = () => navigate(`${Routing.webScm}/${dunsNumber}${Routing.exportedShipments}`);

    const renderShipmentHeader = () => {
        return (
            <div className={'panel panel-default'} data-testid={SHIPMENT_READ_VIEW_HEADER_TEST_ID}>
                <DocumentGenerationInProgressInfoDialog />
                <div
                    className={
                        'panel-heading display-flex flex-row justify-content-between padding-right-10 padding-20'
                    }
                >
                    <div className={'display-flex align-items-center text-color-primary text-size-20'}>
                        <span className={'rioglyph rioglyph-order padding-right-10'} />
                        <span>
                            <FormattedMessage id={'webedi.shipment'} />{' '}
                            <strong>{zeroPadNumber(shipment.shipmentNumber, 8)}</strong>
                        </span>
                        {shipment.exported && shipment.exportedAt && (
                            <div data-testid={EXPORTED_SHIPMENT_LABEL_TEST_ID}>
                                <ShipmentExportedLabel />
                                <span
                                    className={
                                        'text-size-12 text-normal label label-default label-condensed border-none margin-left-5'
                                    }
                                >
                                    <FormattedMessage
                                        id={'webedi.on'}
                                        values={{
                                            date: (
                                                <FormattedDate {...dateTimeFormatProps} value={shipment.exportedAt} />
                                            ),
                                        }}
                                    />
                                </span>
                            </div>
                        )}
                    </div>
                    <div className={'pull-right btn-toolbar'}>
                        <button
                            type={'button'}
                            className={'btn btn-default'}
                            onClick={() => moveToPage('previous')}
                            disabled={selectedStep === SteppedProgressBarStep.DELIVERY_NOTE}
                        >
                            <span className={'rioglyph rioglyph rioglyph-chevron-left'} />
                            <FormattedMessage id={'webedi.back'} />
                        </button>
                        <button
                            type={'button'}
                            className={'btn btn-primary'}
                            onClick={() => moveToPage('next')}
                            disabled={selectedStep === SteppedProgressBarStep.SHIPMENT_DOCUMENTS}
                        >
                            <FormattedMessage id={'webedi.next'} />
                            <span className={'rioglyph rioglyph-chevron-right margin-left-5'} />
                        </button>
                        <button
                            type={'button'}
                            className={'btn btn-default'}
                            onClick={() => dispatch(downloadEdiFile(dunsNumber))}
                            data-testid={SHIPMENT_READ_VIEW_HEADER_DOWNLOAD_EDI_FILE_BUTTON_TEST_ID}
                        >
                            <span className={'rioglyph rioglyph-download'} />
                            <FormattedMessage id={'webedi.label.ediFile'} />
                        </button>
                        <button
                            type={'button'}
                            className={'btn btn-link btn-icon-only'}
                            onClick={() => setHeaderExpanded(!headerExpanded)}
                            data-testid={SHIPMENT_READ_VIEW_HEADER_COLLAPSE_BUTTON_TEST_ID}
                        >
                            <span className={`rioglyph rioglyph-resize-${headerExpanded ? 'small' : 'full'}`} />
                        </button>
                        <button
                            type={'button'}
                            className={'btn btn-link btn-icon-only'}
                            onClick={routeBack}
                            data-testid={SHIPMENT_READ_VIEW_HEADER_CLOSE_BUTTON_TEST_ID}
                        >
                            <span className={'rioglyph rioglyph-remove'} />
                        </button>
                    </div>
                </div>
                <Collapse open={headerExpanded}>
                    <div className={'panel-body'}>
                        <div className={'display-flex gap-10 flex-row padding-20'}>
                            {renderDetailAttributeTextCard(
                                <FormattedMessage id={'webedi.common.manufacturingCompany'} />,
                                <FormattedManufacturingCompany manufacturingCompany={shipment.buyer} />,
                            )}
                            {renderDetailAttributeTextCard(
                                <FormattedMessage id={'webedi.plantIdentifier'} />,
                                <span>{shipment.shipToId}</span>,
                            )}
                            {renderDetailAttributeTextCard(
                                <FormattedMessage id={'webedi.common.placeOfDischarge.address'} />,
                                <span>
                                    <span className={'rioglyph rioglyph-factory text-size-20 margin-right-5'} />
                                    {shipment.placeOfDischarge.address
                                        ? formatAddress(shipment.placeOfDischarge.address)
                                        : '-'}
                                </span>,
                            )}
                            {renderDetailAttributeTextCard(
                                <FormattedMessage id={'webedi.common.placeOfDischarge'} />,
                                <span>
                                    <span className={'rioglyph rioglyph-load-unload text-size-20 margin-right-5'} />
                                    {shipment.placeOfDischarge.id}
                                </span>,
                            )}
                            <div className={`flex-1-1-0`} />
                        </div>
                        <div className={'display-flex gap-10 flex-row padding-20'}>
                            {renderDetailAttributeTextCard(
                                <FormattedMessage id={'webedi.shipment.requestedDeliveryDate'} />,
                                shipment.requestedDeliveryDate ? (
                                    <FormattedDateOrDateTime date={shipment.requestedDeliveryDate} />
                                ) : undefined,
                            )}
                            {renderDetailAttributeTextCard(
                                <FormattedMessage id={'webedi.shipment.estimatedArrivalDate'} />,
                                shipment.estimatedArrivalDate ? (
                                    <FormattedDateOrDateTime date={shipment.estimatedArrivalDate} />
                                ) : undefined,
                            )}
                            {renderDetailAttributeTextCard(
                                <FormattedMessage id={'webedi.shipment.actualDespatchDate'} />,
                                shipment.despatchDate ? (
                                    <FormattedDateOrDateTime date={shipment.despatchDate} />
                                ) : undefined,
                            )}
                            <div className={'flex-1-1-0'}>
                                <div className={'display-flex flex-row'}>
                                    {renderDetailAttributeTextCard(
                                        <FormattedMessage id={'webedi.shipment.grossWeight'} />,
                                        `${shipment.grossWeightInKg.toLocaleString(intl.locale, {
                                            minimumFractionDigits: 3,
                                            maximumFractionDigits: 3,
                                        })} kg`,
                                    )}
                                </div>
                            </div>
                            <div className={'flex-1-1-0'}>
                                <div className={'display-flex flex-row'}>
                                    {renderDetailAttributeTextCard(
                                        <FormattedMessage id={'webedi.shipment.netWeight'} />,
                                        `${shipment.netWeightInKg.toLocaleString(intl.locale, {
                                            minimumFractionDigits: 3,
                                            maximumFractionDigits: 3,
                                        })} kg`,
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </Collapse>
                <div
                    data-testid={SHIPMENT_READ_STEPPED_PROGRESS_BAR_TEST_ID}
                    className={'border-style-solid border-top-only border-width-1 border-color-light margin-top--1'}
                >
                    <SteppedProgressBar
                        onSelectedChanged={(step: number) => setSelectedStep(mapNumberToStep(step))}
                        labels={labelsShipmentProgressBar()}
                        selectedStepNumber={mapStepToNumber(selectedStep)}
                        variant={'roadsign'}
                        disableFollowingPages={Object.keys(SteppedProgressBarStep).length}
                    />
                </div>
            </div>
        );
    };

    return (
        <div data-testid={SHIPMENT_READ_VIEW_TEST_ID}>
            {renderShipmentHeader()}
            {getPageContentBasedOnSteppedProgress(selectedStep)}
        </div>
    );
};
