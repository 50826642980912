import Switch from '@rio-cloud/rio-uikit/Switch';
import { ReactNode } from 'react';
import { FieldRenderProps } from 'react-final-form';
import { useIntl } from 'react-intl';

export interface SwitchInputProps extends FieldRenderProps<boolean> {
    id?: string;
    disabled?: boolean;
    children?: ReactNode;
    enabledPhraseKey?: string;
    disabledPhraseKey?: string;
}

export const SwitchInput = (props: SwitchInputProps) => {
    const { input, ...rest } = props;
    const { id, disabled, children, enabledPhraseKey, disabledPhraseKey } = rest;
    const intl = useIntl();

    if (input.checked === undefined) {
        throw new Error('when using a switch with react final form field type must be set to "checkbox"');
    }

    return (
        <Switch
            keyName={id}
            checked={input.checked}
            onChange={input.onChange}
            disabled={disabled}
            enabledText={intl.formatMessage({ id: enabledPhraseKey || 'webedi.common.on' })}
            disabledText={intl.formatMessage({ id: disabledPhraseKey || 'webedi.common.off' })}
        >
            {children}
        </Switch>
    );
};
