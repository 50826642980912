import Dialog from '@rio-cloud/rio-uikit/Dialog';
import { FormattedMessage } from 'react-intl';
import { useAppDispatch, useAppSelector } from '../../../../../configuration/setup/typedReduxHooks';
import { deliverySchedulesSlice } from '../../../reducers/deliverySchedules/DeliverySchedules.reducer';
import { PackagingStepType } from '../../../reducers/deliverySchedules/types';
import {
    getSelectedDeliverySchedule,
    getSelectedMetadataEntryId,
    getTemplateWizardState,
} from '../../../selectors/deliverySchedules/DeliverySchedules.selector';
import { ArticleNumber } from '../../common/ArticleNumber';
import { AuxiliaryPackagingConfigurationFormId } from '../../common/dialog/AuxiliaryPackaging/AuxiliaryPackagingFormConfiguration';
import { PackagingTemplateConfiguration } from './PackagingTemplateConfiguration';

export const PACKAGING_TEMPLATE_DIALOG_TEST_ID = 'PACKAGING_TEMPLATE_DIALOG_TEST_ID';
export const PACKAGING_TEMPLATE_DIALOG_BODY_TEST_ID = 'PACKAGING_TEMPLATE_DIALOG_BODY';

export const PackagingTemplateDialog = () => {
    const dispatch = useAppDispatch();

    const templateWizardState = useAppSelector(getTemplateWizardState);
    const selectedDeliverySchedule = useAppSelector(getSelectedDeliverySchedule);
    const selectedMetadataEntryId = useAppSelector(getSelectedMetadataEntryId);

    const articleNumber =
        selectedDeliverySchedule?.scheduledArticleDetails.lineItem.lineItemIdentifier.itemNumberIdentification
            .itemIdentifier;

    const step = templateWizardState?.step;
    const stepType = templateWizardState?.type;
    const formId = templateWizardState?.formId;
    const finalStep = formId === AuxiliaryPackagingConfigurationFormId.form;

    const hideDialog = () => {
        dispatch(deliverySchedulesSlice.actions.hideTemplateDialog());
        dispatch(deliverySchedulesSlice.actions.clearTemplateStepConfiguration());
        if (stepType === PackagingStepType.INNER) {
            dispatch(deliverySchedulesSlice.actions.deletePackagingTemplate(selectedMetadataEntryId!));
        }
    };

    return (
        <div data-testid={PACKAGING_TEMPLATE_DIALOG_TEST_ID}>
            <Dialog
                show={!!templateWizardState}
                title={
                    <ArticleNumber
                        articleNumber={
                            <FormattedMessage
                                id={'webedi.packaging.template.inner.dialog.header'}
                                values={{ articleNumber }}
                            />
                        }
                    />
                }
                body={
                    <div
                        className={'padding-20 overflow-y-scroll max-height-75vh'}
                        data-testid={PACKAGING_TEMPLATE_DIALOG_BODY_TEST_ID}
                    >
                        <PackagingTemplateConfiguration stepType={stepType} step={step} />
                    </div>
                }
                footer={<Footer finalStep={finalStep} onCancel={hideDialog} />}
                showCloseButton={true}
                disableEsc={true}
                onClose={hideDialog}
                bodyClassName={'padding-0'}
                bsSize={Dialog.SIZE_LG}
            />
        </div>
    );
};

interface FooterProps {
    onCancel: () => void;
    finalStep: boolean;
}

export const PACKAGING_TEMPLATE_DIALOG_FOOTER_TEST_ID = 'PACKAGING_TEMPLATE_DIALOG_FOOTER_TEST_ID';

const Footer = (props: FooterProps) => {
    const formId = useAppSelector(getTemplateWizardState)?.formId;

    return (
        <div className={'pull-right btn-toolbar'} data-testid={PACKAGING_TEMPLATE_DIALOG_FOOTER_TEST_ID}>
            <button type={'button'} className={'btn btn-default'} onClick={props.onCancel}>
                <FormattedMessage id={'webedi.label.cancel'} />
            </button>
            {props.finalStep ? (
                <button type={'submit'} className={'btn btn-primary'} form={formId}>
                    <FormattedMessage id={'webedi.label.createPackagingTemplateStep'} />
                </button>
            ) : (
                <button className={'btn btn-primary'} type={'submit'} form={formId}>
                    <FormattedMessage id={'webedi.next'} />
                </button>
            )}
        </div>
    );
};
