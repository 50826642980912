import Button from '@rio-cloud/rio-uikit/Button';
import { FormattedMessage } from 'react-intl';
import { useAppDispatch } from '../../../../../configuration/setup/typedReduxHooks';
import { DISPATCH_PROPOSALS_TAG, DISPATCH_PROPOSAL_PROBLEMS_TAG } from '../../../api/baseApi';
import { dispatchProposalApi } from '../../../api/dispatchProposal/dispatchProposalApi';
import {
    UpdateShipmentPackagingHeightRequest,
    useUpdateShipmentPackagingHeightMutation,
} from '../../../api/shipment/shipmentApi';
import {
    ArticleSuggestion,
    DispatchProposalItem,
    PackagedDispatchProposalItem,
    TransportOrderStatus,
    isDispatchProposalItem,
    isPackagedDispatchProposalItem,
} from '../../../domain/dispatchProposal.types';
import { useDunsNumberFromPath } from '../../../hooks/Routing.hooks';
import { DimensionInputComponent } from '../../common/DimensionInputComponent';
import { NoDataAvailable } from '../../common/NoDataAvailable';

const EDITABLE_PACKAGING_HEIGHT_PENCIL_TEST_ID = 'EDITABLE_PACKAGING_HEIGHT_PENCIL_TEST_ID';
const EDITABLE_PACKAGING_HEIGHT_VALUE_TEST_ID = 'EDITABLE_PACKAGING_HEIGHT_VALUE_TEST_ID';

export const PackagingHeight = (props: {
    item: DispatchProposalItem | ArticleSuggestion;
    transportOrderStatus: TransportOrderStatus;
}) => {
    const dispatch = useAppDispatch();
    const [updateHeightMutation] = useUpdateShipmentPackagingHeightMutation();
    const item = props.item;
    const dunsNumber = useDunsNumberFromPath();

    if (!isDispatchProposalItem(item) || !isPackagedDispatchProposalItem(item as DispatchProposalItem)) {
        return <NoDataAvailable />;
    }

    const alreadyOrdered = props.transportOrderStatus === 'ORDERED';
    const packagedItem = props.item as PackagedDispatchProposalItem;

    const content = (
        <Button className={'padding-left-0'} variant={'link'} disabled={alreadyOrdered}>
            {packagedItem.dimensions.heightInMm ? (
                <span>{`${packagedItem.dimensions.heightInMm} mm`}</span>
            ) : (
                <div className={'text-color-danger'}>
                    <FormattedMessage
                        id={'webedi.dispatchProposals.planDispatchProposal.body.table.missingPackagingHeight'}
                    />
                </div>
            )}
            <span
                data-testid={EDITABLE_PACKAGING_HEIGHT_PENCIL_TEST_ID}
                className={`margin-left-5 rioglyph rioglyph-pencil ${packagedItem.dimensions.heightInMm ? '' : 'text-color-danger'}`}
            />
        </Button>
    );

    if (alreadyOrdered || !dunsNumber) {
        return <>{content}</>;
    }

    const updateHeight = (newValue: number | undefined) => {
        const updateRequest: UpdateShipmentPackagingHeightRequest = {
            dunsNumber,
            giphyId: packagedItem.identifier.id,
            shipmentId: packagedItem.identifier.shipmentId,
            heightInMm: newValue!,
        };
        return updateHeightMutation(updateRequest);
    };

    const sendUpdateRequestAndHandleResult = (newValue: number | undefined) => {
        updateHeight(newValue).then((result) => {
            if (!result.hasOwnProperty('error')) {
                // used ShipmentApi is different from DispatchProposalApi
                // the automatic invalidation of tags doesn't work across multiple RTK Apis
                dispatch(
                    dispatchProposalApi.util?.invalidateTags([DISPATCH_PROPOSALS_TAG, DISPATCH_PROPOSAL_PROBLEMS_TAG]),
                );
            }
        });
    };

    return (
        <DimensionInputComponent
            value={packagedItem.dimensions.heightInMm}
            saveHandler={sendUpdateRequestAndHandleResult}
            valueTestId={EDITABLE_PACKAGING_HEIGHT_VALUE_TEST_ID}
            validationErrorTestId={'PackagingHeightErrorLabel'}
            content={content}
        />
    );
};
