import ExpanderPanel from '@rio-cloud/rio-uikit/ExpanderPanel';
import { ReactElement, useEffect } from 'react';
import { useIntl } from 'react-intl';
import { IntlShape } from 'react-intl/src/types';
import { useAppDispatch, useAppSelector } from '../../../../../configuration/setup/typedReduxHooks';
import { AuxiliaryHandlingUnitGroup } from '../../../reducers/auxiliaryPackaging.types';
import { deliverySchedulesSlice } from '../../../reducers/deliverySchedules/DeliverySchedules.reducer';
import { PackagingStepType, PackagingTemplate, PackagingTemplateType } from '../../../reducers/deliverySchedules/types';
import {
    getPackagingTemplateHandlingUnitConfigs,
    getSelectedMetadataEntry,
} from '../../../selectors/deliverySchedules/DeliverySchedules.selector';
import {
    ExpandRowIconPlaceHolder,
    HandlingUnitCategory,
    HandlingUnitCategoryTranslation,
} from '../../common/PackagingCommon';
import { Tooltip } from '../../common/Tooltip';
import { createHandlingUnitTooltipMessage } from '../../shipments/packaging/PackagedArticles';
import {
    ArticleInPackagingTemplateConfigProps,
    AuxiliaryRowProps,
    HandlingUnitInPackagingTemplateConfig,
    HandlingUnitInPackagingTemplateConfigForHomogeneousSteps,
    HandlingUnitInPackagingTemplateConfigForInnerSteps,
    HandlingUnitInPackagingTemplateConfigProps,
    PackageableInPackagingTemplateConfig,
    PackagingTemplateHandlingUnitExpanderProps,
    PackagingTemplateRowContentProps,
    isArticleInPackagingTemplateConfig,
    isHandlingUnitInPackagingTemplateConfig,
} from '../types';
import { PackagingTemplateDialog } from './PackagingTemplateDialog';
import { PackagingTemplateHeaderView } from './PackagingTemplateHeaderView';
import { PackagingTemplateLisonHeaderView } from './PackagingTemplateLisonHeaderView';

export const PACKAGING_TEMPLATES_TEST_ID = 'PACKAGING_TEMPLATES_TEST_ID';
export const PACKAGING_TEMPLATES_EXPANDER_GROUP = 'PACKAGING_TEMPLATES_EXPANDER_GROUP';
export const PACKAGING_TEMPLATES_EXPANDER_SINGLE_ITEM = 'PACKAGING_TEMPLATES_EXPANDER_SINGLE_ITEM';
export const PACKAGING_TEMPLATES_AUXILIARY_PACKAGING_ITEM = 'PACKAGING_TEMPLATES_AUXILIARY_PACKAGING_ITEM';

const EMPTY_CONTENT = '';

const RowContent = (props: PackagingTemplateRowContentProps) => (
    <>
        <span style={{ paddingLeft: props.iteration }} className={`width-30pct ${props.classNameDescriptionElement}`}>
            {props.description}
        </span>
        <span className={'width-20pct'}>
            <span className={props.classNameCategoryElement}>{props.category}</span>
            {props.categoryTooltip !== undefined ? (
                <Tooltip
                    text={props.categoryTooltip}
                    placement={'right'}
                    extraClasses={'white-space-pre-line'}
                    textAlignment={'left'}
                >
                    <span
                        className={
                            'rioglyph rioglyph-info-sign text-color-info text-size-20 padding-left-4 align-middle'
                        }
                    />
                </Tooltip>
            ) : (
                <></>
            )}
        </span>
        <span className={'width-10pct'}>{props.quantity ? props.quantity : EMPTY_CONTENT}</span>
        <span className={'width-20pct'}>{props.fourthColumn ? props.fourthColumn : EMPTY_CONTENT}</span>
        <span className={'width-20pct'}>{props.fifthColumn ? props.fifthColumn : EMPTY_CONTENT}</span>
    </>
);

const ArticleRow = (props: ArticleInPackagingTemplateConfigProps) => {
    const intl = useIntl();
    const article = props.articleHandlingUnitConfig;

    return (
        <div
            className={'display-flex padding-15 border-style-solid border-width-1 border-color-lighter border-top-only'}
        >
            <RowContent
                iteration={props.iteration * 15}
                description={
                    <span>
                        <b>{article.articleNumber}</b>
                    </span>
                }
                category={intl.formatMessage({ id: 'webedi.handlingUnit.type.article' })}
                classNameCategoryElement={'label label-primary label-condensed label-filled'}
            />
            <ExpandRowIconPlaceHolder />
        </div>
    );
};

const getFourthColumnValue = (
    handlingUnit: HandlingUnitInPackagingTemplateConfig,
    intl: IntlShape,
): string | undefined => {
    switch (handlingUnit.type) {
        case PackagingStepType.INNER:
            const quantity = (handlingUnit as HandlingUnitInPackagingTemplateConfigForInnerSteps).quantity;
            const quantityString = quantity.value.toLocaleString(intl.locale, { maximumFractionDigits: 2 });
            return `${intl.formatMessage({ id: 'webedi.packaging.template.quantity.label' })} ${quantityString}`;
        case PackagingStepType.HOMOGENEOUS:
            const numberOfLayersPerHandlingUnit = (
                handlingUnit as HandlingUnitInPackagingTemplateConfigForHomogeneousSteps
            ).numberOfLayersPerHandlingUnit;
            return `${intl.formatMessage({ id: 'webedi.packaging.template.numberOfLayers' })} ${numberOfLayersPerHandlingUnit}`;
        default:
            return undefined;
    }
};

const getFifthColumnValue = (
    handlingUnit: HandlingUnitInPackagingTemplateConfig,
    intl: IntlShape,
): string | undefined => {
    if (handlingUnit.type === PackagingStepType.HOMOGENEOUS) {
        const numberOfHandlingUnitsPerLayer = (handlingUnit as HandlingUnitInPackagingTemplateConfigForHomogeneousSteps)
            .numberOfHandlingUnitsPerLayer;
        return (
            `${intl.formatMessage({ id: 'webedi.packaging.template.amountPerLayer' })} ` +
            `${numberOfHandlingUnitsPerLayer}`
        );
    }
    return undefined;
};

const HandlingUnitRow = (props: HandlingUnitInPackagingTemplateConfigProps) => {
    const intl = useIntl();
    const { handlingUnit, iteration } = props;
    const description = handlingUnit.description ? ` ${handlingUnit.description}` : '';
    const quantity = handlingUnit.numberOfContainers
        ? `${intl.formatMessage({ id: 'webedi.packaging.template.quantity' })} ${handlingUnit.numberOfContainers.toString()}x`
        : undefined;

    return (
        <div
            className={'display-flex padding-top-10 padding-bottom-10'}
            data-testid={PACKAGING_TEMPLATES_EXPANDER_SINGLE_ITEM}
        >
            <RowContent
                description={
                    <span>
                        <b>{handlingUnit.typeOfHandlingUnit}</b>
                        {description}
                    </span>
                }
                category={intl.formatMessage({ id: HandlingUnitCategoryTranslation[handlingUnit.category] })}
                categoryTooltip={createHandlingUnitTooltipMessage(
                    intl,
                    handlingUnit.isReusable,
                    handlingUnit.ownership,
                    handlingUnit.stackingFactor,
                    handlingUnit.tareWeightInKg,
                )}
                quantity={quantity}
                fourthColumn={getFourthColumnValue(handlingUnit, intl)}
                fifthColumn={getFifthColumnValue(handlingUnit, intl)}
                classNameCategoryElement={'label label-default label-condensed label-filled'}
                classNameDescriptionElement={`padding-left-${(iteration * 10).toString()}`}
                iteration={iteration}
            />
        </div>
    );
};

const PackagingTemplateHandlingUnitExpander = (props: PackagingTemplateHandlingUnitExpanderProps) => {
    const { handlingUnitConfig, iteration, index } = props;

    return (
        <div data-testid={PACKAGING_TEMPLATES_EXPANDER_GROUP}>
            <ExpanderPanel
                className={`margin-top-2 margin-bottom-2'}`}
                title={<HandlingUnitRow handlingUnit={handlingUnitConfig} iteration={iteration} />}
                bsStyle={'blank'}
                titleClassName={'width-100pct height-100pct text-color-dark'}
                bodyClassName={'padding-0'}
                key={`${index}`}
                open={!props.collapsed}
            >
                {renderAuxiliaryPackagingContent(handlingUnitConfig, iteration)}
                {renderMainHandlingUnitContent(handlingUnitConfig, iteration, props.collapsed)}
            </ExpanderPanel>
        </div>
    );
};

const AuxiliaryPackagingRow = (props: AuxiliaryRowProps) => {
    const intl = useIntl();
    const auxiliaryHandlingUnitGroupContent = props.auxiliaryHandlingUnitGroup.auxiliaryPackagingContent;
    const quantity = props.auxiliaryHandlingUnitGroup.quantity
        ? `${intl.formatMessage({ id: 'webedi.packaging.template.quantity' })} ${props.auxiliaryHandlingUnitGroup.quantity.toString()}x`
        : undefined;
    return (
        <div
            className={'display-flex padding-15 text-color-dark border-color-lighter'}
            data-testid={PACKAGING_TEMPLATES_AUXILIARY_PACKAGING_ITEM}
            key={props.iteration}
        >
            <RowContent
                description={
                    <span>
                        <b>{props.auxiliaryHandlingUnitGroup.auxiliaryPackagingContent.type}</b>{' '}
                        {props.auxiliaryHandlingUnitGroup.auxiliaryPackagingContent.description}
                    </span>
                }
                category={intl.formatMessage({
                    id: HandlingUnitCategoryTranslation[HandlingUnitCategory.PACKAGING_AID],
                })}
                categoryTooltip={createHandlingUnitTooltipMessage(
                    intl,
                    auxiliaryHandlingUnitGroupContent.isReusable,
                    auxiliaryHandlingUnitGroupContent.ownership,
                    auxiliaryHandlingUnitGroupContent.stackingFactor,
                    auxiliaryHandlingUnitGroupContent.tareWeightInKg,
                )}
                quantity={quantity}
                iteration={props.iteration}
                classNameCategoryElement={'label label-default label-condensed label-filled'}
                classNameDescriptionElement={`padding-left-${(props.iteration * 10).toString()}`}
            />
            <ExpandRowIconPlaceHolder />
        </div>
    );
};

const renderAuxiliaryPackagingContent = (
    handlingUnitConfig: HandlingUnitInPackagingTemplateConfig,
    iteration: number,
): ReactElement => {
    return (
        <div>
            {handlingUnitConfig.auxiliaryPackaging.map(
                (auxiliaryPackagingHandlingUnitGroup: AuxiliaryHandlingUnitGroup, contentIndex: number) => (
                    <div
                        className={`margin-top-2 margin-bottom-2 border-style-solid border-width-1 border-color-lighter border-top-only`}
                        // biome-ignore lint/suspicious/noArrayIndexKey: Fix this see RIOINBBL-1932
                        key={contentIndex}
                    >
                        <AuxiliaryPackagingRow
                            auxiliaryHandlingUnitGroup={auxiliaryPackagingHandlingUnitGroup}
                            iteration={iteration}
                        />
                    </div>
                ),
            )}
        </div>
    );
};

const handlingUnitContent = (
    handlingUnitConfig: PackageableInPackagingTemplateConfig,
    index: number,
    iteration: number,
    collapsed: boolean,
): ReactElement | undefined => {
    const currentIteration = iteration + 1;
    if (isArticleInPackagingTemplateConfig(handlingUnitConfig)) {
        return <ArticleRow articleHandlingUnitConfig={handlingUnitConfig} iteration={currentIteration} key={index} />;
    } else if (isHandlingUnitInPackagingTemplateConfig(handlingUnitConfig)) {
        return (
            <PackagingTemplateHandlingUnitExpander
                handlingUnitConfig={handlingUnitConfig}
                index={index}
                key={index}
                iteration={currentIteration}
                collapsed={collapsed}
            />
        );
    }
    return undefined;
};

const renderMainHandlingUnitContent = (
    handlingUnitConfig: HandlingUnitInPackagingTemplateConfig,
    iteration: number,
    collapsed: boolean,
): ReactElement => {
    return (
        <div className={'position-relative'}>
            <span
                className={`position-absolute top-0 left-0 bottom-0 width-${(iteration + 1) * 15} margin-left--1 margin-bottom--1 bg-lighter`}
            />
            {handlingUnitConfig.contents.map((unit: PackageableInPackagingTemplateConfig, contentIndex: number) =>
                handlingUnitContent(unit, contentIndex, iteration, collapsed),
            )}
        </div>
    );
};

export const PackagingTemplateView = (props: { collapsed: boolean }) => {
    const dispatch = useAppDispatch();
    const intl = useIntl();
    const selectedPackagingTemplate = useAppSelector(getSelectedMetadataEntry) as PackagingTemplate;
    const packagingTemplateHandlingUnitConfigs = useAppSelector(getPackagingTemplateHandlingUnitConfigs);

    useEffect(() => {
        if (!(selectedPackagingTemplate.steps.length > 0)) {
            dispatch(deliverySchedulesSlice.actions.showTemplateDialog(PackagingStepType.INNER));
        }
    }, [selectedPackagingTemplate, dispatch]);

    const PackagingTemplateHierarchyView = () => {
        return (
            <div data-testid={PACKAGING_TEMPLATES_TEST_ID} className={'panel panel-default margin-20'}>
                <div className={'position-sticky z-index-1'} style={{ top: '-28px' }}>
                    {selectedPackagingTemplate.type === PackagingTemplateType.LISON ? (
                        <PackagingTemplateLisonHeaderView />
                    ) : (
                        <PackagingTemplateHeaderView />
                    )}
                    <div className={'panel-heading display-flex bg-lightest padding-15'}>
                        <RowContent
                            description={intl.formatMessage({ id: 'webedi.packaging.template.header.description' })}
                            category={intl.formatMessage({ id: 'webedi.packaging.template.header.category' })}
                            iteration={0}
                        />
                    </div>
                </div>
                {packagingTemplateHandlingUnitConfigs?.map((handlingUnit, index) => (
                    <PackagingTemplateHandlingUnitExpander
                        handlingUnitConfig={handlingUnit}
                        // biome-ignore lint/suspicious/noArrayIndexKey: Fix this see RIOINBBL-1932
                        key={index}
                        index={index}
                        iteration={0}
                        collapsed={props.collapsed}
                    />
                ))}
            </div>
        );
    };
    return (
        <>
            <PackagingTemplateHierarchyView />
            <PackagingTemplateDialog />
        </>
    );
};

PackagingTemplateView.defaultProps = {
    collapsed: false,
};
