import ClearableInputUiKit from '@rio-cloud/rio-uikit/ClearableInput';
import { debounce } from 'lodash';
import { KeyboardEvent, memo, useCallback } from 'react';
import { useIntl } from 'react-intl';
import { useAppDispatch, useAppSelector } from '../../../../configuration/setup/typedReduxHooks';
import { fetchAndStoreDeliverySchedules } from '../../actions/deliverySchedules/DeliverySchedules.actions';
import { deliverySchedulesSlice } from '../../reducers/deliverySchedules/DeliverySchedules.reducer';
import { getDeliverySchedulesQuery } from '../../selectors/deliverySchedules/DeliverySchedules.selector';

export const DELIVERY_SCHEDULES_SEARCH_TEST_ID = 'DELIVERY_SCHEDULES_SEARCH_TEST_ID';

export const DELIVERY_SCHEDULES_SEARCH_DEBOUNCE_TIME = 750;

export const DeliverySchedulesSearch = () => {
    const intl = useIntl();
    const dispatch = useAppDispatch();
    const query = useAppSelector(getDeliverySchedulesQuery);

    const debouncedSearch = useCallback(
        debounce((searchFieldInput: string) => {
            dispatch(fetchAndStoreDeliverySchedules({ offset: 0, q: searchFieldInput }));
        }, DELIVERY_SCHEDULES_SEARCH_DEBOUNCE_TIME),
        [],
    );

    const onChangeHandler = (newValue: string) => {
        dispatch(deliverySchedulesSlice.actions.updateQuery({ ...query, params: { ...query.params, q: newValue } }));
        if (newValue === '') {
            dispatch(fetchAndStoreDeliverySchedules({ offset: 0, q: newValue }));
        } else {
            debouncedSearch(newValue);
        }
    };

    const onKeyPress = useCallback(
        (e: KeyboardEvent<HTMLInputElement>) => e.key === 'Enter' && debouncedSearch.flush(),
        [debouncedSearch],
    );

    return (
        <div className={'input-group width-250'}>
            <span className={'input-group-addon'}>
                <span className={'rioglyph rioglyph-search'} aria-hidden={'true'} />
            </span>
            <MemoizedClearableInput
                data-testid={DELIVERY_SCHEDULES_SEARCH_TEST_ID}
                placeholder={intl.formatMessage({ id: 'webedi.deliverySchedule.searchBar.articleNumber' })}
                onChange={(newValue: string) => {
                    onChangeHandler(newValue);
                }}
                onKeyPress={onKeyPress}
                value={query.params.q}
            />
        </div>
    );
};

const MemoizedClearableInput = memo(ClearableInputUiKit);
