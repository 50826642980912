import ToggleButton from '@rio-cloud/rio-uikit/ToggleButton';
import { FormattedMessage } from 'react-intl';
import { State } from '../../../../configuration/setup/store';
import { useAppSelector } from '../../../../configuration/setup/typedReduxHooks';
import { getShipmentSuggestionsActiveFilters } from '../../selectors/suggestions/ShipmentSuggestions.selector';

export const TOGGLE_FILTERS_PANEL_BUTTON = 'TOGGLE_FILTERS_PANEL_BUTTON';
export const TOGGLE_FILTERS_PANEL_ICON = 'TOGGLE_FILTERS_PANEL_ICON';

interface ShipmentSuggestionsFilterToggleButtonProps {
    active: boolean;
    toggleShow: () => void;
}

export const ShipmentSuggestionsFilterToggleButton = (props: ShipmentSuggestionsFilterToggleButtonProps) => {
    const { active, toggleShow } = props;

    const filterParams = useAppSelector((state: State) => getShipmentSuggestionsActiveFilters(state));
    const activeFilterCount = Object.values(filterParams).filter((it) => it !== undefined).length;

    return (
        <ToggleButton
            data-testid={TOGGLE_FILTERS_PANEL_BUTTON}
            active={active}
            bsStyle={'default'}
            onClick={() => toggleShow()}
        >
            <span
                data-testid={TOGGLE_FILTERS_PANEL_ICON}
                className={activeFilterCount > 0 ? 'rioglyph rioglyph-filter-active' : 'rioglyph rioglyph-filter'}
            />
            <FormattedMessage id={'webedi.common.filter'} />
        </ToggleButton>
    );
};
