import ForbiddenState from '@rio-cloud/rio-uikit/ForbiddenState';
import { isEmpty } from 'lodash';
import { useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import { useAppDispatch, useAppSelector } from '../../../../../configuration/setup/typedReduxHooks';
import { roundOffFloatingPointErrors } from '../../../../../utils';
import { fetchAndStoreCumulativeQuantitySentOffsets } from '../../../actions/deliverySchedules/DeliverySchedules.actions';
import { useDunsNumberFromPath } from '../../../hooks/Routing.hooks';
import { deliverySchedulesSlice } from '../../../reducers/deliverySchedules/DeliverySchedules.reducer';
import { CumulativeQuantitySentOffset, DeliverySchedule } from '../../../reducers/deliverySchedules/types';
import {
    getCumulativeQuantitySentOffsets,
    getisLoadingCumulativeQuantitySentOffsets,
} from '../../../selectors/deliverySchedules/DeliverySchedules.selector';
import { LoadingIndicator } from '../../common/LoadingIndicator';
import { MeasurementUnitCodeDisplay } from '../../common/MeasurementUnitCodeDisplay';
import { FormattedDateOrDateTime } from '../../common/i18n/FormattedDateOrDateTime';

export interface CumulativeQuantitySentOffsetsProps {
    deliverySchedule: DeliverySchedule;
}

export const CUMULATIVE_QUANTITY_SENT_OFFSETS_TABLE_TEST_ID = 'CUMULATIVE_QUANTITY_SENT_OFFSETS_TABLE_TEST_ID';
export const CUMULATIVE_QUANTITIY_SENT_OFFSET_MEASUREMENT_UNIT_CODE_TEST_ID =
    'CUMULATIVE_QUANTITIY_SENT_OFFSET_MEASUREMENT_UNIT_CODE_TEST_ID';

export const CumulativeQuantitySentOffsets = (props: CumulativeQuantitySentOffsetsProps) => {
    const dispatch = useAppDispatch();
    const dunsNumber = useDunsNumberFromPath()!;
    const offsets = useAppSelector(getCumulativeQuantitySentOffsets);
    const isLoading = useAppSelector(getisLoadingCumulativeQuantitySentOffsets);

    // biome-ignore lint/correctness/useExhaustiveDependencies: Migrated from eslint
    useEffect(() => {
        if (isEmpty(offsets)) {
            dispatch(fetchAndStoreCumulativeQuantitySentOffsets(dunsNumber, props.deliverySchedule.id));
        }
        return () => {
            dispatch(deliverySchedulesSlice.actions.clearSelectedDeliveryScheduleCumulativeQuantitySentOffsets());
        };
    }, []);

    const renderRow = (offset: CumulativeQuantitySentOffset) => (
        <tr key={`${offset.dateTime}-${offset.newQuantity}`}>
            <td className={'padding-left-25'}>
                <FormattedDateOrDateTime date={offset.dateTime} />
            </td>
            <td>{offset.previousQuantity}</td>
            <td>{offset.newQuantity}</td>
            <td>{roundOffFloatingPointErrors(offset.newQuantity - offset.previousQuantity)}</td>
            <td className={'padding-right-25'}>{offset.creator}</td>
        </tr>
    );

    return (
        <div data-testid={CUMULATIVE_QUANTITY_SENT_OFFSETS_TABLE_TEST_ID}>
            <div className={'padding-10 bg-white'}>
                <div className={'display-flex flex-row'}>
                    <div className={'padding-left-15 h4 text-light'}>
                        <span>
                            <FormattedMessage id={'webedi.deliverySchedule.cumulativeQuantitySentOffsets.title'} />
                        </span>{' '}
                        <span data-testid={CUMULATIVE_QUANTITIY_SENT_OFFSET_MEASUREMENT_UNIT_CODE_TEST_ID}>
                            <MeasurementUnitCodeDisplay
                                unit={props.deliverySchedule.scheduledArticleDetails.measurementUnitCode}
                            />
                        </span>
                    </div>
                </div>
            </div>
            <div
                className={
                    'overflow-y-auto max-height-350-xs max-height-350-sm max-height-350-lg max-height-500-xl height-50vh'
                }
            >
                <table className={'table table-column-overflow-hidden table-sticky table-head-filled'}>
                    <thead>
                        <tr>
                            <th className={'position-sticky top-0 padding-left-25'}>
                                <FormattedMessage
                                    id={'webedi.deliverySchedule.cumulativeQuantitySentOffsets.lastChange'}
                                />
                            </th>
                            <th className={'position-sticky top-0'}>
                                <FormattedMessage
                                    id={'webedi.deliverySchedule.cumulativeQuantitySentOffsets.oldValue'}
                                />
                            </th>
                            <th className={'position-sticky top-0'}>
                                <FormattedMessage
                                    id={'webedi.deliverySchedule.cumulativeQuantitySentOffsets.updatedValue'}
                                />
                            </th>
                            <th className={'position-sticky top-0'}>
                                <FormattedMessage
                                    id={'webedi.deliverySchedule.cumulativeQuantitySentOffsets.difference'}
                                />
                            </th>
                            <th className={'position-sticky top-0 padding-right-25'}>
                                <FormattedMessage
                                    id={'webedi.deliverySchedule.cumulativeQuantitySentOffsets.changedBy'}
                                />
                            </th>
                        </tr>
                    </thead>
                    <tbody>{offsets.map(renderRow)}</tbody>
                </table>
                {isLoading && <LoadingIndicator />}
                {!isLoading && offsets.length === 0 && (
                    <ForbiddenState
                        headline={
                            <FormattedMessage id={'webedi.deliverySchedule.cumulativeQuantitySentOffsets.empty'} />
                        }
                    />
                )}
            </div>
        </div>
    );
};
