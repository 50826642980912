import Notification from '@rio-cloud/rio-uikit/Notification';
import { saveAs } from 'file-saver';
import { Dispatch } from '../../../../configuration/setup/store';
import { fetchEdiFileDownloadUrl, fetchEdiFileSummaries, fetchFileContentAsBlob } from '../../api/file/ediFilesCalls';
import { EdiFileSummaryOld, ReferenceType } from '../../domain/files.types';

export const testIfShipmentFileSummaryIsAvailable = async (dunsNumber: string, shipmentId: string): Promise<void> => {
    return fetchEdiFileSummaries(dunsNumber, { referenceId: shipmentId, referenceType: ReferenceType.SHIPMENT }).then(
        (ediFileSummaries: EdiFileSummaryOld[]) => {
            if (ediFileSummaries.length === 0) {
                throw new Error('ASN file not available');
            }
        },
    );
};

export const downloadFileContent = (dunsNumber: string, id: string, filename: string) => (dispatch: Dispatch) =>
    fetchEdiFileDownloadUrl(dunsNumber, id)
        .then((url) => dispatch(storeUrlAsFileBlob(url, filename)))
        .catch((err) => Notification.error(err.message));

export const storeUrlAsFileBlob = (url: string, filename: string) => async () => {
    return fetchFileContentAsBlob(url).then((blob) => saveAs(blob, filename));
};
