import ConfirmationDialog from '@rio-cloud/rio-uikit/ConfirmationDialog';
import { ReactElement } from 'react';
import { FormattedMessage } from 'react-intl';

export interface DeletionConfirmationDialogProps {
    show: boolean;
    onClickConfirm: () => void;
    onClickCancel: () => void;
    content?: ReactElement;
}

export const DELETION_CONFIRMATION_DIALOG_TEST_ID = 'DELETION_CONFIRMATION_DIALOG_TEST_ID';

export const DeletionConfirmationDialog = (props: DeletionConfirmationDialogProps) => {
    const { show, onClickConfirm, onClickCancel, content } = props;
    return (
        <ConfirmationDialog
            data-testid={DELETION_CONFIRMATION_DIALOG_TEST_ID}
            show={show}
            title={<FormattedMessage id={'webedi.deletionConfirmationDialog.title'} />}
            content={
                content !== undefined ? (
                    content
                ) : (
                    <FormattedMessage id={'webedi.deletionConfirmationDialog.description'} />
                )
            }
            onClickConfirm={onClickConfirm}
            onClickCancel={onClickCancel}
            cancelButtonText={<FormattedMessage id={'webedi.label.cancel'} />}
            confirmButtonText={<FormattedMessage id={'webedi.deletionConfirmationDialog.title'} />}
            useOverflow={false}
            bsSize={'sm'}
        />
    );
};
