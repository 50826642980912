import OverlayTrigger from '@rio-cloud/rio-uikit/OverlayTrigger';
import Tooltip from '@rio-cloud/rio-uikit/Tooltip';
import { ItemShortDescription as ItemShortDescriptionType } from '../../reducers/types';

export const ItemShortDescription = ({
    itemShortDescriptions,
}: { itemShortDescriptions: ItemShortDescriptionType[] }) => {
    const description =
        itemShortDescriptions.length && itemShortDescriptions[0].itemDescription ? (
            <span>{itemShortDescriptions[0].itemDescription}</span>
        ) : null;

    if (description === null) {
        return null;
    }

    const tooltip = <Tooltip>{description}</Tooltip>;

    return (
        <OverlayTrigger placement={'bottom'} overlay={tooltip}>
            {description}
        </OverlayTrigger>
    );
};
