import Dialog from '@rio-cloud/rio-uikit/Dialog';
import Notification from '@rio-cloud/rio-uikit/Notification';
import isEmpty from 'lodash/fp/isEmpty';
import { useState } from 'react';
import { Field, Form } from 'react-final-form';
import { FormattedMessage } from 'react-intl';
import { RTKQueryErrorResponse } from '../../api/apiUtils';
import {
    useDeleteLoadingLocationMutation,
    usePutLoadingLocationMutation,
} from '../../api/loadingLocation/loadingLocationApi';
import { useDunsNumberFromPath } from '../../hooks/Routing.hooks';
import { LoadingLocation } from '../../reducers/loadingLocations/types';
import { DeletionConfirmationDialog } from '../common/dialog/DeletionConfirmationDialog';
import { CountrySelectionField } from '../common/form/CountrySelectionField';
import { TextInput } from '../common/form/TextInput';
import { FieldErrorType } from '../common/form/types';
import { LoadingLocationFormValues } from './LoadingLocationForm.types';
import { useLoadingLocationsQuery } from './LoadingLocationHooks';
import { LoadingLocationUpsertErrorBox } from './LoadingLocationUpsertErrorBox';

export const SHIPPING_LOCATION_FORM_TEST_ID = 'SHIPPING_LOCATION_FORM_TEST_ID';

export const dataTestIds = {
    name: 'SHIPPING_LOCATIONS_NAME_INPUT',
    loadingPoint: 'SHIPPING_LOCATIONS_LOADING_POINT_INPUT',
    companyName: 'SHIPPING_LOCATIONS_COMPANY_NAME_INPUT',
    street: 'SHIPPING_LOCATIONS_STREET_INPUT',
    postalCode: 'SHIPPING_LOCATIONS_POSTAL_CODE_INPUT',
    city: 'SHIPPING_LOCATIONS_CITY_INPUT',
    country: 'SHIPPING_LOCATIONS_COUNTRY_INPUT',
    comment: 'SHIPPING_LOCATIONS_COMMENT_INPUT',
};

const shippingLocationFormId = 'SHIPPING_LOCATION_FORM_ID';

export const mapLoadingLocationFormValuesToLoadingLocation = (
    values: LoadingLocationFormValues,
    dunsNumber: string,
    locationToEditId: string,
): LoadingLocation => {
    return {
        id: locationToEditId,
        dunsNumberOwner: values.dunsNumberOwner || dunsNumber,
        name: values.name,
        companyName: values.companyName,
        loadingPoint: values.loadingPoint,
        street: values.street,
        postalCode: values.postalCode,
        city: values.city,
        country: values.country,
        comment: values.comment,
        etag: values.etag,
    };
};

export type ShippingLocationFormProps = {
    show: boolean;
    hideDialog: () => void;
    locationToEditId: string;
};

export const LoadingLocationForm = (props: ShippingLocationFormProps) => {
    const loadingLocations = useLoadingLocationsQuery().data ?? [];
    const loadingLocationToEdit = loadingLocations.find((item) => item.id === props.locationToEditId);

    const [putLoadingLocation] = usePutLoadingLocationMutation();
    const [deleteLoadingLocation] = useDeleteLoadingLocationMutation();

    const dunsNumber = useDunsNumberFromPath();
    const [showDeletionConfirmation, setShowDeletionConfirmation] = useState(false);
    const [upsertErrorCode, setUpsertErrorCode] = useState<string | undefined>(undefined);
    if (dunsNumber === undefined) {
        return null;
    }
    const hideDialog = () => {
        setUpsertErrorCode(undefined);
        props.hideDialog();
    };

    const onSubmit = (values: LoadingLocationFormValues) => {
        const locationId = props.locationToEditId;
        const loadingLocation = mapLoadingLocationFormValuesToLoadingLocation(values, dunsNumber, locationId);
        putLoadingLocation(loadingLocation)
            .unwrap()
            .then(() => {
                Notification.success(<FormattedMessage id={'webedi.label.success.save'} />);
                hideDialog();
            })
            .catch((error: RTKQueryErrorResponse) => {
                Notification.error(<FormattedMessage id={'webedi.error.general'} />);
                setUpsertErrorCode(error.data.errorCode);
            });
    };

    const getHighestExistingLoadingId = (): number => {
        return Math.max(...loadingLocations.map((it) => Number(it.loadingPoint)).filter((it) => !isNaN(it)), 0);
    };

    const InfoBox = () => {
        return (
            <div className='alert alert-dismissible alert-info margin-bottom-20'>
                <div className='display-flex gap-10'>
                    <span className='text-color-primary text-size-h4 rioglyph rioglyph-info-sign' />
                    <div>
                        <strong className='text-size-16'>
                            <FormattedMessage id={'webedi.loadingLocation.hint.title'} />
                        </strong>
                        <div className='margin-y-5'>
                            <FormattedMessage id={'webedi.loadingLocation.hint.text'} />
                        </div>
                    </div>
                </div>
            </div>
        );
    };

    const renderBody = () => {
        const rowClassName =
            'display-flex flex-row align-items-center text-color-dark padding-10 width-100pct form-group margin-bottom-10';
        const formInputClassName = 'width-70pct height-30';
        const initialValues: LoadingLocationFormValues = loadingLocationToEdit || {
            name: '',
            loadingPoint: `${getHighestExistingLoadingId() + 1}`,
            companyName: '',
            street: '',
            postalCode: '',
            city: '',
            country: '',
            comment: '',
        };
        return (
            <Form<LoadingLocationFormValues>
                initialValues={initialValues}
                onSubmit={onSubmit}
                // biome-ignore lint/correctness/noUnusedVariables: Parameter is required by final-form
                render={({ handleSubmit, values, errors, touched }) => {
                    const renderTextField = (params: {
                        fieldName: keyof LoadingLocationFormValues;
                        labelId: string;
                        label: string;
                        required: boolean;
                        additionalValidation?: (value: string | undefined) => FieldErrorType | undefined;
                        testId: string;
                    }) => {
                        const validateValue = (
                            value: string | undefined,
                            // biome-ignore lint/correctness/noUnusedVariables: Parameter is required by final-form
                            allValues: unknown,
                            meta: { name: string } | undefined,
                        ): string | undefined => {
                            if (params.required && isEmpty(value)) {
                                return meta
                                    ? `${FieldErrorType.REQUIRED}.loadingPoint.${meta.name}`
                                    : FieldErrorType.REQUIRED;
                            }
                            return params.additionalValidation?.(value);
                        };

                        return (
                            <div className={rowClassName}>
                                <label className={'width-30pct text-size-14'} htmlFor={params.labelId}>
                                    <FormattedMessage id={params.label} />
                                    {params.required ? '*' : ''}
                                </label>
                                <div className={formInputClassName}>
                                    <Field
                                        className={'form-control'}
                                        id={params.labelId}
                                        data-testid={params.testId}
                                        name={params.fieldName}
                                        component={TextInput}
                                        type={'text'}
                                        validate={validateValue}
                                    />
                                </div>
                            </div>
                        );
                    };

                    const validateLoadingPointId = (value: string | undefined): FieldErrorType | undefined => {
                        const otherLoadingLocations = loadingLocations.filter(
                            (it) => it.id !== loadingLocationToEdit?.id,
                        );
                        const othersWithSameLoadingPlaceId = otherLoadingLocations.find(
                            (it) => it.loadingPoint.toLowerCase() === value?.toLowerCase(),
                        );
                        return othersWithSameLoadingPlaceId && FieldErrorType.LOADING_POINT_ID_ALREADY_EXISTS;
                    };

                    return (
                        <form
                            id={shippingLocationFormId}
                            onSubmit={handleSubmit}
                            data-testid={SHIPPING_LOCATION_FORM_TEST_ID}
                            className={'form-horizontal'}
                        >
                            <InfoBox />
                            <div className={'display-flex flex-column'}>
                                <LoadingLocationUpsertErrorBox errorCode={upsertErrorCode} />
                                {renderTextField({
                                    fieldName: 'name',
                                    labelId: 'input_name',
                                    label: 'webedi.loadingLocation.name',
                                    required: true,
                                    testId: dataTestIds.name,
                                })}
                                {renderTextField({
                                    fieldName: 'loadingPoint',
                                    labelId: 'input_loadingPoint',
                                    label: 'webedi.loadingLocation.loadingLocationId',
                                    required: true,
                                    additionalValidation: validateLoadingPointId,
                                    testId: dataTestIds.loadingPoint,
                                })}
                                {renderTextField({
                                    fieldName: 'companyName',
                                    labelId: 'input_companyName',
                                    label: 'webedi.loadingLocation.companyName',
                                    required: true,
                                    testId: dataTestIds.companyName,
                                })}
                                {renderTextField({
                                    fieldName: 'street',
                                    labelId: 'input_street',
                                    label: 'webedi.loadingLocation.street',
                                    required: true,
                                    testId: dataTestIds.street,
                                })}
                                {renderTextField({
                                    fieldName: 'postalCode',
                                    labelId: 'input_postalCode',
                                    label: 'webedi.loadingLocation.postalCode',
                                    required: true,
                                    testId: dataTestIds.postalCode,
                                })}
                                {renderTextField({
                                    fieldName: 'city',
                                    labelId: 'input_city',
                                    label: 'webedi.loadingLocation.city',
                                    required: true,
                                    testId: dataTestIds.city,
                                })}
                                <div className={rowClassName}>
                                    <label
                                        className={'width-30pct text-size-14'}
                                        htmlFor={'country'}
                                        data-testid={dataTestIds.country}
                                    >
                                        <FormattedMessage id={'webedi.loadingLocation.country'} />*
                                    </label>
                                    <CountrySelectionField
                                        formGroupClassName={'margin-bottom-10'}
                                        fieldName={'country'}
                                        dropUp={true}
                                    />
                                </div>
                                {renderTextField({
                                    fieldName: 'comment',
                                    labelId: 'input_comment',
                                    label: 'webedi.loadingLocation.comment',
                                    required: false,
                                    testId: dataTestIds.comment,
                                })}
                                <p
                                    className={
                                        'pull-left text-color-gray padding-10 margin-top-10 text-size-10 margin-bottom-10'
                                    }
                                >
                                    <FormattedMessage id={'webedi.hint.requiredFields'} />
                                </p>
                            </div>
                        </form>
                    );
                }}
            />
        );
    };

    const onConfirmDeletion = () => {
        loadingLocationToEdit &&
            deleteLoadingLocation({ dunsNumber, loadingLocationId: loadingLocationToEdit.id })
                .unwrap()
                .then(() => {
                    setShowDeletionConfirmation(false);
                    hideDialog();
                    Notification.success(<FormattedMessage id={'webedi.label.success.delete'} />);
                })

                .catch(() => {
                    Notification.error(<FormattedMessage id={'webedi.error.general'} />);
                });
    };

    const renderFooter = () => (
        <div>
            {loadingLocationToEdit && (
                <>
                    <div className={'btn-toolbar pull-left'}>
                        <button
                            className={'btn-danger btn btn-default btn-link'}
                            onClick={() => setShowDeletionConfirmation(true)}
                        >
                            <span className={'rioglyph rioglyph-trash'} />
                            <FormattedMessage id={'webedi.label.delete'} />
                        </button>
                    </div>
                    <DeletionConfirmationDialog
                        content={<FormattedMessage id={'webedi.loadingLocation.deletion.confirmation.info'} />}
                        show={showDeletionConfirmation}
                        onClickConfirm={onConfirmDeletion}
                        onClickCancel={() => setShowDeletionConfirmation(false)}
                    />
                </>
            )}
            <div className={'btn-toolbar pull-right'}>
                <button className={'CancelButton btn btn-default'} onClick={hideDialog}>
                    <FormattedMessage id={'webedi.label.cancel'} />
                </button>
                <button className={'ConfirmationButton btn btn-primary'} type={'submit'} form={shippingLocationFormId}>
                    <FormattedMessage id={'webedi.label.save'} />
                </button>
            </div>
        </div>
    );

    const renderTitle = () => {
        return loadingLocationToEdit === undefined ? (
            <FormattedMessage id={'webedi.loadingLocation.form.title.create'} />
        ) : (
            <FormattedMessage id={'webedi.loadingLocation.form.title.edit'} />
        );
    };

    return (
        <Dialog
            show={props.show}
            title={renderTitle()}
            body={renderBody()}
            footer={renderFooter()}
            useOverflow={true}
            onHide={hideDialog}
            bodyClassName={'padding-bottom-0'}
        />
    );
};
