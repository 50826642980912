import EmptyState from '@rio-cloud/rio-uikit/EmptyState';
import { FormattedMessage } from 'react-intl';
import { useAppSelector } from '../../../../../../../configuration/setup/typedReduxHooks';
import { GroupedHandlingUnits } from '../../../../../reducers/shipments/packaging.types';
import { getSelectedGroupedHandlingUnits } from '../../../../../selectors/packaging/Packaging.selector';
import { HeterogeneousPackagingConfiguration } from './HeterogeneousPackagingConfiguration';
import { HomogeneousPackagingConfiguration } from './HomogeneousPackagingConfiguration';

export const TEST_IDS = {
    emptySelection: 'PACKAGING_LAYER_CONFIGURATION_EMPTY_SELECTION_TEST_ID',
    mixedSelection: 'PACKAGING_LAYER_CONFIGURATION_MIXED_SELECTION_TEST_ID',
};

export const PackagingLayerConfiguration = () => {
    const groupedHandlingUnitsToPack: GroupedHandlingUnits[] = useAppSelector(getSelectedGroupedHandlingUnits);

    if (groupedHandlingUnitsToPack.length === 0) {
        return (
            <div data-testid={TEST_IDS.emptySelection}>
                <EmptyState
                    headline={<FormattedMessage id={'webedi.packaging.layerConfiguration.emptySelection.headline'} />}
                    outerClassName={'border-none bg-transparent'}
                    message={<FormattedMessage id={'webedi.packaging.layerConfiguration.emptySelection.message'} />}
                />
            </div>
        );
    } else if (groupedHandlingUnitsToPack.length > 1) {
        return <HeterogeneousPackagingConfiguration />;
    } else {
        return <HomogeneousPackagingConfiguration />;
    }
};
