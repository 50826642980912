import ClearableInput from '@rio-cloud/rio-uikit/ClearableInput';
import Multiselect from '@rio-cloud/rio-uikit/Multiselect';
import NotFoundState from '@rio-cloud/rio-uikit/NotFoundState';
import { sortBy, uniqBy } from 'lodash';
import { ReactNode, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useAppDispatch, useAppSelector } from '../../../../configuration/setup/typedReduxHooks';
import {
    useGetArticleSuggestionsForDraftQuery,
    useGetArticleSuggestionsQuery,
} from '../../api/dispatchProposal/dispatchProposalApi';
import { ArticleSuggestion, DispatchProposal, ManualDispatchProposalDraft } from '../../domain/dispatchProposal.types';
import { TransportConcept } from '../../domain/meansOfTransport.types';
import { useDunsNumberFromPath } from '../../hooks/Routing.hooks';
import { dispatchProposalsSlice } from '../../reducers/dispatchProposal/DispatchProposalsReducer';
import { hasAnyActiveFilter } from '../../selectors/dispatchProposals/DispatchProposals.selector';
import { ArticleNumber } from '../common/ArticleNumber';
import { ItemShortDescription } from '../common/ItemShortDescription';
import { LoadingIndicator } from '../common/LoadingIndicator';
import { ArticleSuggestionWarningIcon } from '../common/articleSuggestions/ArticleSuggestionWarningIcon';

const ARTICLE_SUGGESTIONS_SIDEBAR_TEST_ID = 'ARTICLE_SUGGESTIONS_SIDEBAR_TEST_ID';

interface ArticleSuggestionsForDispatchProposalProps {
    dispatchProposal: DispatchProposal;
}

export const ArticleSuggestionsForDispatchProposal = (props: ArticleSuggestionsForDispatchProposalProps) => {
    const dunsNumber = useDunsNumberFromPath();
    const { dispatchProposal } = props;
    const { data, isLoading } = useGetArticleSuggestionsQuery({
        dunsNumber: dunsNumber!,
        id: dispatchProposal.id,
    });

    return (
        <ArticleSuggestionsTable
            data={data}
            isLoading={isLoading}
            showPlantCodeFilter={dispatchProposal.termination.transportConcept === TransportConcept.LTL}
            defaultPlantCodes={dispatchProposal.items.map((it) => it.shipTo.plantCode)}
            filterActiveHint={
                <FormattedMessage
                    id={'webedi.dispatchProposal.addArticle.activeFilter.hint'}
                    values={{ b: (chunks: ReactNode) => <b>{chunks}</b> }}
                />
            }
        />
    );
};

interface ArticleSuggestionsForDispatchProposalDraftProps {
    draft: ManualDispatchProposalDraft;
}

export const ArticleSuggestionsForDispatchProposalDraft = (props: ArticleSuggestionsForDispatchProposalDraftProps) => {
    const dunsNumber = useDunsNumberFromPath();
    const { draft } = props;
    const transportConcept = draft.transportConcept;
    const { data, isLoading } = useGetArticleSuggestionsForDraftQuery({
        dunsNumber: dunsNumber!,
        placeOfDischargeId: transportConcept === TransportConcept.LTL ? undefined : draft.placeOfDischargeMasterDataId,
        eligibleForKep: transportConcept === TransportConcept.KEP ? true : undefined,
    });

    return (
        <ArticleSuggestionsTable
            data={data}
            isLoading={isLoading}
            showPlantCodeFilter={draft.transportConcept === TransportConcept.LTL}
            defaultPlantCodes={[draft.plantCode]}
            filterActiveHint={
                <FormattedMessage
                    id={'webedi.dispatchProposal.create.activeFilter.hint'}
                    values={{ b: (chunks: ReactNode) => <b>{chunks}</b> }}
                />
            }
        />
    );
};

interface ArticleSuggestionsTableProps {
    data: ArticleSuggestion[] | undefined;
    isLoading: boolean;
    showPlantCodeFilter: boolean;
    defaultPlantCodes: string[];
    filterActiveHint: ReactNode;
}

const ArticleSuggestionsTable = (props: ArticleSuggestionsTableProps) => {
    const { data, isLoading } = props;
    const suggestions = data || [];

    const [searchValue, setSearchValue] = useState<string>('');
    const [selectedPlantCodes, setSelectedPlantCodes] = useState<string[]>(props.defaultPlantCodes);
    const intl = useIntl();
    const anyActiveFilter = useAppSelector(hasAnyActiveFilter);

    if (isLoading) {
        return <LoadingIndicator />;
    }

    const allOptions = suggestions.map((it) => ({
        /**
         * We use the plant code as id, even though it is not unique (like plant 40).
         * The consequence is that articles for multiple buyers might appear in the list.
         */
        id: it.shipTo.plantCode,
        label: `${it.buyer.name} (${it.shipTo.plantCode})`,
        selected: selectedPlantCodes.includes(it.shipTo.plantCode) ?? false,
    }));
    const uniqueOptions = uniqBy(allOptions, (it) => it.id);
    const options = sortBy(uniqueOptions, (it) => it.label);

    const getSearchableStrings = (suggestion: ArticleSuggestion) =>
        suggestion.itemShortDescriptions.map((item) => item.itemDescription).concat([suggestion.articleNumberBuyer]);

    const caseInsensitiveSearch = (value: string) => value.toLowerCase().indexOf(searchValue.toLowerCase()) > -1;
    const filteredValues = suggestions
        .filter((it) => selectedPlantCodes.length === 0 || selectedPlantCodes.includes(it.shipTo.plantCode))
        .filter((it) => getSearchableStrings(it).some((value) => value && caseInsensitiveSearch(value)));

    return (
        <div data-testid={ARTICLE_SUGGESTIONS_SIDEBAR_TEST_ID}>
            <div className={'margin-left-20 margin-right-20 margin-top-10 margin-bottom-10'}>
                {props.showPlantCodeFilter && (
                    <div className={'form-group'}>
                        <div className={'input-group z-index-5'}>
                            <span className={'input-group-addon'}>
                                <span className={'rioglyph rioglyph-factory'} aria-hidden={'true'} />
                            </span>
                            <Multiselect
                                placeholder={intl.formatMessage({ id: 'webedi.select.plantCode' })}
                                options={options}
                                useFilter={true}
                                onChange={(plantCodes: string[]) => setSelectedPlantCodes(plantCodes)}
                                className={'form-control-feedback-wrapper multiselect-with-input-addon'}
                            />
                        </div>
                    </div>
                )}
                <div className={'form-group'}>
                    <div className={'input-group'}>
                        <span className={'input-group-addon'}>
                            <span className={'rioglyph rioglyph-search'} aria-hidden={'true'} />
                        </span>
                        <ClearableInput
                            placeholder={intl.formatMessage({ id: 'webedi.deliverySchedule.searchBar.articleNumber' })}
                            id={'search-phrase'}
                            value={searchValue}
                            onChange={(value: string) => setSearchValue(value)}
                        />
                    </div>
                </div>
                {anyActiveFilter && props.filterActiveHint}
            </div>
            {filteredValues.length > 0 ? (
                <DispatchProposalArticleSuggestionsTable items={filteredValues} />
            ) : (
                <NotFoundState
                    outerClassName={'border-none'}
                    headline={<FormattedMessage id={'webedi.nothingFound'} />}
                    message={<FormattedMessage id={'webedi.common.search.noResult'} />}
                />
            )}
        </div>
    );
};

interface DispatchProposalArticleSuggestionsTableProps {
    items: ArticleSuggestion[];
}

const DispatchProposalArticleSuggestionsTable = (props: DispatchProposalArticleSuggestionsTableProps) => (
    <div className={'table-responsive'}>
        <table className={'table table-column-overflow-hidden table-head-filled margin-0'}>
            <thead>
                <tr>
                    <th className={'text-center'} />
                    <th>
                        <FormattedMessage id={'webedi.articleNumber.buyer'} />
                    </th>
                    <th>
                        <FormattedMessage id={'webedi.itemShortDescription'} />
                    </th>
                    <th>
                        <FormattedMessage
                            id={'webedi.dispatchProposals.overview.expander.table.header.column.plantUnloading'}
                        />
                    </th>
                    <th />
                </tr>
            </thead>
            <tbody>
                {props.items.map((articleSuggestion: ArticleSuggestion) => (
                    <ArticleSuggestionRow
                        key={articleSuggestion.referencedDeliveryScheduleId}
                        articleSuggestion={articleSuggestion}
                    />
                ))}
            </tbody>
        </table>
    </div>
);

interface ArticleSuggestionRowProps {
    articleSuggestion: ArticleSuggestion;
}

const ArticleSuggestionRow = (props: ArticleSuggestionRowProps) => {
    const dispatch = useAppDispatch();

    const { articleSuggestion } = props;
    const hasWarnings = !articleSuggestion.hasValidArticleMasterData;
    return (
        <tr
            className={`${hasWarnings ? 'bg-lightest text-color-gray warning' : ''}`}
            key={articleSuggestion.articleNumberBuyer}
        >
            <td>
                <ArticleSuggestionWarningIcon
                    hasInvalidMasterData={!articleSuggestion.hasValidArticleMasterData}
                    hasInvalidCumulativeQuantitySent={false}
                />
            </td>
            <td className={'padding-left-20'}>
                <ArticleNumber articleNumber={articleSuggestion.articleNumberBuyer} />
            </td>
            <td>
                <ItemShortDescription itemShortDescriptions={articleSuggestion.itemShortDescriptions} />
            </td>
            <td>
                {articleSuggestion.shipTo.plantCode} / {articleSuggestion.shipTo.placeOfDischarge}
            </td>
            <td className={'padding-right-20'}>
                <button
                    type={'button'}
                    className={`btn btn-link btn-icon-only ${hasWarnings ? 'disabled' : ''}`}
                    disabled={hasWarnings}
                    onClick={() =>
                        dispatch(dispatchProposalsSlice.actions.articleSuggestionSelected(articleSuggestion))
                    }
                >
                    <span className={'rioglyph rioglyph-plus text-size-14'} />
                </button>
            </td>
        </tr>
    );
};
