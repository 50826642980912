import NotFoundState from '@rio-cloud/rio-uikit/NotFoundState';
import { useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import { useNavigate } from 'react-router';
import { getDisplayCountries } from '../../../../configuration/lang/selectors';
import { useAppSelector } from '../../../../configuration/setup/typedReduxHooks';
import { LoadingLocation } from '../../reducers/loadingLocations/types';
import { getSelectedShippingLocationId } from '../../selectors/masterData/MasterData.selector';
import { LoadingIndicator } from '../common/LoadingIndicator';
import { useLoadingLocationsQuery } from './LoadingLocationHooks';

export const SHIPPING_LOCATION_TABLE_TEST_ID = 'SHIPPING_LOCATION_TABLE_TEST_ID';
const tableClassNames = [
    'table',
    'table-column-overflow-hidden',
    'table-bordered',
    'table-sticky',
    'table-head-filled',
    'text-left',
    'cursor-pointer',
    'margin-bottom-20',
].join(' ');

type LoadingLocationsTableProps = {
    onRowClick?: (loadingLocationId: string) => void;
};

export const LoadingLocationsTable = (props: LoadingLocationsTableProps) => {
    const navigate = useNavigate();
    const { data, isLoading, error } = useLoadingLocationsQuery();
    const loadingLocations = data ?? [];
    const selectedShippingLocationId = useAppSelector(getSelectedShippingLocationId);
    const displayCountries = useAppSelector(getDisplayCountries);

    useEffect(() => {
        if (error && 'status' in error && error.status === 403) {
            navigate('/forbidden');
        }
    }, [error, navigate]);

    const renderRow = (loadingLocation: LoadingLocation) => {
        const onRowClick = props.onRowClick ?? (() => {});
        const shippingLocationId = loadingLocation.id;
        const isSelected = shippingLocationId === selectedShippingLocationId;

        return (
            <tr
                className={isSelected ? 'active' : undefined}
                onClick={() => onRowClick(shippingLocationId)}
                key={shippingLocationId}
            >
                <td>{loadingLocation.name}</td>
                <td>{loadingLocation.loadingPoint}</td>
                <td>{loadingLocation.companyName}</td>
                <td>{loadingLocation.street}</td>
                <td>{loadingLocation.postalCode}</td>
                <td>{loadingLocation.city}</td>
                <td>{`${displayCountries[loadingLocation.country]} (${loadingLocation.country})`}</td>
                <td className={'ellipsis-1 max-width-150'}>{loadingLocation.comment}</td>
            </tr>
        );
    };

    if (isLoading) {
        return <LoadingIndicator />;
    }

    if (loadingLocations.length === 0) {
        return <NotFoundState headline={<FormattedMessage id={'webedi.nothingFound'} />} message={''} />;
    }

    return (
        <table className={tableClassNames} data-testid={SHIPPING_LOCATION_TABLE_TEST_ID}>
            <thead>
                <tr>
                    <th>
                        <FormattedMessage id={'webedi.loadingLocation.name'} />
                    </th>
                    <th>
                        <FormattedMessage id={'webedi.loadingLocation.loadingLocationId'} />
                    </th>
                    <th>
                        <FormattedMessage id={'webedi.loadingLocation.companyName'} />
                    </th>
                    <th>
                        <FormattedMessage id={'webedi.loadingLocation.street'} />
                    </th>
                    <th>
                        <FormattedMessage id={'webedi.loadingLocation.postalCode'} />
                    </th>
                    <th>
                        <FormattedMessage id={'webedi.loadingLocation.city'} />
                    </th>
                    <th>
                        <FormattedMessage id={'webedi.loadingLocation.country'} />
                    </th>
                    <th>
                        <FormattedMessage id={'webedi.loadingLocation.comment'} />
                    </th>
                </tr>
            </thead>
            <tbody>{loadingLocations.map(renderRow)}</tbody>
        </table>
    );
};
