import Spinner from '@rio-cloud/rio-uikit/Spinner';
import { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useAppSelector } from '../../../../../configuration/setup/typedReduxHooks';
import { DeliveryScheduleWorkflow } from '../../../reducers/deliverySchedules/types';
import { getIsPostingCumulativeQuantitySentOffset } from '../../../selectors/deliverySchedules/DeliverySchedules.selector';
import { DeliveryScheduleDetailsProps } from '../types';
import { CumulativeQuantitySentOffsetForm } from './CumulativeQuantitySentOffsetForm';
import { CumulativeQuantitySentOffsets } from './CumulativeQuantitySentOffsets';
import { DNGDeliveryScheduleDetailsHeaderSection } from './DNGDeliveryScheduleDetailsHeaderSection';
import { DeliveryScheduleDetailsHeaderSection } from './DeliveryScheduleDetailsHeaderSection';
import { DeliveryScheduleDetailsTable } from './DeliveryScheduleDetailsTable';
import { SideInformationContent } from './sideInformation/SideInformationContent';

export const DELIVERY_SCHEDULES_DETAILS_TEST_ID = 'DELIVERY_SCHEDULES_DETAILS_TEST_ID';
export const CUMULATIVE_QUANTITY_SENT_OFFSETS_TEST_ID = 'CUMULATIVE_QUANTITY_SENT_OFFSETS_TEST_ID';

export const DeliveryScheduleDetails = (props: DeliveryScheduleDetailsProps) => {
    const [showCumulativeOffsetTable, setShowCumulativeOffsetTable] = useState<boolean>(false);

    const isPostingCumulativeQuantityOffset = useAppSelector(getIsPostingCumulativeQuantitySentOffset);

    if (props.deliverySchedule === undefined) {
        return null;
    }

    const isWebSCMClassicWorkflow = props.deliverySchedule!.workflow === DeliveryScheduleWorkflow.WEBSCM_CLASSIC;
    const renderMainContent = () => (
        <div data-testid={DELIVERY_SCHEDULES_DETAILS_TEST_ID} className={'width-75pct'}>
            {isWebSCMClassicWorkflow ? (
                <DeliveryScheduleDetailsHeaderSection
                    deliverySchedule={props.deliverySchedule!}
                    setShowCumulativeOffsetTable={setShowCumulativeOffsetTable}
                />
            ) : (
                <DNGDeliveryScheduleDetailsHeaderSection deliverySchedule={props.deliverySchedule!} />
            )}

            <div className={'overflow-y-auto height-50vh'}>
                <DeliveryScheduleDetailsTable
                    schedulingData={props.deliverySchedule!.scheduledArticleDetails.schedulingData}
                    hasZeroDemand={props.deliverySchedule!.hasZeroDemand}
                    workflow={props.deliverySchedule!.workflow}
                />
            </div>
        </div>
    );

    const renderCumulativeQuantitySentContent = () => (
        <div
            data-testid={CUMULATIVE_QUANTITY_SENT_OFFSETS_TEST_ID}
            className={'width-75pct bg-lightest display-flex flex-column justify-content-between'}
        >
            <Spinner
                show={isPostingCumulativeQuantityOffset}
                text={<FormattedMessage id={'webedi.saving'} />}
                isFullSized={true}
            />
            <CumulativeQuantitySentOffsets deliverySchedule={props.deliverySchedule!} />
            <CumulativeQuantitySentOffsetForm
                deliverySchedule={props.deliverySchedule!}
                setShowCumulativeOffsetTable={setShowCumulativeOffsetTable}
            />
        </div>
    );

    return (
        <div className={'display-flex flex-row margin--20'}>
            {showCumulativeOffsetTable ? renderCumulativeQuantitySentContent() : renderMainContent()}
            <div className={'bg-lighter padding-left-1'} />
            <SideInformationContent {...props.deliverySchedule} />
        </div>
    );
};
