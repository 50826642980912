import Spinner from '@rio-cloud/rio-uikit/Spinner';
import { FormApi } from 'final-form';
import arrayMutators from 'final-form-arrays';
import { ReactNode, useEffect, useState } from 'react';
import { DragDropContext, DropResult } from 'react-beautiful-dnd';
import { Form } from 'react-final-form';
import { FormattedMessage } from 'react-intl';
import { useAppDispatch, useAppSelector } from '../../../../../configuration/setup/typedReduxHooks';
import { neverReachedFor, wouldChangesToShipmentInvalidatePackaging } from '../../../../../utils';
import { getAndStoreManagedFreightForwarders } from '../../../actions/masterData/MasterData.actions';
import { updateShipment } from '../../../actions/shipments/Shipments.actions';
import { useDunsNumberFromPath } from '../../../hooks/Routing.hooks';
import { packagingSlice } from '../../../reducers/shipments/Packaging.reducer';
import { shipmentsSlice } from '../../../reducers/shipments/Shipments.reducer';
import { SavingShipmentStatus, Shipment } from '../../../reducers/shipments/types';
import { getSavingShipmentStatus } from '../../../selectors/shipments/Shipments.selector';
import { ErrorBox } from '../../common/ErrorBox';
import { SteppedProgressBarStep } from '../ShipmentSteppedProgressConfig';
import { PackagingDialog } from '../packaging/dialogs/PackagingDialog';
import { PackagesContentEditView } from '../packaging/packagingEditView/PackagesContentEditView';
import { ShipmentDocuments } from '../shipmentDocuments/ShipmentDocuments';
import { AdditionalPropertiesDialog } from './AdditionalPropertiesDialog';
import { ShipmentEditHeader } from './ShipmentEditHeader';
import { validateShipment } from './ShipmentEditViewFormConfig';
import {
    DeliveryNotesContentEditView,
    addDeliveryNote,
    addLoadItem,
    deleteDeliveryNote,
    handleOnDragEnd,
    updateDeliveryNote,
} from './deliveryNotes/DeliveryNotesContentEditView';
import { setInitialVehicleRegistrationNumber } from './transport/MeansOfTransportEdit';
import { TransportContentEditView } from './transport/TransportContentEditView';
import { ShipmentEditViewProps, ShipmentFormMutators } from './types';

export const SHIPMENT_EDIT_VIEW_TEST_ID = 'SHIPMENT_EDIT_VIEW_TEST_ID';
export const SHIPMENT_EDIT_VIEW_HEADER_TEST_ID = 'SHIPMENT_EDIT_VIEW_HEADER_TEST_ID';
export const SHIPMENT_EDIT_VIEW_HEADER_SHIPMENT_NUMBER_TEST_ID = 'SHIPMENT_EDIT_VIEW_HEADER_SHIPMENT_NUMBER_TEST_ID';
export const SHIPMENT_EDIT_VIEW_CLOSE_BUTTON_TEST_ID = 'SHIPMENT_EDIT_VIEW_CLOSE_BUTTON_TEST_ID';
export const SHIPMENT_EDIT_STEPPED_PROGRESS_BAR_TEST_ID = 'SHIPMENT_EDIT_STEPPED_PROGRESS_BAR_TEST_ID';
export const SHIPMENT_EDIT_VIEW_DESPATCH_DATE_ENABLED_TEST_ID = 'SHIPMENT_EDIT_VIEW_DESPATCH_DATE_TEST_ID';
export const SHIPMENT_EDIT_VIEW_DESPATCH_DATE_DISABLED_TEST_ID = 'SHIPMENT_EDIT_VIEW_DESPATCH_DATE_DISABLED_TEST_ID';
export const SHIPMENT_EDIT_VIEW_ESTIMATED_ARRIVAL_DATE_TEST_ID = 'SHIPMENT_EDIT_VIEW_ESTIMATED_ARRIVAL_DATE_TEST_ID';
export const SHIPMENT_EDIT_VIEW_HEADER_WEIGHT_WARNING_TEST_ID = 'SHIPMENT_EDIT_VIEW_HEADER_WEIGHT_WARNING_TEST_ID';

export const ShipmentEditView = (props: ShipmentEditViewProps) => {
    const dispatch = useAppDispatch();
    const dunsNumber = useDunsNumberFromPath();
    const shipment = props.shipment;
    const savingStatus = useAppSelector(getSavingShipmentStatus);
    useEffect(() => {
        if (dunsNumber) {
            dispatch(getAndStoreManagedFreightForwarders(dunsNumber));
        }
    }, [dispatch, dunsNumber]);

    const [selectedStep, setSelectedStep] = useState<SteppedProgressBarStep>(SteppedProgressBarStep.DELIVERY_NOTE);
    const [selectedStepPending, setSelectedStepPending] = useState<SteppedProgressBarStep | undefined>(undefined);

    useEffect(() => {
        if (selectedStepPending) {
            if (savingStatus === SavingShipmentStatus.DEFAULT) {
                setSelectedStep(selectedStepPending);
                setSelectedStepPending(undefined);
            } else if (savingStatus === SavingShipmentStatus.ERROR) {
                setSelectedStepPending(undefined);
            }
        } else if (savingStatus === SavingShipmentStatus.ERROR) {
            dispatch(shipmentsSlice.actions.setSavingStatus(SavingShipmentStatus.DEFAULT));
        }
    }, [dispatch, savingStatus, selectedStepPending]);

    const getPageContentBasedOnSteppedProgress = (step: SteppedProgressBarStep, form: FormApi<Shipment>): ReactNode => {
        const shipmentFormMutators = form.mutators as unknown as ShipmentFormMutators;
        switch (step) {
            case SteppedProgressBarStep.DELIVERY_NOTE:
                return (
                    <DeliveryNotesContentEditView
                        formMutators={shipmentFormMutators}
                        duns={shipment.dunsNumberOwner}
                        shipmentId={shipment.id}
                    />
                );
            case SteppedProgressBarStep.PACKAGING:
                return <PackagesContentEditView />;
            case SteppedProgressBarStep.TRANSPORT:
                return <TransportContentEditView />;
            case SteppedProgressBarStep.SHIPMENT_DOCUMENTS:
                return <ShipmentDocuments />;
            default:
                return neverReachedFor(step);
        }
    };

    const onSubmit = (values: Shipment) => {
        const mappedShipment = values;
        if (
            selectedStep === SteppedProgressBarStep.DELIVERY_NOTE &&
            wouldChangesToShipmentInvalidatePackaging(mappedShipment, shipment)
        ) {
            dispatch(packagingSlice.actions.deletePackagingConfiguration(mappedShipment.id));
        }
        dispatch(updateShipment(mappedShipment, mappedShipment.dunsNumberOwner, mappedShipment.id));
    };

    return (
        <div data-testid={SHIPMENT_EDIT_VIEW_TEST_ID}>
            <Spinner
                show={savingStatus === SavingShipmentStatus.SAVING}
                text={<FormattedMessage id={'webedi.saving'} />}
                isFullScreen
            />
            <div className={'margin-bottom-20'}>
                <ErrorBox headerKey={'webedi.error.shipment'} />
            </div>
            <Form<Shipment>
                mutators={{
                    ...arrayMutators,
                    handleOnDragEnd,
                    addDeliveryNote,
                    deleteDeliveryNote,
                    updateDeliveryNote,
                    addLoadItem,
                    setInitialVehicleRegistrationNumber,
                }}
                onSubmit={onSubmit}
                initialValues={shipment}
                validate={validateShipment}
                render={({ handleSubmit, pristine, form, errors }) => {
                    return (
                        <>
                            <form onSubmit={handleSubmit}>
                                <ShipmentEditHeader
                                    shipment={shipment}
                                    pristine={pristine}
                                    submitForm={form.submit}
                                    selectedStep={selectedStep}
                                    setSelectedStep={setSelectedStepPending}
                                    readOnly={selectedStep === SteppedProgressBarStep.SHIPMENT_DOCUMENTS}
                                    errors={errors}
                                />
                                <DragDropContext
                                    onDragEnd={(result: DropResult) => form.mutators.handleOnDragEnd(result)}
                                >
                                    {getPageContentBasedOnSteppedProgress(selectedStep, form)}
                                </DragDropContext>
                                <AdditionalPropertiesDialog shipment={form.getState().values} formErrors={errors} />
                                <PackagingDialog />
                            </form>
                        </>
                    );
                }}
            />
        </div>
    );
};
