import { BUTTON_STYLE } from '@rio-cloud/rio-uikit/Button';
import SimpleButtonDropdown from '@rio-cloud/rio-uikit/SimpleButtonDropdown';
import { Draggable, Droppable } from 'react-beautiful-dnd';
import { FieldArray } from 'react-final-form-arrays';
import { FormattedMessage } from 'react-intl';
import { useAppDispatch } from '../../../../../../configuration/setup/typedReduxHooks';
import { openAdditionalPropertiesDialog, openSidebar } from '../../../../actions/shipments/Shipments.actions';

import { DeliveryNote, LoadItem } from '../../../../reducers/shipments/types';
import { ArticleNumber } from '../../../common/ArticleNumber';
import { Tooltip } from '../../../common/Tooltip';
import {
    ADDITIONAL_PROPERTIES_DISPLAY_TEST_ID,
    ADDITIONAL_PROPERTIES_DISPLAY_TOOLTIP_TEST_ID,
    AdditionalPropertiesDisplay,
} from '../../AdditionalPropertiesDisplay';
import { EditableDeliveryNoteNumberField } from './EditableNumberField';
import { QuantityField } from './QuantityField';
import { DeliveryNoteEditViewProps, LoadItemFormFields } from './types';

export const DELIVERY_NOTE_EDIT_VIEW_TEST_ID = 'DELIVERY_NOTE_EDIT_VIEW_TEST_ID';
export const DELIVERY_NOTE_EDIT_VIEW_HEADER_TEST_ID = 'DELIVERY_NOTE_EDIT_VIEW_HEADER_TEST_ID';
export const LOAD_ITEM_DRAG_HANDLE_TEST_ID = 'LOAD_ITEM_DRAG_HANDLE_TEST_ID';
export const DELIVERY_NOTE_ACTION_DROPDOWN_TEST_ID = 'DELIVERY_NOTE_ACTION_DROPDOWN_TEST_ID';
export const DELIVERY_NOTE_EDIT_VIEW_LOAD_TABLE_TEST_ID = 'DELIVERY_NOTE_EDIT_VIEW_LOAD_TABLE_TEST_ID';
export const DELIVERY_NOTE_EDIT_VIEW_LOAD_TABLE_HEADER_ROW_TEST_ID =
    'DELIVERY_NOTE_EDIT_VIEW_LOAD_TABLE_HEADER_ROW_TEST_ID';
export const DELIVERY_NOTE_EDIT_VIEW_LOAD_TABLE_ROW_TEST_ID = 'DELIVERY_NOTE_EDIT_VIEW_LOAD_TABLE_ROW_TEST_ID';
export const DELIVERY_NOTE_BUTTON_TOOLBAR_TEST_ID = 'DELIVERY_NOTE_BUTTON_TOOLBAR_TEST_ID';
export const DELIVERY_NOTE_EDIT_VIEW_ARTICLE_EDIT_BUTTON_TEST_ID =
    'DELIVERY_NOTE_EDIT_VIEW_ARTICLE_EDIT_BUTTON_TEST_ID';
export const DELIVERY_NOTE_OPEN_ADDITIONAL_PROPERTIES_TEST_ID = 'DELIVERY_NOTE_OPEN_ADDITIONAL_PROPERTIES_TEST_ID';

export const hasLoadItemAdditionalProperties = (loadItem: LoadItem) => {
    return !!(
        loadItem.batchNumber ||
        loadItem.expiryDate ||
        loadItem.isSubjectToPreferenceAuthorization ||
        loadItem.additionalProductId?.softwareStatus ||
        loadItem.additionalProductId?.hardwareStatus ||
        loadItem.additionalProductId?.partsGenerationStatus
    );
};

const DeliveryNoteHeader = (deliveryNoteHeaderProps: {
    deliveryNotePath: string;
    deliveryNote: DeliveryNote;
    deleteDeliveryNote: (deliveryNote: DeliveryNote) => void;
}) => {
    const dispatch = useAppDispatch();

    const onAddArticleToDeliveryNote = () => {
        dispatch(openSidebar(deliveryNoteHeaderProps.deliveryNote.deliveryNoteNumber));
    };

    return (
        <div className={'panel-heading display-flex flex-row justify-content-between padding-left-20 padding-10'}>
            <div className={'display-flex align-items-center text-color-dark'}>
                <span className={'rioglyph rioglyph-document text-size-20 padding-right-10'} />
                <FormattedMessage id={'webedi.deliveryNote'} />
                <div data-testid={DELIVERY_NOTE_EDIT_VIEW_HEADER_TEST_ID}>
                    <EditableDeliveryNoteNumberField
                        path={deliveryNoteHeaderProps.deliveryNotePath}
                        value={deliveryNoteHeaderProps.deliveryNote.deliveryNoteNumber}
                        inputInnerClasses={'width-100 text-left margin-right--5'}
                    />
                </div>
            </div>
            <div className={'pull-right display-flex btn-toolbar'} data-testid={DELIVERY_NOTE_BUTTON_TOOLBAR_TEST_ID}>
                <button type={'button'} className={'btn btn-default'} onClick={onAddArticleToDeliveryNote}>
                    <span className={'rioglyph rioglyph-plus'} />
                    <FormattedMessage id={'webedi.deliveryNote.addArticle'} />
                </button>
                <div className={'pull-right'} data-testid={DELIVERY_NOTE_ACTION_DROPDOWN_TEST_ID}>
                    <SimpleButtonDropdown
                        title={<span className={'rioglyph rioglyph-option-vertical'} />}
                        bsStyle={'link' as BUTTON_STYLE}
                        iconOnly
                        items={[
                            {
                                value: (
                                    <span className={'text-danger'}>
                                        <span className={'rioglyph rioglyph-trash margin-right-10'} />
                                        <FormattedMessage id={'webedi.label.delete'} />
                                    </span>
                                ),
                                onSelect: () =>
                                    deliveryNoteHeaderProps.deleteDeliveryNote(deliveryNoteHeaderProps.deliveryNote),
                            },
                        ]}
                        pullRight={true}
                    />
                </div>
            </div>
        </div>
    );
};

export const DeliveryNoteEdit = (props: DeliveryNoteEditViewProps) => {
    const { deliveryNote, deliveryNotePath, deleteDeliveryNote, updateDeliveryNote } = props;

    const tableClassNames = ['table', 'table-column-overflow-hidden', 'table-head-filled'].join(' ');

    const dispatch = useAppDispatch();

    const renderRowPlaceholder = () => {
        return (
            <tr>
                <td colSpan={6} className={'text-center text-color-gray text-size-20'}>
                    <div className={'text-center text-color-gray text-size-14 padding-2pct'}>
                        <span className={'rioglyph rioglyph-looking-glass-man text-size-400pct padding-25'} />
                        <div className={'text-size-20'}>
                            <FormattedMessage id={'webedi.deliveryNote.addArticleNumber'} />
                        </div>
                    </div>
                </td>
            </tr>
        );
    };

    const deleteLoadItem = (loadItemToDelete: LoadItem) => {
        const updatedLoadItems: LoadItem[] = deliveryNote.loadItems.filter((loadItem) => loadItem !== loadItemToDelete);
        const updatedDeliveryNote: DeliveryNote = {
            ...deliveryNote,
            loadItems: updatedLoadItems,
        };
        updateDeliveryNote(updatedDeliveryNote);
    };

    const deleteLoadItemButton = (loadItem: LoadItem) => ({
        value: (
            <span className={'text-color-danger'}>
                <span className={'rioglyph rioglyph-trash margin-right-10'} />
                <FormattedMessage id={'webedi.label.delete'} />
            </span>
        ),
        onSelect: () => deleteLoadItem(loadItem),
    });

    const additionalPropertiesButton = (path: string) => ({
        value: (
            <span data-testid={DELIVERY_NOTE_OPEN_ADDITIONAL_PROPERTIES_TEST_ID}>
                <span className={'rioglyph rioglyph-plus margin-right-10'} />
                <FormattedMessage id={'webedi.shipment.additionalProperties'} />
            </span>
        ),
        onSelect: () => dispatch(openAdditionalPropertiesDialog(path)),
    });

    const renderRow = (loadItem: LoadItem, index: number, path: string) => {
        return (
            <Draggable
                isDragDisabled={false}
                draggableId={deliveryNote.deliveryNoteNumber + index.toString()}
                index={index}
                key={deliveryNote.deliveryNoteNumber + index.toString()}
            >
                {(provided, snapshot) => {
                    return (
                        <tr
                            key={index}
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            className={snapshot.isDragging ? 'active display-table border-none' : ''}
                            data-testid={DELIVERY_NOTE_EDIT_VIEW_LOAD_TABLE_ROW_TEST_ID}
                        >
                            <td className={'width-35'}>
                                <span
                                    className={'rioglyph rioglyph-cards-list text-color-light text-size-20'}
                                    {...provided.dragHandleProps}
                                    data-testid={LOAD_ITEM_DRAG_HANDLE_TEST_ID}
                                />
                            </td>
                            <td className={'text-center width-100'}>
                                <span className={'badge badge-default'}>{index + 1}</span>
                            </td>
                            <td>
                                <ArticleNumber articleNumber={loadItem.articleNumberBuyer} />
                            </td>
                            <td>
                                <ArticleNumber articleNumber={loadItem.articleMasterData?.articleNumberSeller} />
                            </td>
                            <td>
                                <QuantityField
                                    index={index}
                                    loadItem={loadItem}
                                    deliveryNotePath={deliveryNotePath}
                                    isDragging={snapshot.isDragging}
                                />
                            </td>
                            <td className={'text-center max-width-250'}>
                                {hasLoadItemAdditionalProperties(loadItem) ? (
                                    <Tooltip
                                        text={
                                            <AdditionalPropertiesDisplay
                                                loadItem={loadItem}
                                                tooltip={true}
                                                testId={ADDITIONAL_PROPERTIES_DISPLAY_TOOLTIP_TEST_ID}
                                            />
                                        }
                                        placement={'bottom'}
                                    >
                                        <span
                                            className={'cursor-pointer ellipsis-2'}
                                            onClick={() => dispatch(openAdditionalPropertiesDialog(path))}
                                        >
                                            <AdditionalPropertiesDisplay
                                                loadItem={loadItem}
                                                tooltip={false}
                                                testId={ADDITIONAL_PROPERTIES_DISPLAY_TEST_ID}
                                            />
                                        </span>
                                    </Tooltip>
                                ) : (
                                    <button
                                        type={'button'}
                                        className={'btn btn-primary btn-link'}
                                        onClick={() => dispatch(openAdditionalPropertiesDialog(path))}
                                    >
                                        <span className={'rioglyph rioglyph-plus'} />
                                        <FormattedMessage id={'webedi.shipment.addAdditionalProperties'} />
                                    </button>
                                )}
                            </td>
                            <td
                                className={'width-60 table-action'}
                                data-testid={DELIVERY_NOTE_EDIT_VIEW_ARTICLE_EDIT_BUTTON_TEST_ID}
                            >
                                <SimpleButtonDropdown
                                    title={<span className={'rioglyph rioglyph-option-vertical'} />}
                                    bsStyle={'link' as BUTTON_STYLE}
                                    iconOnly
                                    items={[additionalPropertiesButton(path), deleteLoadItemButton(loadItem)]}
                                    pullRight={true}
                                    dropdownClassName={'width-200'}
                                />
                            </td>
                        </tr>
                    );
                }}
            </Draggable>
        );
    };

    const renderRows = (fields: LoadItemFormFields) => {
        return fields.map((loadItemPath, loadItemIndex) =>
            renderRow(deliveryNote.loadItems[loadItemIndex], loadItemIndex, loadItemPath),
        );
    };

    return (
        <div
            className={'panel panel-default'}
            key={`${deliveryNotePath}.deliveryNoteNumberKey`}
            data-testid={DELIVERY_NOTE_EDIT_VIEW_TEST_ID}
        >
            <DeliveryNoteHeader
                deliveryNotePath={deliveryNotePath}
                deleteDeliveryNote={deleteDeliveryNote}
                deliveryNote={deliveryNote}
            />
            <table className={tableClassNames} data-testid={DELIVERY_NOTE_EDIT_VIEW_LOAD_TABLE_TEST_ID}>
                <thead>
                    <tr data-testid={DELIVERY_NOTE_EDIT_VIEW_LOAD_TABLE_HEADER_ROW_TEST_ID}>
                        <th className={'width-35'} />
                        <th className={'text-center width-100'}>
                            <FormattedMessage id={'webedi.position'} />
                        </th>
                        <th>
                            <FormattedMessage id={'webedi.articleNumber.buyer'} />
                        </th>
                        <th>
                            <FormattedMessage id={'webedi.articleNumber.seller'} />
                        </th>
                        <th className={'text-center'}>
                            <FormattedMessage id={'webedi.schedulingData.quantity'} />
                        </th>
                        <th className={'text-center max-width-250'}>
                            <FormattedMessage id={'webedi.shipment.additionalProperties'} />
                        </th>
                        <th className={'width-60'} />
                    </tr>
                </thead>
                <Droppable droppableId={deliveryNotePath}>
                    {(provided) => {
                        return (
                            <tbody ref={provided.innerRef}>
                                <FieldArray
                                    name={
                                        `${deliveryNotePath}.loadItems` as unknown as keyof DeliveryNote['loadItems'] as string
                                    }
                                >
                                    {({ fields }: { fields: LoadItemFormFields }) => {
                                        return fields.length && fields.length > 0
                                            ? renderRows(fields)
                                            : renderRowPlaceholder();
                                    }}
                                </FieldArray>
                                {provided.placeholder}
                            </tbody>
                        );
                    }}
                </Droppable>
            </table>
        </div>
    );
};
