import Button from '@rio-cloud/rio-uikit/Button';
import { FormattedMessage, useIntl } from 'react-intl';
import { useDismissDispatchProposalProblemMutation } from '../../../../api/dispatchProposal/dispatchProposalApi';
import {
    ArticleQuantityDiscrepancyProblem,
    DispatchProposalProblem,
    DispatchProposalProblemBase,
    isArticleQuantityDiscrepancyProblem,
} from '../../../../domain/dispatchProposal.types';
import { Quantity } from '../../../common/Quantity';
import { DispatchProposalProblemBannerFunctions, ProblemDescriptorRow } from '../DispatchProposalProblemBanner';
import {
    BannerIcon,
    FormattedArticleNumber,
    HorizontalLine,
    colorByProblemLevel,
} from '../DispatchProposalProblemBannerFormatters';

const filterArticleQuantityDiscrepancyProblem = (problems: DispatchProposalProblem[]) =>
    problems.filter(isArticleQuantityDiscrepancyProblem);

const useMapToArticleQuantityDiscrepancyProblem = (problemBase: DispatchProposalProblemBase[]) => {
    const problems = problemBase as ArticleQuantityDiscrepancyProblem[];

    if (problems.length === 0) {
        return undefined;
    }

    const alertLevel = problems[0].level;
    const rows = problems.map(
        (problem) =>
            ({
                id: problem.id,
                identifier: <FormattedArticleNumber articleNumber={problem.articleNumberBuyer} />,
                description: <ArticleQuantityDiscrepancyProblemDescription problem={problem} />,
                actionButton: <DismissArticleQuantityDiscrepancyProblemActionButton problem={problem} />,
            }) as ProblemDescriptorRow,
    );
    return {
        title: ArticleQuantityDiscrepancyProblemTitle(),
        rows,
        formatting: {
            testId: problems[0].type,
            horizontalDivider: <HorizontalLine level={alertLevel} />,
            icon: BannerIcon({ level: alertLevel }),
            buttonStyle: colorByProblemLevel(alertLevel),
            printEntries: true,
        },
    };
};

const ArticleQuantityDiscrepancyProblemDescription = (props: { problem: ArticleQuantityDiscrepancyProblem }) => {
    const targetQuantity = props.problem.targetQuantity;
    return (
        <p>
            <strong>
                <FormattedMessage
                    id={'webedi.dispatchProposals.problemBanner.articleQuantityDiscrepancy.targetQuantity'}
                />
                :
            </strong>
            <Quantity
                className={'margin-left-5'}
                quantity={targetQuantity.value}
                measurementUnitCode={targetQuantity.measurementUnitCode}
            />
            <AdditionalArticleQuantityDiscrepancyProblemDescription problem={props.problem} />
        </p>
    );
};

const AdditionalArticleQuantityDiscrepancyProblemDescription = (props: {
    problem: ArticleQuantityDiscrepancyProblem;
}) => {
    const intl = useIntl();

    return (
        <span className={'margin-left-5'}>
            (<span>{intl.formatDate(props.problem.deliveryDate)},</span>
            <span className='rioglyph rioglyph-factory text-size-18 margin-left-5' aria-hidden='true' />
            <span className={'margin-left-5'}>{props.problem.plantCode}</span>
            <span className='rioglyph rioglyph-load margin-left-5' aria-hidden='true' />
            <span className={'margin-left-5'}>{props.problem.placeOfDischarge}</span>)
        </span>
    );
};

const ArticleQuantityDiscrepancyProblemTitle = () => (
    <strong className='text-size-16'>
        <FormattedMessage id={'webedi.dispatchProposals.problemBanner.articleQuantityDiscrepancy.title'} />
    </strong>
);

const DismissArticleQuantityDiscrepancyProblemActionButton = (props: {
    problem: ArticleQuantityDiscrepancyProblem;
}) => {
    const buttonStyle = colorByProblemLevel(props.problem.level);
    const label = 'webedi.dispatchProposals.problemBanner.understood';

    const [dismissDispatchProposalProblem] = useDismissDispatchProposalProblemMutation();
    const onClick = () =>
        dismissDispatchProposalProblem({
            dunsNumber: props.problem.dunsNumber,
            problemId: props.problem.id,
        });

    return (
        <Button bsStyle={buttonStyle} className={'margin-left-auto'} onClick={onClick}>
            <FormattedMessage id={label} />
        </Button>
    );
};

export const articleQuantityDiscrepancyProblemHandler: DispatchProposalProblemBannerFunctions = {
    filterForOneTypeOfProblem: filterArticleQuantityDiscrepancyProblem,
    useGenerateProblemDescriptor: useMapToArticleQuantityDiscrepancyProblem,
};
