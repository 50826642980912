import { SelectOption } from '@rio-cloud/rio-uikit';
import Button from '@rio-cloud/rio-uikit/Button';
import EditableContent from '@rio-cloud/rio-uikit/EditableContent';
import Notification from '@rio-cloud/rio-uikit/Notification';
import SelectUiKit from '@rio-cloud/rio-uikit/Select';
import { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { usePostOperationMutation } from '../../../api/dispatchProposal/dispatchProposalApi';
import {
    ChangeLoadingLocationOperation,
    DispatchProposalOperationType,
} from '../../../api/dispatchProposal/operations.types';
import { ArticleSuggestion, DispatchProposalItem } from '../../../domain/dispatchProposal.types';
import { useDunsNumberFromPath } from '../../../hooks/Routing.hooks';
import { LoadingLocation } from '../../../reducers/loadingLocations/types';
import { NoDataAvailable } from '../../common/NoDataAvailable';
import { useLoadingLocationsQuery } from '../../loadingLocations/LoadingLocationHooks';

interface SelectorProps {
    value: string | undefined;
    loadingLocations: LoadingLocation[];
    onChange: (selectedLoadingLocationId?: string) => void;
}

const Selector = (props: SelectorProps) => {
    const options = props.loadingLocations.map((it) => ({ id: it.id, label: it.name }));

    return (
        <div>
            <SelectUiKit
                id={props.value}
                onChange={(event?: SelectOption) => props.onChange(event?.id)}
                options={options}
                placeholder={<FormattedMessage id={'webedi.inputPlaceholder.dropdown.standard'} />}
                value={props.value ? [props.value] : undefined}
            />
        </div>
    );
};

export const LoadingLocationSelector = (props: {
    item: DispatchProposalItem | ArticleSuggestion;
    dispatchProposalId: string | undefined;
    isOperationAllowed: boolean;
}) => {
    const { data, isLoading } = useLoadingLocationsQuery();
    const loadingLocations = data ?? [];
    const [changeLoadingLocation] = usePostOperationMutation();
    const dunsNumber = useDunsNumberFromPath()!;
    const dispatchProposalId = props.dispatchProposalId;
    const itemIdentifier = (props.item! as DispatchProposalItem).identifier;
    const [currentSelectedLoadingLocationId, setCurrentSelectedLoadingLocationId] = useState(
        props.item!.shipFrom.shippingLocationId,
    );
    const savedLoadingLocationId = props.item!.shipFrom.shippingLocationId;

    if (isLoading) {
        return <NoDataAvailable />;
    }

    const findLoadingLocationName = (loadingLocationId: string | undefined) => {
        const loadingLocation = loadingLocations.find((item) => item.id === loadingLocationId);
        return loadingLocation?.name;
    };

    const savedLoadingLocationName = findLoadingLocationName(savedLoadingLocationId);

    const initialContent = (
        <Button
            className={'padding-left-0 width-100pct justify-content-start'}
            variant={'link'}
            disabled={!props.isOperationAllowed || loadingLocations.length === 0}
        >
            {savedLoadingLocationName !== undefined ? (
                <div className={'ellipsis-1'}>
                    <span>{savedLoadingLocationName}</span>
                </div>
            ) : (
                <div className={'text-color-danger justify-content-start ellipsis-1'}>
                    <FormattedMessage
                        id={'webedi.dispatchProposals.planDispatchProposal.body.table.missingLoadingLocation'}
                    />
                </div>
            )}
            <span
                data-testid={'EDITABLE_LOADING_LOCATION_PENCIL_TEST_ID'}
                className={`margin-left-5 rioglyph rioglyph-pencil ${savedLoadingLocationName === undefined ? 'text-color-danger' : ''}`}
            />
        </Button>
    );

    const onLoadingLocationChange = (selectedLoadingLocationId?: string) => {
        setCurrentSelectedLoadingLocationId(selectedLoadingLocationId);
    };

    const onLoadingLocationConfirmed = () => {
        if (currentSelectedLoadingLocationId === undefined) {
            return;
        }
        if (currentSelectedLoadingLocationId === savedLoadingLocationId) {
            return;
        }
        setCurrentSelectedLoadingLocationId(undefined);

        const operation: ChangeLoadingLocationOperation = {
            type: DispatchProposalOperationType.CHANGE_LOADING_LOCATION,
            dunsNumber,
            dispatchProposalId: dispatchProposalId!,
            parameters: {
                itemIdentifier,
                loadingLocationId: currentSelectedLoadingLocationId!,
            },
        };

        changeLoadingLocation(operation)
            .unwrap()
            .then(() => {
                Notification.success(<FormattedMessage id={'webedi.label.success.save'} />);
            })
            .catch((_) => {
                Notification.error(<FormattedMessage id={'webedi.error.general'} />);
            });
    };

    const onLoadingLocationCancelled = () => {
        setCurrentSelectedLoadingLocationId(undefined);
    };

    if (!props.isOperationAllowed || loadingLocations.length === 0) {
        return initialContent;
    }

    return (
        <EditableContent
            onSave={onLoadingLocationConfirmed}
            onCancel={onLoadingLocationCancelled}
            customEditor={
                <Selector
                    value={currentSelectedLoadingLocationId ?? savedLoadingLocationId}
                    loadingLocations={loadingLocations}
                    onChange={onLoadingLocationChange}
                />
            }
        >
            {initialContent}
        </EditableContent>
    );
};
