import ActionBarItem from '@rio-cloud/rio-uikit/ActionBarItem';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import { useAppSelector } from '../../../../../configuration/setup/typedReduxHooks';
import { isSupplier } from '../../../../../configuration/tokenHandling/selectors';
import { useDunsNumberFromPath } from '../../../hooks/Routing.hooks';
import { Routing } from '../../routing/Routes';

export const VwSelectedDunsNumber = () => {
    const dunsNumber = useDunsNumberFromPath();
    const supplier = useAppSelector(isSupplier);

    return (
        <ActionBarItem id={'dunsNumber'} className={'selected-duns-action-bar-container'}>
            <div className={'action-bar-item'}>
                {dunsNumber && (
                    <div className={'selected-duns-header-container'}>
                        <div className={'text-left flex-basis-100pct padding-5'}>
                            <label>
                                <FormattedMessage id={'webedi.dunsNumber'} />
                            </label>
                            <div>
                                {supplier ? (
                                    <div className={'text-left'}>{dunsNumber}</div>
                                ) : (
                                    <Link to={Routing.dunsSelection} className={'text-decoration-none'}>
                                        <button className={'btn btn-primary btn-link padding-0 text-color-darkest'}>
                                            {dunsNumber}
                                            <span
                                                className={'rioglyph rioglyph-pencil margin-left-10 margin-bottom-3'}
                                            />
                                        </button>
                                    </Link>
                                )}
                            </div>
                        </div>
                    </div>
                )}
            </div>
        </ActionBarItem>
    );
};
